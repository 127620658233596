import axios from "axios";

export const getSubscriptionsAdmin = async ({ page = 1, pageSize = 100, status = "Active" }) => {
  const params = {
    query: { $and: [{ status }] },
    sort: "-_id",
    page,
    pageSize,
  };
  const response = await axios.get("/subscriptions", { params });

  const hasMore = response.data.page < response.data.totalPages;
  const data = { ...response.data, hasMore };
  return data;
};

export const getSubscriptionById = async (id) => {
  const { data } = await axios.get(`/subscriptions/${id}`);
  return data;
};

export const updateSubscriptionStatus = async (data) => {
  const response = await axios.post(`/subscriptions/${data.id}`, data);
  return response;
};

export const updateSubscriptionById = async ({id, data}) => {
  const response = await axios.put(`/subscriptions/${id}`, data);
  return response;
};

export const postSubscription = async ({ data }) => {
  const response = await axios.post(`/subscriptions`, data);
  return response;
};

export const getLocations = async () => {
  const params = {
    query: { $and: [{ status: "Active" }] },
    sort: "-_id",
  };
  const { data } = await axios.get("/locations", { params });
  return data;
};
