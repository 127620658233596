import axios from 'axios'

export const signInAdmin = async (credentials) => {
    return axios.post('/user/admin/login', credentials)
}

export const signUpAdmin = async (data) => {
    try {
        const response = await axios.post('/user/admin/signup', data)
        return response
    } catch (error) {
        return null
    }
}

export const createUser = async (data) => {
    try {
        const response = await axios.post('/user/admin', data)
        return response
    } catch (error) {
        return null
    }
}

export const forgotPassword = async (email) => {
    try {
        const response = await axios.post('/user/admin/password/reset', { email })
        return response
    } catch (error) {
        return null
    }
}

export const verifyEmail = async (token) => {
     try {  
        const response = await axios.get("/user/email/verify", {params: {token}});
        return response;
     } catch (error) {
       return null;
     }
};

