import { Suspense, lazy } from "react";
import { Switch, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { RouteHeader, Loader } from "../../../../reusableComponents";
import { ADMIN_ROUTES, PrivatRoute } from '../../../../routes';
import s from "./Settings.module.scss";

const BillingsAsync = lazy(() => import("./Billings" /*webpackChunkName: "Settings"*/));
const ConditionsAsync = lazy(() => import("./Conditions" /*webpackChunkName: "Settings"*/));

const Settings = () => {
    const { t } = useTranslation();
   

    return (
      <div className={s.container}>
        <RouteHeader title={t("settingsRoute").title} />
        <div className={s.box}>
          <div className={s.column}>
            <NavLink to={ADMIN_ROUTES.SETTINGS.billings} activeClassName={s.btnActive} className={s.btn}>
              {t("settingsRoute").billings.title}
            </NavLink>
            <NavLink to={ADMIN_ROUTES.SETTINGS.conditions} activeClassName={s.btnActive} className={s.btn}>
              {t("settingsRoute").conditions.title}
            </NavLink>
          </div>
          <div className={s.boxRoute}>
             <Suspense fallback={<Loader />}>
              <Switch>
                <PrivatRoute component={BillingsAsync} path={ADMIN_ROUTES.SETTINGS.billings} exact />
                <PrivatRoute component={ConditionsAsync} path={ADMIN_ROUTES.SETTINGS.conditions} exact /> 
                <Redirect to={ADMIN_ROUTES.RESERVATIONS.path} />
              </Switch>
            </Suspense> 
          </div>
        </div>
      </div>
    );
}
export default Settings;
