import axios from "axios";

export const getReservationsHost = async ({ page = 1, pageSize = 100, status = "Active" }) => {
  const params = {
    query: { $and: [{ status }] },
    sort: "-_id",
    page,
    pageSize,
  };
  const response = await axios.get("/reservations", { params });

  const hasMore = response.data.page < response.data.totalPages;
  const data = { ...response.data, hasMore };
  return data;
};
