import React, { useState, useEffect, useCallback, memo } from 'react'
import { useLocalStorage } from 'react-use'
import cn from 'classnames'

import DrawerArrow from './DrawerArrow'
import DrawerList from './DrawerList'

import { SIDEBAR_STORAGE_KEY } from '../../data/constans'

import s from './Drawer.module.scss'

export const Drawer = memo(() => {
    const [expandLS, setExpandLS] = useLocalStorage(SIDEBAR_STORAGE_KEY)

    const [expand, setExpand] = useState(expandLS)
    const handleToogleExpand = useCallback(() => setExpand((prevState) => !prevState), [])

    useEffect(() => {
        setExpandLS(expand)
    }, [expand])

    return (
        <div className={cn(s.drawer, expand && s.expand)}>
            <DrawerArrow isExpand={expand} onExpand={handleToogleExpand} />
            <DrawerList isExpanded={expand} />
        </div>
    )
})
