import { Button } from '@material-ui/core/'
import { withStyles } from '@material-ui/core/styles';

export const SubmitButton = withStyles({
  root: {
    background: "#e6c727",
    color: "#fff",
    marginTop: "20px",
    opacity: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "45px",

    "&:hover": {
      boxShadow: "0 0 0 0.1rem grey",
      background: "#fff",
      color: "#000",
    },
    "&:active": {
      boxShadow: "none",
      background: "#fff",
      color: "#000",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.1rem grey",
    },
    "&[disabled]": {
      opacity: "0.7",
      color: "rgba(0, 0, 0, 0.26)",
      boxShadow: "none",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
    },
    "&a": {
      color: "#fff",
    },
  },
})(Button);