import { MobilePDFReader } from "react-read-pdf";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "@material-ui/core";
import s from "./TermAndConditionsModal.module.scss";

export const TermAndConditionsModal = ({ isOpen, onClose, document, onSubmit }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onSubmit(true);
    onClose();
  };

  const handleDocument = (document) => {
    const binary = atob(document.replace(/\s/g, ""));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([view], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    return url;
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={s.modal}>
        <h2 className={s.title}>{t("createReservation").agreementLink}</h2>

        <div className={s.pdf}>
          {document ? (
            <div style={{ overflow: "scroll" }}>
              <MobilePDFReader
                url={handleDocument(document)}
                minScale="0.6"
                maxScale="1.5"
                isShowHeader={false}
                isShowFooter={false}
              />
            </div>
          ) : (
            <p className={s.notFound}>{t("createReservation").notFound}</p>
          )}
        </div>
        <div className={s.btns}>
          <Button className={s.btn} variant="outlined" fullWidth type="button" onClick={onClose}>
            {t("openCabinetModal").cancelBtn}
          </Button>
          <Button className={s.btn} color="primary" variant="contained" fullWidth type="button" onClick={handleClick}>
            {t("openCabinetModal").yesBtn}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
