import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AUTH_CLIENT_ROUTES } from '../../../../../routes'
import { createClient } from '../../../../../services'

export const useRegisterForm = () => {
	const { push } = useHistory()
	const [error, setError] = useState(false)

	const signIn= async (credentials) => {
		try {
				await createClient(credentials)
				push({
					pathname: AUTH_CLIENT_ROUTES.CLIENT_LOGIN.path,
					state: { phone: credentials.phone}
				})
		} catch (error) {
			setError(true)
		}
	}
	return { error, signIn }
}