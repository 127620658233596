import { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, IconButton, MenuItem, Menu } from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { ChangePasswordModal, ChangeProfileLogo, BtnLanguage } from "../../reusableComponents";
import { useAdminNavBar } from "./useAdminNavBar";
import estilamLogo from "../../assets/estilamLogo.png";
import { useAuth } from "../../context";
import { useUserRole } from "../../hooks/useUserRole";
import s from "./AppNavBar.module.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

export function AppNavBar() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isClient, isAdmin, isHost } = useUserRole();
  const { authUser } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const { logout } = useAdminNavBar();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openChangePassword, setOpenChangePassword] = useState(false);
  const toogleChangePassword = () => setOpenChangePassword((prev) => !prev);

  const [openChangeProfileLogo, setOpenChangeProfileLogo] = useState(false);
  const toogleChangeLogo = () => {
    handleClose();
    setOpenChangeProfileLogo((prev) => !prev);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color={"transparent"}>
        <Toolbar className={isClient && (!isAdmin || !isHost) ? s.headerClient : ""}>
          {!isClient && (isAdmin || isHost) && (
            <div style={{ marginRight: "auto" }}>
              <img src={estilamLogo} width="120" height="50" alt="logo" />
            </div>
          )}
          <BtnLanguage />
          {isClient && (!isAdmin || !isHost) && (
            <div>
              <img src={estilamLogo} width="120" height="50" alt="logo" />
            </div>
          )}
          <div className={authUser.role !== "Client" ? s.userMenu : null}>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {authUser.image ? (
                <img
                  src={`${
                    process.env.NODE_ENV === "development"
                      ? process.env.REACT_APP_BASE_URL_LOCAL
                      : process.env.REACT_APP_BASE_URL
                  }/${authUser.image}`}
                  className={s.userLogo}
                  alt="user logo"
                />
              ) : (
                <AccountCircle fontSize="large" />
              )}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={toogleChangeLogo}>{t("navbar").logo}</MenuItem>
              {authUser.role !== "Client" && <MenuItem onClick={toogleChangePassword}>{t("navbar").password}</MenuItem>}
              <MenuItem onClick={logout}>{t("navbar").signOut}</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <ChangePasswordModal isOpen={openChangePassword} onClose={() => setOpenChangePassword(false)} />
      <ChangeProfileLogo isOpen={openChangeProfileLogo} onClose={() => setOpenChangeProfileLogo(false)} />
    </div>
  );
}
