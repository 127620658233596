import cn from 'classnames'
import { Icon } from '@blueprintjs/core'

import s from './DrawerArrow.module.scss'

const DrawerArrow = ({ isExpand, onExpand }) => {
    return (
        <div className={cn(s.drawerArrow, isExpand && s.expand)} onClick={onExpand}>
            <Icon icon='chevron-right' color='#fff' />
        </div>
    )
}

export default DrawerArrow
