import axios from "axios";

export const getReservationsAdmin = async ({ page = 1, pageSize = 100, status = "Active" }) => {
  const params = {
    query: { $and: [{ status }] },
    sort: "-_id",
    page,
    pageSize,
  };
  const response = await axios.get("/reservations", { params });

  const hasMore = response.data.page < response.data.totalPages;
  const data = { ...response.data, hasMore };
  return data;
};

export const getReservationsDownloadCSW = async () => {
  const params = {
    export: true,
    pageSize: 1000000,
  };
  const response = await axios.get("/reservations", { params });
  return response;
};

export const getReservationForLocker = async ({ locker, cabinet }) => {
  const params = {
    query: {
      $nor: [{ status: "Completed" }],
      $and: [{ "locker.number": locker }, { "locker.cabinet": cabinet }],
    },
  };
  const response = await axios.get("/reservations", { params });
  return response;
};

export const getReservationById = async (id) => {
  const response = await axios.get(`/reservations/${id}`);
  return response;
};

export const updateReservationStatus = async (id) => {
  const response = await axios.delete(`/reservations/${id}`);
  return response;
};

export const getLogsById = async (reservationId) => {
  const response = await axios.get(`/reservation/logs`, {
    params: { reservationId },
  });
  return response;
};
