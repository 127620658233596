import { NavLink } from 'react-router-dom'
import { Icon } from '@blueprintjs/core'
import cn from 'classnames'

import s from './DrawerItem.module.scss'

const DrawerItem = ({ path, icon, title, isExpanded }) => {
    return (
        <li>
            <NavLink activeClassName={s.active} className={cn(s.navigationItem, isExpanded && s.expanded)} to={path}>
                <div className={s.navigationItem_container}>
                    <div className={s.navigationItem_icon}>
                        <Icon icon={icon} iconSize={20} />
                    </div>
                    {isExpanded && <div className={s.navigationItem_title}>{title}</div>}
                </div>
            </NavLink>
        </li>
    )
}

export default DrawerItem
