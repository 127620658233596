import { memo } from "react";
import ReactTable from "react-table";
import { useTranslation } from "react-i18next";

import s from "./TableReactQuery.module.scss";
import "react-table/react-table.css";

export const TableReactQuery = memo(({ data, columns, page, pages = 1, onLoadMore, hasMore, props = {} }) => {
  const { t } = useTranslation();

  return (
    <ReactTable
      NoDataComponent={() => null}
      data={data}
      resizable={false}
      columns={columns}
      defaultPageSize={10}
      showPageJump={false}
      showPageSizeOptions={false}
      showPagination={true}
      pages={pages}
      previousText={t('reactTable').previousText}
      nextText={t('reactTable').nextText}
      pageText={t('reactTable').pageText}
      ofText={t("reactTable").ofText}
      renderCurrentPage={() => <span>{page}</span>}
      PreviousComponent={({ children }) => (
        <button
          onClick={() => onLoadMore((old) => Math.max(old - 1, 1))}
          type="button"
          className="-btn"
          disabled={page === 1}
        >
          {children}
        </button>
      )}
      NextComponent={({ disabled, children }) => (
        <button
          onClick={() => onLoadMore((old) => (hasMore ? old + 1 : old))}
          type="button"
          className="-btn"
          disabled={!hasMore}
        >
          {children}
        </button>
      )}
      sortable={false}
      className={`${s.reactTable} -striped -highlight`}
      minRows={0}
      getTheadThProps={(_state, rowInfo, column) => {
        const style = {
          whiteSpace: "pre-wrap",
          textAlign: "center",
          padding: 10,
          justifyContent: "center",
          fontWeight: 700,
          height: "auto",
        };
        if (column.Header === "Actions") {
          style.marginLeft = "auto";
        }
        return { style };
      }}
      {...props}
    />
  );
});

export const TableReactQueryNotLocalize = ({
  data,
  createColumns,
  page,
  totalPages,
  loadMore,
  hasMore,
  showPagination,
}) => {
  return (
    <TableReactQuery
      data={data}
      columns={createColumns}
      page={page}
      pages={totalPages}
      onLoadMore={loadMore}
      hasMore={hasMore}
      props={{
        style: {
          width: "auto",
          marginTop: 20,
        },
        showPagination,
        pageSize: 100,
      }}
    />
  );
};
