import { Position, Toaster, Intent } from '@blueprintjs/core'

const ToasterSuccess = Toaster.create({
    className: 'toaster toaster-success',
    position: Position.BOTTOM_RIGHT,
})

const ToasterError = Toaster.create({
    className: 'toaster toaster-error',
    position: Position.BOTTOM_RIGHT,
})

export const showSuccessMessage = (message = 'Successfully') => {
    ToasterSuccess.show({ message, intent: Intent.SUCCESS })
}

export const showWarningMessage = (message = 'Something went wrong') => {
    ToasterError.show({ message, intent: Intent.WARNING })
}

export const showErrorMessage = (message = 'Something went wrong') => {
    ToasterError.show({ message, intent: Intent.DANGER })
}
