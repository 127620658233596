import s from './LockersList.module.scss'

export const LockersList = ({ lockers, onSetLocker }) => {
    return (
        <div className={s.list}>
            {lockers.map((locker) => {
                const handleSetLocker = () =>
                    onSetLocker({
                      locker
                    })
                return (
                    <div key={locker._id} className={s.list_item} onClick={handleSetLocker}>
                        {locker.name}
                    </div>
                )
            })}
        </div>
    )
}
