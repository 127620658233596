import { AppBar, Tabs, Tab } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TabPanel, TableReactQueryNotLocalize, Loader } from "../";
import { useTabsContainer } from "./useTabsContainer";

const TAB_STYLE = { fontWeight: 800, fontSize: 18 };
const NOT_FOUND_STYLE = { textAlign: "center", marginTop: "30px" };

export const TabsContainer = ({ tabs, initialTab, createColumn, getDataCallback, queryKey }) => {
  const { t } = useTranslation();

  const { tab, data, page, isFetching, setPage, refetch, selectTab } = useTabsContainer({
    createColumn,
    getDataCallback,
    initialTab,
    queryKey,
  });

  return (
    <div>
      <AppBar position="static">
        {tabs && (
          <Tabs value={tab} onChange={(_, newTab) => selectTab(newTab)}>
            {tabs.map((tab) => (
              <Tab key={tab} label={t("tabs")[tab]} value={tab} style={TAB_STYLE} />
            ))}
          </Tabs>
        )}
      </AppBar>
      <Loader show={isFetching} />
      {!isFetching && data && (
        <TabPanel value={tab} index={tab}>
          {data.rows && data.rows.length > 0 ? (
            <TableReactQueryNotLocalize
              data={data.rows}
              createColumns={createColumn({ refetch })}
              page={page}
              totalPages={data.totalPages}
              loadMore={setPage}
              hasMore={data.hasMore}
              showPagination={data.totalPages > 1}
            />
          ) : (
            <p style={NOT_FOUND_STYLE}>{t("tabs").notFound}</p>
          )}
        </TabPanel>
      )}
    </div>
  );
};
