import { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { TextField, Button, ButtonGroup } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { useUserRole } from "../../../hooks/useUserRole";
import { changeUserPassword } from "../../../services/admin";
import { changeUserPasswordHost } from "../../../services/host";
import s from "./ChangePasswordModal.module.scss";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #e6c727",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const ChangePasswordModal = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isAdmin } = useUserRole();
  const [modalStyle] = useState(getModalStyle);
  const f = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },

    onSubmit: async (values) => {
      const isChanged = isAdmin ? await changeUserPassword(values) : await changeUserPasswordHost(values);
      if (isChanged) {
        onClose();
      }
    },
  });
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <form onSubmit={f.handleSubmit}>
          <div className={s.form} style={{ display: "flex" }}>
            <div>
              <TextField
                fullWidth
                id="oldPassword"
                name="oldPassword"
                label={t("authForm").changePassword.old}
                value={f.values.oldPassword}
                onChange={f.handleChange}
                className={s.input}
              />

              <TextField
                fullWidth
                id="newPassword"
                name="newPassword"
                label={t("authForm").changePassword.new}
                value={f.values.newPassword}
                onChange={f.handleChange}
                className={s.input}
              />
              <TextField
                fullWidth
                id="newPasswordConfirm"
                name="newPasswordConfirm"
                label={t("authForm").changePassword.confirm}
                value={f.values.newPasswordConfirm}
                onChange={f.handleChange}
                className={s.input}
              />
            </div>
          </div>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group" fullWidth>
            <Button color="secondary" variant="outlined" fullWidth type="button" onClick={onClose}>
              {t("authForm").changePassword.cancelBtn}
            </Button>
            <Button color="primary" variant="contained" fullWidth type="submit">
              {t("authForm").changePassword.changeBtn}
            </Button>
          </ButtonGroup>
        </form>
      </div>
    </Modal>
  );
};
