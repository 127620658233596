import { useState } from 'react'
import { useAuth } from '../../../../../context'
import { signUpAdmin } from '../../../../../services/admin'
import { showErrorMessage } from "../../../../../reusableComponents";

export const useSignUpForm = () => {
    const { login } = useAuth()
    const [error, setError] = useState(false)

    const signUp = async (newAdmin) => {
        try {
            const data = await signUpAdmin(newAdmin)
            await login(data)
        } catch (error) {
           showErrorMessage(error.response.data.message);
        }
    }

    return { error, signUp }
}
