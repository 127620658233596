import axios from "axios";

export const postNewUserHost = async (data) => {
  const response = await axios.post("/user/host", data);
  return response;
};

export const changeUserPasswordHost = async (data) => {
  const response = await axios.post("user/host/password", data);
  return response;
};