import React, { useMemo, memo } from 'react'
import { Icon } from '@blueprintjs/core'
import classnames from 'classnames'

import s from './Button.module.scss'

export const Button = memo(
    ({
        type = 'button',
        classNameType = 'action',
        disabled = false,
        withIcon = false,
        iconProps = {
            icon: '',
            iconSize: 12,
        },
        text,
        style = {},
        onClick = () => {},
        className,
    }) => {
        const buttonType = useMemo(() => (disabled && classNameType !== 'dismiss' ? s['disabled'] : s[classNameType]), [
            disabled,
            classNameType,
        ])
        return (
            <button
                type={type}
                disabled={disabled}
                className={classnames(s.button, buttonType, className)}
                onClick={onClick}
                style={style}
            >
                {text}
                {withIcon && <Icon style={{ marginLeft: 10 }} {...iconProps} />}
            </button>
        )
    },
)

