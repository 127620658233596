import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Button } from "@material-ui/core";
import s from "./CopyLinkModal.module.scss";


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 350,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #e6c727",
    boxShadow: theme.shadows[5],
    padding: "30px",
    textAlign: "center",
  },
}));

export const CopyLinkModal = ({ isOpen, onClose, link}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <div className={s.box}>
          <div className={s.link}>{link}</div>
        </div>
        <div className={s.btns}>
          <Button className={s.btn} variant="outlined" fullWidth type="button" onClick={onClose}>
            {t("copyLinkModal").cancelBtn}
          </Button>
          <CopyToClipboard className={s.btn} text={link} onCopy={onClose}>
            <Button className={s.btn} color="primary" variant="contained" fullWidth type="button">
              {t("copyLinkModal").copyBtn}
            </Button>
          </CopyToClipboard>
        </div>
      </div>
    </Modal>
  );
};
