import { useState } from 'react'
import { useAsync } from 'react-use'

import { getShuttleLockers } from '../../../services/admin'

export const useLockerLocation = ({ isFetch }) => {
    const [lockers, setLockers] = useState([])
    const [loading, setLoading] = useState(false)

    useAsync(async () => {
        if (!isFetch) return
        setLoading(true)
        try {
            const response = await getShuttleLockers()
            setLockers(response)
        } catch (error) {}
        setLoading(false)
    }, [isFetch])

    return { lockers, loading }
}
