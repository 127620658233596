import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@material-ui/core/';
import { BtnLanguage, NavBarByRole } from '../../../../../reusableComponents';
import { useLoginForm } from './useLogin';
import { phoneCodes } from '../../../../../data/constans';
import { SubmitButton } from '../../styles';
import estilamLogo from '../../../../../assets/estilamLogo.png';
import s from '../authForm.module.scss';

function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, char =>
          String.fromCodePoint(char.charCodeAt(0) + 127397),
        )
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    '& div .MuiOutlinedInput-root': {
      padding: '6px',
    },
  },
});

const LoginForm = () => {
  const { t } = useTranslation();
  const { error, logIn } = useLoginForm();
  const classes = useStyles();

  const f = useFormik({
    initialValues: {
      code: { code: 'SA', label: 'Saudi Arabia', phone: '966' },
      phone: '',
    },
    validationSchema: Yup.object({
      code: Yup.string().required(t('validationForm').required),
      phone: Yup.string()
        .min(9, t('validationForm').phoneMinClient)
        .required(t('validationForm').required)
        .matches(/^\d{9,11}$/, {
          message: t('validationForm').phoneValidation,
        }),
    }),
  });

  const handleSubmit = e => {
    e.preventDefault();
    const data = { phone: `+${f.values.code.phone}${f.values.phone}` };
    logIn(data);
  };

  return (
    <div className={s.container}>
      <NavBarByRole />
      <div className={s.formContainer}>
        <div className={s.btnLanguage}>
          <BtnLanguage />
        </div>
        <div className={s.logo}>
          <img src={estilamLogo} width="120" alt="Logo" />
        </div>

        <form onSubmit={e => handleSubmit(e)} className={s.loginForm}>
          <div className={s.inputs}>
            <Autocomplete
              id="code"
              name="code"
              style={{ width: '30%' }}
              options={phoneCodes}
              classes={{
                option: classes.option,
              }}
              autoHighlight
              disableClearable={true}
              value={f.values.code}
              autoSelect={true}
              getOptionSelected={(option, value) => option.code === value.code}
              getOptionLabel={option => option.phone}
              onChange={(e, value) => {
                f.setFieldValue(
                  'code',
                  value !== null ? value : f.initialValues.code,
                );
              }}
              renderOption={option => (
                <>
                  <span>{countryToFlag(option.code)}</span>+{option.phone}
                </>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('authForm').logInClient.code}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
            <FormControl
              size="medium"
              style={{ marginLeft: '30px', width: '40%' }}
            >
              <InputLabel htmlFor="phone">
                {f.values.code.phone === '966' ? '5xxxxxxxx' : 'xxxxxxxxxx'}
              </InputLabel>
              <Input
                id="phone"
                name="phone"
                type="tel"
                value={f.values.phone}
                onBlur={f.handleBlur}
                onChange={f.handleChange}
                autoComplete="off"
              />
              <FormHelperText error>
                {f.errors?.phone || error ? f.errors?.phone : ' '}
              </FormHelperText>
            </FormControl>
          </div>
          <SubmitButton
            type="submit"
            disabled={f.values.phone === '' || !!f.errors?.phone}
            style={{ width: '70%', margin: '20px auto 0' }}
          >
            {t('authForm').logInClient.logInBtn}
          </SubmitButton>
        </form>
        {/* <p>{t("authForm").logInClient.signUpLinkLabel}</p>
        <Link to={AUTH_CLIENT_ROUTES.CLIENT_SIGNUP.path}>
          <Button variant="outlined" color="secondary">
            {t("authForm").logInClient.signUpBtn}
          </Button>
        </Link> */}
      </div>
    </div>
  );
};

export default LoginForm;
