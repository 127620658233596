import { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAsync } from "react-use";
import { logInClient, verifyEmail } from "../../../../../services";
import { AUTH_CLIENT_ROUTES } from "../../../../../routes";
import { useAuth } from "../../../../../context";

export const useLoginForm = () => {
    const { otp } = useAuth()
    const { push } = useHistory()
    const { pathname } = useLocation()
    const [error, setError] = useState(false)

    const logIn = async (phoneNumber) => {
        try {
            await logInClient(phoneNumber)
            otp(true)
            push({
                pathname: AUTH_CLIENT_ROUTES.CLIENT_VERIFY.path,
                state: phoneNumber,
            })
        } catch (error) {
            setError(true)
        }
    }
      useAsync(async () => {
        try {
          if (pathname.includes("token")) {
            const arr = pathname.split("/");
            const token = arr[arr.length - 1];
            await verifyEmail(token);
          }
        } catch (error) {
          //showErrorMessage(error.response.data.message);
        }
      }, []);

    return { error, logIn }
}
