import { createMuiTheme } from "@material-ui/core/styles";
import i18n from "i18next";

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#e6c727",
      main: "#e6c727",
      dark: "#e6c727",
      contrastText: "#fff",
    },
    secondary: {
      light: "#3e3e3e",
      main: "#e6c727",
      dark: "#e6c727",
      contrastText: "#fff",
    },
    container: {
      grey: "#3e3e3e",
      lightGrey: '#f0f0f0'
    },
  },  
   typography: {
    fontFamily: i18n.language === 'ar' ? 'Kufyan Arabic, serif' : 'Farro, sans-serif'
  }

});
