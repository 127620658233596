import { Link, useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { TextField, FormGroup, FormControlLabel, Button } from "@material-ui/core/";
import { AUTH_ADMIN_ROUTES } from "../../../../../routes";
import { forgotPassword } from "../../../../../services";

import s from "../authForm.module.scss";

export const ForgotPasswordForm = () => {
  const { push } = useHistory();
  const {pathname} = useLocation();
  const { t } = useTranslation();

  const f = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      const res = await forgotPassword(values.email);
      if (res) {
        pathname.includes("admin") ? push("/admin/login") : push("/host/login");
      }
    },
  });
  
  return (
    <div className={s.container}>
      <div className={s.formContainer}>
        <form onSubmit={f.handleSubmit} style={{ width: "100%" }}>
          <FormGroup>
            <FormControlLabel
              label={t("authForm").forgotPassword.email}
              labelPlacement="top"
              style={{ marginBottom: 10 }}
              control={
                <TextField
                  id="email"
                  name="email"
                  value={f.values.email}
                  onChange={f.handleChange}
                  style={{ width: "400px" }}
                />
              }
            />
          </FormGroup>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={AUTH_ADMIN_ROUTES.ADMIN_LOGIN.path}>
              <Button color="secondary" variant="outlined" type="button">
                {t("authForm").forgotPassword.signinBtn}
              </Button>
            </Link>

            <Button color="primary" variant="contained" type="submit" style={{ marginLeft: 10 }}>
              {t("authForm").forgotPassword.restoreBtn}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
