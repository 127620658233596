import { useHistory } from "react-router-dom";
import { createContext, useContext, useState } from "react";
import { useAsync, useLocalStorage } from "react-use";
import axios from "axios";
import { decode } from "jsonwebtoken";
import { getCurrentUser } from "../services";
import { AUTH_CLIENT_ROUTES } from "../routes";

const isExpiredToken = (token) => {
  const { exp } = decode(token);
  return exp * 1000 - Date.now() <= 0;
};

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [token, setToken, removeToken] = useLocalStorage(process.env.REACT_APP_TOKEN_LOCALSTORAGE_KEY);
  const [isWaitingForPin, setIsWaitingForPin] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const [isProcessing, setIsProcessing] = useState(true);
  const { push } = useHistory();

  if (token) {
    if (isExpiredToken(token)) {
      removeToken();
      const role = sessionStorage.getItem('role');
      const redirectByRole = `/${role?.split('"').join('').toLowerCase()}/login`
      const redirectLink = !!role ? redirectByRole : AUTH_CLIENT_ROUTES.CLIENT_LOGIN.path
      push(redirectLink);
    } else {
      if(authUser){
        sessionStorage.setItem('role', JSON.stringify(authUser.role))
      }
      axios.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }

  const updateToken = (newToken) => {
    axios.defaults.headers.Authorization = `Bearer ${token}`;
    setToken(newToken);
  };

  const otp = (status) => {
    return setIsWaitingForPin(status);
  };

  const login = (user) => {
    sessionStorage.setItem('role', JSON.stringify(''));
    if (!user.access_token && user.role !== "Client") {
      return user.role === "Admin" ? push("/admin/login") : push("/host/login");
    }

    setAuthUser({ ...user, image: user.image ? user.image + "?" + new Date().getTime() : null });
    updateToken(user.access_token);

    if (user.role === "Client") {
      setIsWaitingForPin(false);
    }
  };

  const register = () => {};

  const logout = () => {
    sessionStorage.setItem('role', JSON.stringify(authUser.role));
    setAuthUser(null);
    removeToken();
    axios.defaults.headers.Authorization = "";
  };

  const updateUser = (user) => {
    let image = user.image;
    if (user.image) {
      image = user.image + `?${new Date().getTime()}`;
    }
    setAuthUser({ ...user, image });
  };

  useAsync(async () => {
    if (token && !isExpiredToken(token)) {
      const user = await getCurrentUser();

      if (!user) {
        logout();
        return;
      }
      setAuthUser(user);
    }
    setIsProcessing(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuth: !!authUser,
        isWaitingForPin,
        authUser,
        isProcessing,
        otp,
        login,
        register,
        logout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

//     useEffect(() => {
//         const interval = setInterval(() => {
//             if (authToken) {
//                 refreshUserToken()
//             } else {
//                 clearInterval(interval)
//             }
//         }, 60000)
//         return () => clearInterval(interval)
//     }, [authToken])
