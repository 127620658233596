import { useTranslation } from "react-i18next";
import { Typography, Grid, Switch } from "@material-ui/core/";
import { withStyles } from "@material-ui/core/styles";

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: 26,
      height: 16,
      padding: 0,
      paddingBottom: 2,
      margin: 0,
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(12px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.primary.main,
    },
    checked: {},
  }))(Switch);

export const BtnLanguage = () => {
    const { i18n } = useTranslation();
    
    const toogle = ({ target }) => {
      i18n.changeLanguage(target.name);
    };

  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>EN</Grid>
        <Grid item>
          <AntSwitch
            checked={i18n.language === "en" ? false : true}
            onChange={toogle}
            name={i18n.language === "en" ? "ar" : "en"}
          />
        </Grid>
        <Grid item>AR</Grid>
      </Grid>
    </Typography>
  );
};

