import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from "react-i18next";
import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core/'
import { useRegisterForm } from './useRegister'
import { BtnLanguage } from "../../../../../reusableComponents";
import { SubmitButton } from '../../styles'
import estilamLogo from "../../../../../assets/estilamLogo.png";
import s from '../authForm.module.scss'


const RegisterForm = () => {
    const { t } = useTranslation();
	const { error, signIn } = useRegisterForm()

	const f = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .min(12, t("validationForm").phoneMin)
        .max(13, t("validationForm").phoneMax)
        .required(t("validationForm").required)
        .matches(/^\+\d*$/, { message: t("validationForm").phoneValidation }),
    }),
    onSubmit: (values) => {
      signIn(values);
    },
  });

	const checkProperties = (obj) => {
    for (let key in obj) {
        if (obj[key] !== '')
            return false;
    }
    return true;
	}

	return (
    <div className={s.container}>
      <h3>{t("authForm").signUpClient.title}</h3>
      <div className={s.formContainer}>
        <div className={s.btnLanguage}>
          <BtnLanguage />
        </div>
        <div className={s.logo}>
          <img src={estilamLogo} width="120" alt="Logo" />
        </div>
        <form onSubmit={f.handleSubmit} className={s.registerForm}>
          <FormControl>
            <InputLabel htmlFor="phone">{t("authForm").signUpClient.phonePlaceholder}</InputLabel>
            <Input
              id="phone"
              name="phone"
              type="tel"
              value={f.values.phone}
              onBlur={f.handleBlur}
              onChange={f.handleChange}
              autoComplete="off"
            />
            {f.errors?.phone && f.touched.phone && <FormHelperText error>{f.errors?.phone}</FormHelperText>}
          </FormControl>
          {error && <FormHelperText error>{t("authForm").signUpClient.errorMessage}</FormHelperText>}
          <SubmitButton type="submit" disabled={checkProperties(f.values) || !!Object.values(f.errors).length}>
            {t("authForm").signUpClient.signUpBtn}
          </SubmitButton>
        </form>
      </div>
    </div>
  );
}

export default RegisterForm