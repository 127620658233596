import { useTranslation } from "react-i18next";
import moment from "moment";
import SyncIcon from "@material-ui/icons/Sync";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import BlockIcon from "@material-ui/icons/Block";
import TimelineIcon from "@material-ui/icons/Timeline";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import cn from "classnames";
import s from "./Logs.module.scss";

export const Logs = ({ listLogs }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const handleAction = (log) => {
    if (log.action === "status_changed") {
      return (
        <>
          <SyncIcon />
          <p>{t("logs").statusChanged}</p>
        </>
      );
    }
    if (log.action === "opened_locker_cabinet") {
      return (
        <>
          <VpnKeyIcon />
          <p>{`${t("logs").openedCabinet} ${log.author}`}</p>
        </>
      );
    }
    if (log.action === "opened_locker_cabinet_by_admin") {
      return (
        <>
          <VpnKeyIcon />
          <p>{t("logs").openedCabinetAdmin}</p>
        </>
      );
    }
    if (log.action === "opened_locker_cabinet_by_shared_key_holder") {
      return (
        <>
          <VpnKeyIcon />
          <p>{`${t("logs").openedCabinet} ${log.author}`}</p>
        </>
      );
    }
    if (log.action === "key_has_been_shared") {
      return (
        <>
          <TimelineIcon />
          <p>{`${log.author} ${t("logs").sharedKey} ${log.sharedTo.phoneNumber}`}</p>
        </>
      );
    }
    if (log.action === "key_has_been_revoked") {
      return (
        <>
          <BlockIcon />
          <p>{`${log.author} ${t("logs").revokeKey.revoke} ${log.revokeFrom.phoneNumber} ${
            t("logs").revokeKey.key
          }`}</p>
        </>
      );
    }
    if (
      log.action === "paid_new_reservation" ||
      log.action === "paid_proceed_reservation" ||
      log.action === "paid_fine_reservation"
    ) {
      return (
        <>
          <AttachMoneyIcon />
          <p>{`${t("logs").paid} ${log.author}`}</p>
        </>
      );
    }
    if (
      log.action === "paid_double_new_reservation" ||
      log.action === "paid_double_proceed_reservation" ||
      log.action === "paid_double_fine_reservation"
    ) {
      return (
        <>
          <AttachMoneyIcon />
          <p>{`${t("logs").extended} ${log.author}`}</p>
        </>
      );
    }
    if (
      log.action === "paid_reservation_cancel" ||
      log.action === "paid_extend_reservation_error" ||
      log.action === "paid_fine_reservation_error" ||
      log.action === "paid_double_reservation_error" ||
      log.action === "paid_double_extend_reservation_error" ||
      log.action === "paid_double_fine_reservation_error"
    ) {
      return (
        <>
          <BlockIcon />
          <p>{t("logs").errorPayment}</p>
        </>
      );
    }
    if (log.action === "created_reservation" || log.action === "use_free_subscription") {
      return (
        <>
          <CheckIcon />
          <p>{`${t("logs").create} ${log.author}`}</p>
        </>
      );
    }
    if (log.action === "status_changed_Cancel") {
      return (
        <>
          <CloseIcon />
          <p>{`${log.author} ${t("logs").canceled}`}</p>
        </>
      );
    }
    if (log.action === "reservation_completed_manually") {
      return (
        <>
          <CheckCircleIcon />
          <p>{`${t("logs").completed} ${log.author} `}</p>
        </>
      );
    }
  };

  return (
    <>
      {listLogs.map((log) => (
        <div className={s.stringTable} key={log.time}>
          <div className={cn(s.columnDate, language === "ar" && s.arColumnDate)}>
            <p>{moment(log.time).format("MMM/DD/YYYY")}</p>
            <p>{moment(log.time).format("HH:mm:ss")}</p>
          </div>
          <div className={cn(s.columnAction, language === "ar" && s.arColumnAction)}>
            <div className={s.boxLog}>{handleAction(log)}</div>
          </div>
        </div>
      ))}
    </>
  );
};
