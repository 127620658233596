import axios from "axios";

export * from "./client";
export * from "./admin";
export * from "./host";
export * from "./user";

axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_API_LOCAL
    : process.env.REACT_APP_BASE_URL_API;

axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("estilam_token");
      window.location.reload();
    }
    return Promise.reject(error);
  }
);
