import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import {
  Input,
  Button,
  ButtonGroup,
  TextField,
  InputLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useFormik } from 'formik';
import { updateUserImage } from '../../../services/admin';
import { useAuth } from '../../../context';

const bytesToMb = (bytes, roundTo) => {
  const converted = bytes / (1024 * 1024);
  return roundTo ? converted.toFixed(roundTo) : converted;
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #e6c727',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imgInput: {
    width: '100%',
  },
  imgBtn: {
    cursor: 'pointer',
    padding: '10px 20px',
    border: '1px solid #f0f0f0',
    borderRadius: '50px',
    marginLeft: '30px',
    marginTop: '20px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#000',
    backgroundColor: '#f0f0f0',
    marginBottom: 0,
    '&:hover': {
      color: '#fff',
      backgroundColor: '#e6c727',
    },
    '&:focus': {
      color: '#fff',
      backgroundColor: '#e6c727',
    },
  },
  imgFile: {
    opacity: 0,
    visibility: 'hidden',
    position: 'absolute',
  },
  imgBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    position: 'relative',
    marginBottom: '25px',
    width: '100%',
  },
}));

export const ChangeProfileLogo = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { updateUser } = useAuth();
  const [modalStyle] = useState(getModalStyle);
  const [fakeNameImg, setFakeNameImg] = useState('');
  const f = useFormik({
    initialValues: {
      image: null,
    },

    onSubmit: async (values, { resetForm }) => {
      const { data } = await updateUserImage(values);
      await updateUser(data);
      onClose();
      resetForm({});
      setFakeNameImg('');
    },
  });

  const getBase64 = file => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async function () {
      const res = reader.result.replace(/data:image\/\w*;base64,/, '');
      f.setValues({ image: res });
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const handleChangeUserLogo = ({ target }) => {
    const file = target.files[0];

    setFakeNameImg(file.name);

    const isBigestImg = bytesToMb(file.size) >= 3;

    if (!isBigestImg) {
      getBase64(file);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <form onSubmit={f.handleSubmit}>
          <div className={classes.imgBox}>
            <Input
              id="logo"
              name="logo"
              label="Logo"
              accept="image/*"
              type="file"
              onChange={handleChangeUserLogo}
              className={classes.imgFile}
            />
            <TextField
              fullWidth
              id="fakeNameImg"
              name="fakeNameImg"
              value={fakeNameImg}
              className={classes.imgInput}
            />
            <InputLabel className={classes.imgBtn} htmlFor="logo">
              {t('locationFormRoute').browseBtn}
            </InputLabel>
          </div>

          <ButtonGroup
            variant="contained"
            color="primary"
            aria-label="contained primary button group"
            fullWidth
          >
            <Button
              color="secondary"
              variant="outlined"
              fullWidth
              type="button"
              onClick={onClose}
            >
              {t('authForm').changeProfileLogo.cancelBtn}
            </Button>
            <Button color="primary" variant="contained" fullWidth type="submit">
              {t('authForm').changeProfileLogo.updateBtn}
            </Button>
          </ButtonGroup>
        </form>
      </div>
    </Modal>
  );
};
