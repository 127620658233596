export const en = {
  translation: {
    navBarByRole: {
      client: "Log In Client",
      admin: "Log In Admin",
      host: "Log In Host",
    },
    authForm: {
      forgotPassword: {
        email: "Email",
        signinBtn: "Sign In",
        restoreBtn: "Restore",
      },
      signIn: {
        emailPlaceholder: "Email",
        passwordPlaceholder: "Password",
        forgotPasswordLinkLabel: "Forgot Password",
        signupBtn: "Sign Up",
        signinBtn: "Sign In",
      },
      signUp: {
        firstNamePlaceholder: "First Name",
        lastNamePlaceholder: "Last Name",
        emailPlaceholder: "Email",
        phonePlaceholder: "Phone Number",
        passwordPlaceholder: "Password",
        confirmPasswordPlaceholder: "Confirm Password",
        signupBtn: "Sign Up",
        signinBtn: "Sign In",
      },
      changePassword: {
        old: "Old Password",
        new: "New Password",
        confirm: "Confirm New Password",
        cancelBtn: "Cancel",
        changeBtn: "Change",
      },
      changeProfileLogo: {
        cancelBtn: "Cancel",
        updateBtn: "Update",
      },
      logInClient: {
        title: "Log In",
        logInBtn: "Log In",
        code: "Select code",
        phonePlaceholder: "Phone +966xxxxxxxxx",
        signUpLinkLabel: "Don’t have an account?",
        signUpBtn: "Sign Up",
      },
      signUpClient: {
        title: "Create account",
        firstNamePlaceholder: "First Name",
        lastNamePlaceholder: "Last Name",
        emailPlaceholder: "Email",
        phonePlaceholder: "Phone Number +966xxxxxxxxx",
        signUpBtn: "Sign Up",
        errorMessage: "Some field exist in DB",
      },
      verifyForm: {
        title: "Verification",
        getSMS: "Will get OTP via SMS",
        pin: "pin",
        verifyBtn: "Verify",
        getSMSAgain: "Send OTP again",
      },
    },
    usersRoute: {
      title: "Users",
      newUser: "New User",
    },
    userFormRoute: {
      titleCreate: "Create User",
      titleEdit: "Edit User",
      firstNamePlaceholder: "First Name",
      lastNamePlaceholder: "Last Name",
      emailPlaceholder: "Email",
      phonePlaceholder: "Phone Number",
      rolePlaceholder: "Role",
      hostPlaceholder: "Host",
      cancelBtn: "Cancel",
      submitBtn: "Submit",
    },
    subscriptionsRoute: {
      title: "Subscriptions",
      newPlan: "New Plan",
    },
    subscriptionFormRoute: {
      titleCreate: "Create Plan",
      titleEdit: "Edit Plan",
      visibleName: "Visible Name",
      titleSizes: "Cabinet Sizes",
      sizeS: "Small",
      sizeM: "Medium",
      sizeL: "Large",
      titlePriceS: "Small size price",
      titlePriceM: "Medium size price",
      titlePriceL: "Large size price",
      titleDuration: "Duration",
      selectEffectsPlaceholder: "Please select what happens when reservation is overdue",
      selectEffectsOptions: {
        none: "None",
        release: "Release the cabinet",
        fine: "Client pays overdue fees",
      },
      titleFinePerMinute: "Overdue fees (currency/minute)",
      key: "Sharing key feature",
      titleLocations: "Locations preferences",
      cancelBtn: "Cancel",
      submitBtn: "Submit",
    },
    subscriptionDetailsRoute: {
      title: "Plan Detail",
      plan: "Plan",
      titlePlan: "Plan name",
      titleSizes: "Locker sizes",
      titleS: "Small size price",
      titleM: "Medium size price",
      titleL: "Large size price",
      titleLocations: "Plan locations preferences",
      locations: "Locations",
      duration: "Duration",
      finePerMinute: "Overdue fees/ minute",
      status: "Plan status",
      key: "Share key",
      editBtn: "Edit",
      deactivateBtn: "Deactivate",
      activateBtn: "Activate",
      changeBtn: "Change preference",
    },
    lockersRoute: {
      title: "Lockers",
    },
    lockerDetailsRoute: {
      backBtnText: "Back",
      lockerInfo: {
        locker: "Locker",
        location: "Location",
        utilization: "Utilization",
        sizes: "Sizes",
      },
      cabinetsGrid: {
        title: "Cabinets Grid",
        cabinetInfo: {
          status: "Status",
          state: "State",
          number: "Number",
          size: "Size",
          width: "Width",
          height: "Height",
          row: "Row",
          column: "Column",
          hideBtnsText: "Hide Cabinet Info",
          openCabinetBtnText: "Open Cabinet",
        },
        reservationInfo: {
          number: "No.",
          phone: "Phone Number",
          duration: "Duration",
          price: "Price",
          amount: "Amount",
          date: "Final Date & Time",
          status: "Status",
        },
      },
      openCabinet: { success: "Cabinet has been opened successfully" },
    },
    reservationsRoute: {
      title: "Reservations",
      download: "Download CSV",
      logsModal: {
        title: "Activity logs",
        cancelBtn: "Cancel",
      },
    },
    reservationDetailsRoute: {
      title: "Reservation Details",
      reservationId: "Reservation",
      name: "Name",
      phone: "Phone Number",
      location: "Location",
      locker: "Locker number",
      cabinet: "Cabinet number",
      size: "Cabinet size",
      duration: "Duration",
      amount: "Amount",
      price: "Price",
      date: "Final Date & Time",
      status: "Status",
      key: "Share key",
      completeBtn: "Complete",
      disactiveBtn: "Disactive",
      cabinetBtn: "Open cabinet",
      resendBtn: "Resend key",
      revokeBtn: "Revoke",
    },
    locationsRoute: {
      title: "Locations",
      newLocation: "New Location",
    },
    locationFormRoute: {
      titleCreate: "Create Location",
      titleEdit: "Edit Location",
      host: "Host name",
      nameAr: "Location name in Arabic",
      nameEn: "Location name in English",
      address: "Location address",
      url: "Location URL",
      descriptionEnglish: "Locker location description in English",
      descriptionArabic: "Locker location description in Arabic",
      photo: "Locker location Photo",
      largeImageError: "Image is large then 15MB",
      setLockerBtn: "Set Locker",
      browseBtn: "Browse",
      cancelBtn: "Cancel",
      submitBtn: "Submit",
      modal: {
        title: "Lockers List",
        cancelBtn: "Cancel",
      },
    },
    locationDetailsRoute: {
      title: "Location Details",
      location: "Location",
      name: "Location name",
      address: "Address",
      map: "Map",
      status: "Location status",
      locker: "Locker Number",
      size: "Sizes",
      utilization: "Utilization",
      lockerStatus: "Locker status",
      info: "Location info",
      description: "Locker location description",
      photo: "Locker location photo",
      editBtn: "Edit",
      deleteBtn: "Delete",
      uploadBtn: "Upload",
      deactivateBtn: "Deactivate",
      activateBtn: "Activate",
    },
    hostsRoute: {
      title: "Hosts",
      newHost: "New Host",
    },
    hostFormRoute: {
      titleCreate: "Create Host",
      titleEdit: "Edit Host",
      hostNamePlaceholder: "Host Name",
      cancelBtn: "Cancel",
      submitBtn: "Submit",
    },
    settingsRoute: {
      title: "Settings",
      billings: {
        title: "Billings",
        companyName: "Company name",
        commercialRecord: "Commercial record number",
        currency: "Currency",
        vat: "VAT",
        cancelBtn: "Cancel",
        submitBtn: "Submit",
      },
      conditions: {
        title: "Term & conditions",
        browseBtn: "Browse",
        placeholder: "PDF-document",
        description: "Document with the terms and conditions for the provision of services",
      },
    },
    clientLogged: {
      welcome: "Welcome, Dear customer",
      cabinet: "Book a new cabinet",
      reservations: "My reservations",
    },
    clientCabinet: {
      search: "Search for locker",
      scan: "Scan the locker",
    },
    clientListLockers: {
      title: "List lockers",
      search: "Enter the name of the locker...",
      notFound: "Not found",
      filterForm: {
        title: "Fill the form",
        cityPlaceholder: "City",
        districtPlaceholder: "District",
        loading: "Loading...",
        cancelBtn: "Cancel",
        okBtn: "Ok",
      },
    },
    clientScanner: {
      title: "Point the camera at the QR code",
      error: "QR code is not read or it is absent in the database",
    },
    clientSelectSize: {
      title: "Select size",
      notAvaliableCabibets: "No vacant cabinets",
      notFound: "Not found",
    },
    createReservation: {
      title: "Cabinet info",
      cabinetNumber: "Cabinet number",
      cabinetSize: "Cabinet size",
      lockerNumber: "Locker number",
      lockerName: "Locker name",
      chosePlan: "Chose plan",
      selectPlaceholder: "Select",
      payOverdueFeesBtn: "overdue fees (paid upon pickup)",
      payBtn: "Pay",
      bookBtn: "Book",
      location: "Location",
      agreementText: "By booking cabinet, you agree to our",
      agreementLink: "term & conditions",
      notFound: "Not found",
      invoice: {
        title: "Reservation invoice",
        cabinetNumber: "Cabinet number",
        duration: "Duration",
        price: "Price",
        tax: "Tax",
        total: "Total",
      },
      loaders: {
        cabinet: "Checking cabinet...",
        reservation: "Creating reservation...",
        payment: "Launching payment...",
      },
    },
    clientAllReservations: {
      title: "My reservations",
      notFound: "Not found",
      paid: "paid",
      unpaid: "unpaid",
      tabs: {
        current: "Current",
        completed: "Completed",
        canceled: "Canceled",
      },
    },
    reservationInfo: {
      title: "Reservation info:",
      reservationNumber: "Res no.",
      status: { title: "Status", paid: "paid", unpaid: "unpaid" },
      plan: "Plan",
      cabinetNumber: "Cabinet number",
      cabinetSize: "Cabinet size",
      lockerNumber: "Locker no.",
      location: "Location",
      finalDate: "Final date",
      warn: {
        titleWarn: "Your reservation near to expire.",
        textWarn1: "Please confirm you picked up your stuff from the cabinet to end your reservation successfully.",
        textWarn2: "Also, you can extend your reservation before it end.",
        textGrateful: "You confirmed you have picked up your stuff from the cabinet.Thank you for using our service.",
        confirmBtn: "Confirm",
        titleExpired: "Your reservation is expired.",
        textExpired1: "You will not be able to open the cabinet.",
        textExpired2: "To activate open cabinet button you should pay the applicable fees",
        subtitleExpired: "Notice",
        textExpired3:
          "After you paid the overdue fees the open cabinet button will be active for one time and you reservation will be finished",
      },
      openCabinetBtn: "Open cabinet",
      shareKeyBtn: "Share key",
      payOverdueFeesBtn: "Pay overdue fees",
      finePerMinute: "Overdue fees/ minute",
      endBtn: "End reservation",
      poppers: {
        reservationInfo: "Reservation info",
        invoices: "Invoices",
        keys: "Key table",
        logs: "Activity logs",
        extend: "Extend reservation",
        cancel: "Cancel",
      },
    },
    reservationInvoice: {
      title: "Invoices",
      invoiceNumber: "Invoice number",
      vat: "VAT",
      client: "Client",
      price: "Price",
      currency: "SAR",
      total: "Total",
    },
    reservationShareKey: {
      title: "Key table",
      shareKeyBtn: "Share the key with others",
      error: "Unfortunately, sharing the key is prohibited.",
      modal: {
        title: "Key secondary user information",
        name: "Name",
        phone: "Phone +966xxxxxxxxx",
        cancelBtn: "Cancel",
        submitBtn: "Submit",
      },
    },
    reservationErrorPayment: {
      errorText: "An error occurred while making a payment, please try again",
    },
    userPageCabinet: {
      title: "Smart Key",
      hello: { hey: "Hey,", cabinetOwner: "Cabinet owner", shared: "has been shared a cabinet key with you" },
      cabinetInfo: "Cabinet info",
      cabinetOwner: "Cabinet owner:",
      reservationNumber: "Reservation no:",
      location: "Location:",
      lockerId: "Locker ID:",
      cabinetNumber: "Cabinet no:",
      lockerDirection: "Locker direction:",
      lockerDescription: "Locker description:",
      detailsBtn: "More details",
      openCabinetBtn: "Open cabinet",
      callBtn: "Call customer support",
      error: {
        revoked: "Cabinet owner has been revoked your smart key.",
        toDoTitle: "We suggest you to do the following:",
        reach: "Reach out cabinet owner",
        askHim: "Ask him/her to reactivate your smart key or send you a new key",
        support: "Reach out customer support",
        activeKey: "If your smart key still active and you still facing this issue, call customer support for help.",
      },
    },
    userPageDetails: {
      lockerInfo: "Locker information",
      lockerDescription: "Locker Description:",
      lockerPhotosTitle: "Locker Photos:",
      openCabinetBtn: "Open cabinet",
      back: "Back",
      lockerPhotosNotFound: "Not Found",
    },
    openCabinetModal: {
      questionOpen: "Are you sure you want to open the cabinet?",
      cancelBtn: "Cancel",
      yesBtn: "Yes",
    },
    freeCabinetModal: {
      questionFree: "Do you confirm that you want to free the cabinet and   not to continue the booking of it more?",
      cancelBtn: "Cancel",
      yesBtn: "Yes",
    },
    errorPaymentModal: {
      tryAgainBtn: "Try again",
      callBtn: "Call",
      payment: "Payment service is temporary unavailable. Please try again later or contact to support.",
      location: "Location is inactive",
      cabinetUsed: "Cabinet is already using",
      cabinetReserved: "Cabinet is not reserved",
    },
    completeReservationModal: {
      questionComplete:
        "Do you confirm that you want to free the cabinet and   not to continue the booking of it more?",
      cancelBtn: "Cancel",
      yesBtn: "Yes",
    },
    copyLinkModal: {
      copyBtn: "Copy",
      cancelBtn: "Cancel",
    },
    successPaymentModal: {
      title: "Congratulations! You have reserved the cabinet",
      yesBtn: "Yes",
    },
    cancelReservationModal: {
      questionOpen: "Are you sure you want to cancel your reservation?",
      cancelBtn: "Cancel",
      yesBtn: "Yes",
    },
    header: {
      backBtn: "Back",
    },
    logs: {
      title: "Activity logs",
      notFound: "Not found",
      statusChanged: "The status of the cabinet has changed",
      openedCabinet: "Cabinet opened by",
      sharedKey: "shared cabinet key with",
      revokeKey: { revoke: "revoke", key: "key" },
      create: "Cabinet has been booked by",
      paid: "Reservation has been paid by",
      extended: "Reservation has been extended by",
      canceled: "Canceled the cabinet reservation",
      errorPayment: "An error occurred while making a payment",
      openedCabinetAdmin: "Cabinet opened by Admin",
      completed: "Reservation has been completed manually by",
    },
    sizes: {
      s: { title: "Small size" },
      m: { title: "Medium size" },
      l: { title: "Large size" },
    },
    address: {
      none: "None",
      city: {
        Riyadh: "Riyadh",
        Dammam: "Dammam",
        Jeddah: "Jeddah",
        Kharj: "Kharj",
        الرياض: "Riyadh",
        الدمام: "Dammam",
        جدة: "Jeddah",
        الخبر: "Kharj",
      },
      districts: {
        "Ad Dar Al Baida": "Ad Dar Al Baida",
        "Ad Difa": "Ad Difa",
        "Ad Dirah": "Ad Dirah",
        "Ad Dubiyah": "Ad Dubiyah",
        "Ad Duraihimiyah": "Ad Duraihimiyah",
        "Adh Dhubbat": "Adh Dhubbat",
        "Al Arid": "Al Arid",
        "Al Badiah": "Al Badiah",
        "Al Bariah": "Al Bariah",
        "Al Batha": "Al Batha",
        "Al Dho": "Al Dho",
        "Al Fakhriyah": "Al Fakhriyah",
        "Al Falah": "Al Falah",
        "Al Faruq": "Al Faruq",
        "Al Futah": "Al Futah",
        "Al Ghadir": "Al Ghadir",
        "Al Ghnamiah": "Al Ghnamiah",
        "Al Haeer": "Al Haeer",
        "Al Hamra": "Al Hamra",
        "Al Hazm": "Al Hazm",
        "Al Imam University": "Al Imam University",
        "Al Iskan": "Al Iskan",
        "Al Izdihar": "Al Izdihar",
        "Al Janadriyah": "Al Janadriyah",
        "Al Jaradiyah": "Al Jaradiyah",
        "Al Jazirah": "Al Jazirah",
        "Al Khalidiyah": "Al Khalidiyah",
        "Al Madhar": "Al Madhar",
        "Al Madhar Ash Shamali": "Al Madhar Ash Shamali",
        "Al Mahdiyah": "Al Mahdiyah",
        "Al Maizilah": "Al Maizilah",
        "Al Malaz": "Al Malaz",
        "Al Malqa": "Al Malqa",
        "Al Manakh": "Al Manakh",
        "Al Mansurah": "Al Mansurah",
        "Al Marqab": "Al Marqab",
        "Al Marwah": "Al Marwah",
        "Al Masani": "Al Masani",
        "Al Masif": "Al Masif",
        "Al Mikal": "Al Mikal",
        "Al Misfat": "Al Misfat",
        "Al Mughrizat": "Al Mughrizat",
        "Al Munsiyah": "Al Munsiyah",
        "Al Murabba": "Al Murabba",
        "Al Mursalat": "Al Mursalat",
        "Al Muruj": "Al Muruj",
        "Al Mutamarat": "Al Mutamarat",
        "Al Qirawan": "Al Qirawan",
        "Al Quds": "Al Quds",
        "Al Qura": "Al Qura",
        "Al Shohada": "Al Shohada",
        "Al Ud": "Al Ud",
        "Al Umjiah": "Al Umjiah",
        "Al Uraija": "Al Uraija",
        "Al Uraija Al Gharbiyah": "Al Uraija Al Gharbiyah",
        "Al Uraija Al Wusta": "Al Uraija Al Wusta",
        "Al Wadi": "Al Wadi",
        "Al Wazarat": "Al Wazarat",
        "Al Wisham": "Al Wisham",
        "Al Wurud": "Al Wurud",
        "Al Wusayta": "Al Wusayta",
        "Al Yamamah": "Al Yamamah",
        "Al Yarmuk": "Al Yarmuk",
        "Al Yasmin": "Al Yasmin",
        "An Nafl": "An Nafl",
        "An Namudhajiyah": "An Namudhajiyah",
        "An Narjis": "An Narjis",
        "An Nasim Al Gharbi": "An Nasim Al Gharbi",
        "An Nasim Ash Sharqi": "An Nasim Ash Sharqi",
        "An Nazim": "An Nazim",
        "Ar Rabwah": "Ar Rabwah",
        "Ar Rafiah": "Ar Rafiah",
        "Ar Rahmaniyah": "Ar Rahmaniyah",
        "Ar Raid": "Ar Raid",
        "Ar Rayan": "Ar Rayan",
        "Ar Rimal": "Ar Rimal",
        "Ar Rimayah": "Ar Rimayah",
        Areed: "Areed",
        "As Saadah": "As Saadah",
        "As Safarat": "As Safarat",
        "As Sahafah": "As Sahafah",
        "As Salhiyah": "As Salhiyah",
        "As Sinaiyah": "As Sinaiyah",
        "As Sulai": "As Sulai",
        "As Sulimaniyah": "As Sulimaniyah",
        "As Suwaidi": "As Suwaidi",
        "As Suwaidi Al Gharbi": "As Suwaidi Al Gharbi",
        "Ash Sharafiyah": "Ash Sharafiyah",
        "Ash Shimaisi": "Ash Shimaisi",
        "Az Zahra": "Az Zahra",
        "Az Zahrah": "Az Zahrah",
        Banban: "Banban",
        "Dahiat Namar": "Dahiat Namar",
        "Dhahrat Al Badiah": "Dhahrat Al Badiah",
        "Dhahrat Laban": "Dhahrat Laban",
        "Dhahrat Namar": "Dhahrat Namar",
        Dirab: "Dirab",
        Ghubairah: "Ghubairah",
        Hittin: "Hittin",
        Hyt: "Hyt",
        Ilaishah: "Ilaishah",
        Irqah: "Irqah",
        Ishbiliyah: "Ishbiliyah",
        Jabrah: "Jabrah",
        Jarir: "Jarir",
        "Khashm Al An": "Khashm Al An",
        "King Abdul Aziz": "King Abdul Aziz",
        "King Abdullah": "King Abdullah",
        "King Fahd": "King Fahd",
        "King Faisal": "King Faisal",
        "King Khalid Airport": "King Khalid Airport",
        "King Saud University": "King Saud University",
        Laban: "Laban",
        Manfuhah: "Manfuhah",
        "Manfuhah Al Jadidah": "Manfuhah Al Jadidah",
        Mansuriyah: "Mansuriyah",
        Namar: "Namar",
        "New Industrial Area": "New Industrial Area",
        "Salah Ad Din": "Salah Ad Din",
        Shubra: "Shubra",
        Siyah: "Siyah",
        Skirinah: "Skirinah",
        Sultanah: "Sultanah",
        Taibah: "Taibah",
        Thulaim: "Thulaim",
        Tuwaiq: "Tuwaiq",
        "Um Alshal": "Um Alshal",
        "Umm Al Hamam Al Gharbi": "Umm Al Hamam Al Gharbi",
        "Umm Al Hamam Ash Sharqi": "Umm Al Hamam Ash Sharqi",
        "Umm Salim": "Umm Salim",
        Uqaz: "Uqaz",
        Utaiqah: "Utaiqah",
        "Ad Dabab": "Ad Dabab",
        "Ad Danah": "Ad Danah",
        "Ad Danah Al Janubiyah": "Ad Danah Al Janubiyah",
        "Ad Danah Ash Shamaliyah": "Ad Danah Ash Shamaliyah",
        "Ad Dawasir": "Ad Dawasir",
        "Ad Dawhah Al Janubiyah": "Ad Dawhah Al Janubiyah",
        "Ad Dawhah Ash Shamaliyah": "Ad Dawhah Ash Shamaliyah",
        "Al Adamah": "Al Adamah",
        "Al Amal": "Al Amal",
        "Al Amamrah": "Al Amamrah",
        "Al Amanah": "Al Amanah",
        "Al Amir Muhammed Ibn Saud": "Al Amir Muhammed Ibn Saud",
        "Al Andalus": "Al Andalus",
        "Al Anud": "Al Anud",
        "Al Anwar": "Al Anwar",
        "Al Aqiq": "Al Aqiq",
        "Al Aqrabiyah": "Al Aqrabiyah",
        "Al Athir": "Al Athir",
        "Al Aziziyah": "Al Aziziyah",
        "Al Badi": "Al Badi",
        "Al Badiyah": "Al Badiyah",
        "Al Bahar": "Al Bahar",
        "Al Bandariyah": "Al Bandariyah",
        "Al Basatin": "Al Basatin",
        "Al Buhayrah (Dammam)": "Al Buhayrah (Dammam)",
        "Al Buhayrah (Khobar)": "Al Buhayrah (Khobar)",
        "Al Bustan": "Al Bustan",
        "Al Faiha": "Al Faiha",
        "Al Faisaliyah": "Al Faisaliyah",
        "Al Fanar": "Al Fanar",
        "Al Firdaws": "Al Firdaws",
        "Al Fursan": "Al Fursan",
        "Al Hada": "Al Hada",
        "Al Hadabah": "Al Hadabah",
        "Al Hamra (Dammam)": "Al Hamra (Dammam)",
        "Al Hamra (Khobar)": "Al Hamra (Khobar)",
        "Al Haras Al Watani": "Al Haras Al Watani",
        "Al Hizam Adh Dhahabi": "Al Hizam Adh Dhahabi",
        "Al Hizam Al Akhdar": "Al Hizam Al Akhdar",
        "Al Hussam": "Al Hussam",
        "Al Iskan (Dammam)": "Al Iskan (Dammam)",
        "Al Iskan (Khobar)": "Al Iskan (Khobar)",
        "Al Itisalat": "Al Itisalat",
        "Al Jalawiyah": "Al Jalawiyah",
        "Al Jamiah": "Al Jamiah",
        "Al Jamiyin": "Al Jamiyin",
        "Al Jawharah (Dammam)": "Al Jawharah (Dammam)",
        "Al Jawharah (Khobar)": "Al Jawharah (Khobar)",
        "Al Jisr": "Al Jisr",
        "Al Kawthar": "Al Kawthar",
        "Al Khalidiyah Al Janubiyah": "Al Khalidiyah Al Janubiyah",
        "Al Khalidiyah Ash Shamaliyah": "Al Khalidiyah Ash Shamaliyah",
        "Al Khalij": "Al Khalij",
        "Al Khawr": "Al Khawr",
        "Al Khubar Al Janubiyah": "Al Khubar Al Janubiyah",
        "Al Khubar Ash Shamaliyah": "Al Khubar Ash Shamaliyah",
        "Al Khuzama": "Al Khuzama",
        "Al Kurnaish": "Al Kurnaish",
        "Al Kuthriah": "Al Kuthriah",
        "Al Lulu": "Al Lulu",
        "Al Maha (Dammam)": "Al Maha (Dammam)",
        "Al Maha (Khobar)": "Al Maha (Khobar)",
        "Al Manar": "Al Manar",
        "Al Matar": "Al Matar",
        "Al Mazruiyah": "Al Mazruiyah",
        "Al Muhammadiyah": "Al Muhammadiyah",
        "Al Muntazah": "Al Muntazah",
        "Al Muraikabat": "Al Muraikabat",
        "Al Murjan": "Al Murjan",
        "Al Qadisiyah": "Al Qadisiyah",
        "Al Qashlah": "Al Qashlah",
        "Al Qazaz": "Al Qazaz",
        "Al Qusur": "Al Qusur",
        "Al Ulaya": "Al Ulaya",
        "Al Wahah": "Al Wahah",
        "Al Yarmok": "Al Yarmok",
        "An Nada": "An Nada",
        "An Nahdah": "An Nahdah",
        "An Nakhil": "An Nakhil",
        "An Nasim": "An Nasim",
        "An Nasriyah": "An Nasriyah",
        "An Nawras": "An Nawras",
        "An Nur": "An Nur",
        "An Nuzhah": "An Nuzhah",
        "Ar Rabi": "Ar Rabi",
        "Ar Rabiyah": "Ar Rabiyah",
        "Ar Raja": "Ar Raja",
        "Ar Rakah Al Janubiyah": "Ar Rakah Al Janubiyah",
        "Ar Rakah Ash Shamaliyah": "Ar Rakah Ash Shamaliyah",
        "Ar Rawabi": "Ar Rawabi",
        "Ar Rawdah": "Ar Rawdah",
        "Ar Rayyan": "Ar Rayyan",
        "As Sadafah": "As Sadafah",
        "As Safa": "As Safa",
        "As Sahil": "As Sahil",
        "As Saif": "As Saif",
        "As Salam": "As Salam",
        "As Sawari": "As Sawari",
        "As Sufun": "As Sufun",
        "As Suq": "As Suq",
        "Ash Sharq": "Ash Sharq",
        "Ash Shati Al Gharbi": "Ash Shati Al Gharbi",
        "Ash Shati Ash Sharqi": "Ash Shati Ash Sharqi",
        "Ash Sheraa": "Ash Sheraa",
        "Ash Shifa": "Ash Shifa",
        "Ash Shulah": "Ash Shulah",
        "At Taawn": "At Taawn",
        "At Tahliyah": "At Tahliyah",
        "At Tubayshi": "At Tubayshi",
        "Ath Thuqbah": "Ath Thuqbah",
        "Az Zuhur": "Az Zuhur",
        Badr: "Badr",
        "Dahiyat Al Malik Fahd": "Dahiyat Al Malik Fahd",
        "Gharb Adh Dhahran": "Gharb Adh Dhahran",
        Ghirnatah: "Ghirnatah",
        Hajr: "Hajr",
        "Ibn Khaldun": "Ibn Khaldun",
        "Ibn Sina": "Ibn Sina",
        Industrial: "Industrial",
        "Industrial Area No1": "Industrial Area No1",
        "Industrial City 2": "Industrial City 2",
        Ishbiliya: "Ishbiliya",
        Kfupm: "Kfupm",
        "King Abdul Aziz Seaport": "King Abdul Aziz Seaport",
        "King Fahd International Airport": "King Fahd International Airport",
        "King Faysal University": "King Faysal University",
        "Madinat Al Umal (Dammam)": "Madinat Al Umal (Dammam)",
        "Madinat Al Ummal (Khobar)": "Madinat Al Ummal (Khobar)",
        "Qasr Al Khalij": "Qasr Al Khalij",
        Qurtubah: "Qurtubah",
        "Sinaiyah Al Foaziah": "Sinaiyah Al Foaziah",
        "Sinaiyah Ath Thuqbah": "Sinaiyah Ath Thuqbah",
        "Sporting City": "Sporting City",
        Taybah: "Taybah",
        Tihamah: "Tihamah",
        Uhud: "Uhud",
        "Abhur Al Janubiyah": "Abhur Al Janubiyah",
        "Abhur Ash Shamaliyah": "Abhur Ash Shamaliyah",
        "Abruq Ar Rughamah": "Abruq Ar Rughamah",
        "Ad Dahiah": "Ad Dahiah",
        "Al Adel": "Al Adel",
        "Al Ajaweed": "Al Ajaweed",
        "Al Ajwad": "Al Ajwad",
        "Al Amir Abdoulmajed": "Al Amir Abdoulmajed",
        "Al Amir Fawaz Al Janouby": "Al Amir Fawaz Al Janouby",
        "Al Amir Fawaz Ash Shamaly": "Al Amir Fawaz Ash Shamaly",
        "Al Ammariyah": "Al Ammariyah",
        "Al Amwaj": "Al Amwaj",
        "Al Asalah": "Al Asalah",
        "Al Baghdadiyah Al Gharbiyah": "Al Baghdadiyah Al Gharbiyah",
        "Al Baghdadiyah Ash Sharqiyah": "Al Baghdadiyah Ash Sharqiyah",
        "Al Balad": "Al Balad",
        "Al Barakah": "Al Barakah",
        "Al Bashaer": "Al Bashaer",
        "Al Bawadi": "Al Bawadi",
        "Al Fadel": "Al Fadel",
        "Al Fadeylah": "Al Fadeylah",
        "Al Farouk": "Al Farouk",
        "Al Ferdous": "Al Ferdous",
        "Al Frosyah": "Al Frosyah",
        "Al Hamadaniyah": "Al Hamadaniyah",
        "Al Hindawiyah": "Al Hindawiyah",
        "Al Jawharah": "Al Jawharah",
        "Al Kandarah": "Al Kandarah",
        "Al Karamah": "Al Karamah",
        "Al Khomrah": "Al Khomrah",
        "Al Loaloa": "Al Loaloa",
        "Al Mahjar": "Al Mahjar",
        "Al Masarah": "Al Masarah",
        "Al Montazah": "Al Montazah",
        "Al Moulysaa": "Al Moulysaa",
        "Al Mutanazahat": "Al Mutanazahat",
        "Al Qouzeen": "Al Qouzeen",
        "Al Qryniah": "Al Qryniah",
        "Al Quraiyat": "Al Quraiyat",
        "Al Waziriyah": "Al Waziriyah",
        "Al Yaqoot": "Al Yaqoot",
        "An Naim": "An Naim",
        "An Nazlah Al Yamaniyah": "An Nazlah Al Yamaniyah",
        "An Nazlah Ash Sharqiyah": "An Nazlah Ash Sharqiyah",
        "Ar Rahmah": "Ar Rahmah",
        "Ar Rahmanyah": "Ar Rahmanyah",
        "Ar Rayaan": "Ar Rayaan",
        "Ar Rihab": "Ar Rihab",
        "Ar Riyadh": "Ar Riyadh",
        "Ar Ruwais": "Ar Ruwais",
        "As Sabil": "As Sabil",
        "As Sahifah": "As Sahifah",
        "As Salamah": "As Salamah",
        "As Samir": "As Samir",
        "As Sanabel": "As Sanabel",
        "As Sarawat": "As Sarawat",
        "As Sororyah": "As Sororyah",
        "As Swaryee": "As Swaryee",
        "Ash Shati": "Ash Shati",
        "Ash Shefaa": "Ash Shefaa",
        "Ash Shrouk": "Ash Shrouk",
        "At Taawon": "At Taawon",
        "At Tadamon": "At Tadamon",
        "Ath Thaalibah": "Ath Thaalibah",
        "Ath Thaghr": "Ath Thaghr",
        "Az Zomorod": "Az Zomorod",
        Bahrah: "Bahrah",
        "Bani Malik": "Bani Malik",
        Bryman: "Bryman",
        Ghulail: "Ghulail",
        "Industrial Area": "Industrial Area",
        "Jeddah Eslamic Seaport": "Jeddah Eslamic Seaport",
        "King Abdul Aziz International Airport": "King Abdul Aziz International Airport",
        "King Abdul Aziz Medical City": "King Abdul Aziz Medical City",
        "King Abdul Aziz University": "King Abdul Aziz University",
        "King Faisal Naval Base": "King Faisal Naval Base",
        "Madain Al Fahd": "Madain Al Fahd",
        Mishrifah: "Mishrifah",
        Mraykh: "Mraykh",
        Petromin: "Petromin",
        "Um Asalam": "Um Asalam",
        "Um Hableen": "Um Hableen",
        "ابن خلدون": "Ibn Khaldun",
        احد: "Uhud",
        الاتصالات: "Al Itisalat",
        الاسكان: "Al Iskan",
        "الإسكان (الدمام)": "Al Iskan (Dammam)",
        "الاسكان (الخبر)": "Al Iskan (Khobar)",
        "الامير محمد بن سعود": "Al Amir Muhammed Ibn Saud",
        الأثير: "Al Athir",
        الأمانة: "Al Amanah",
        العمل: "Al Amal",
        الأنوار: "Al Anwar",
        البادية: "Al Badiyah",
        "البحيرة (الدمام)": "Al Buhayrah (Dammam)",
        "البحيرة (الخبر)": "Al Buhayrah (Khobar)",
        البديع: "Al Badi",
        البساتين: "Al Basatin",
        الجامعيين: "Al Jamiyin",
        الجلوية: "Al Jalawiyah",
        الجوهرة: "Al Jawharah",
        "الجوهرة (الدمام)": "Al Jawharah (Dammam)",
        "الجوهرة (الخبر)": "Al Jawharah (Khobar)",
        الحسام: "Al Hussam",
        الحمراء: "Al Hamra",
        "الحمراء (الدمام)": "Al Hamra (Dammam)",
        "الحمراء (الخبر)": "Al Hamra (Khobar)",
        "الخالدية الجنوبية": "Al Khalidiyah Al Janubiyah",
        "الخالدية الشمالية": "Al Khalidiyah Ash Shamaliyah",
        الخضرية: "Al Kuthriah",
        الخليج: "Al Khalij",
        الدانة: "Ad Danah",
        الدواسر: "Ad Dawasir",
        الرابية: "Ar Rabiyah",
        "الراكة الشمالية": "Ar Rakah Ash Shamaliyah",
        الربيع: "Ar Rabi",
        الروضة: "Ar Rawdah",
        الريان: "Ar Rayyan",
        الزهور: "Az Zuhur",
        السلام: "As Salam",
        السوق: "As Suq",
        السيف: "As Saif",
        "الشاطئ الشرقي": "Ash Shati Ash Sharqi",
        "الشاطئ الغربي": "Ash Shati Al Gharbi",
        الشرق: "Ash Sharq",
        الشعلة: "Ash Shulah",
        الشفا: "Ash Shifa",
        الصدفة: "As Sadafah",
        الصفا: "As Safa",
        الصناعية: "Industrial",
        "الصناعية الاولى": "Industrial Area No1",
        الضباب: "Ad Dabab",
        الطبيشي: "At Tubayshi",
        العدامة: "Al Adamah",
        العزيزية: "Al Aziziyah",
        العمامرة: "Al Amamrah",
        العنود: "Al Anud",
        الفردوس: "Al Firdaws",
        الفرسان: "Al Fursan",
        الفنار: "Al Fanar",
        الفيحاء: "Al Faiha",
        الفيصلية: "Al Faisaliyah",
        القادسية: "Al Qadisiyah",
        القزاز: "Al Qazaz",
        المحمدية: "Al Muhammadiyah",
        "المدينة الصناعية الثانية": "Industrial City 2",
        المريكبات: "Al Muraikabat",
        المزروعية: "Al Mazruiyah",
        المطار: "Al Matar",
        المنار: "Al Manar",
        المنتزه: "Al Muntazah",
        "المها (الدمام)": "Al Maha (Dammam)",
        "المها (الخبر)": "Al Maha (Khobar)",
        الناصرية: "An Nasriyah",
        النخيل: "An Nakhil",
        الندى: "An Nada",
        النزهة: "An Nuzhah",
        النسيم: "An Nasim",
        النهضة: "An Nahdah",
        النور: "An Nur",
        النورس: "An Nawras",
        الهضبة: "Al Hadabah",
        الواحة: "Al Wahah",
        بدر: "Badr",
        "ضاحية الملك فهد": "Dahiyat Al Malik Fahd",
        طيبة: "Taybah",
        غرناطة: "Ghirnatah",
        "قصر الخليج": "Qasr Al Khalij",
        "مدينة العمال (الدمام)": "Madinat Al Umal (Dammam)",
        "مدينة العمال(الخبر)": "Madinat Al Ummal (Khobar)",
        "مدينة الملك فيصل الجامعية": "King Faysal University",
        "مطار الملك فهد الدولي": "King Fahd International Airport",
        "ميناء الملك عبدالعزيز": "King Abdul Aziz Seaport",
        "ابن سيناء": "Ibn Sina",
        اشبيليا: "Ishbiliya",
        الامواج: "Al Amwaj",
        الاندلس: "Al Andalus",
        البحر: "Al Bahar",
        البستان: "Al Bustan",
        البندرية: "Al Bandariyah",
        التحلية: "At Tahliyah",
        التعاون: "At Taawn",
        الثقبة: "Ath Thuqbah",
        الجسر: "Al Jisr",
        "الحزام الاخضر": "Al Hizam Al Akhdar",
        "الحزام الذهبي": "Al Hizam Adh Dhahabi",
        "الخبر الجنوبية": "Al Khubar Al Janubiyah",
        "الخبر الشمالية": "Al Khubar Ash Shamaliyah",
        الخزامى: "Al Khuzama",
        الخور: "Al Khawr",
        "الراكة الجنوبية": "Ar Rakah Al Janubiyah",
        الرجاء: "Ar Raja",
        الروابي: "Ar Rawabi",
        الساحل: "As Sahil",
        السفن: "As Sufun",
        الشراع: "Ash Sheraa",
        الصواري: "As Sawari",
        العقربية: "Al Aqrabiyah",
        العقيق: "Al Aqiq",
        العليا: "Al Ulaya",
        الكوثر: "Al Kawthar",
        الكورنيش: "Al Kurnaish",
        اللؤلؤ: "Al Lulu",
        "المدينة الرياضية": "Sporting City",
        المرجان: "Al Murjan",
        الهدا: "Al Hada",
        اليرموك: "Al Yarmok",
        "صناعية الثقبة": "Sinaiyah Ath Thuqbah",
        "صناعية الفوازية": "Sinaiyah Al Foaziah",
        قرطبة: "Qurtubah",
        "الدوحة الجنوبية": "Ad Dawhah Al Janubiyah",
        "الدانة الشمالية": "Ad Danah Ash Shamaliyah",
        القصور: "Al Qusur",
        "الدوحة الشمالية": "Ad Dawhah Ash Shamaliyah",
        "الدانة الجنوبية": "Ad Danah Al Janubiyah",
        الجامعة: "Al Jamiah",
        "الحرس الوطني": "Al Haras Al Watani",
        تهامة: "Tihamah",
        القشلة: "Al Qashlah",
        هجر: "Hajr",
        "غرب الظهران": "Gharb Adh Dhahran",
        "جامعة الملك فهد للبترول والمعادن": "Kfupm",
        الزمرد: "Az Zomorod",
        الياقوت: "Al Yaqoot",
        الصوارى: "As Swaryee",
        الفلاح: "Al Falah",
        الاصالة: "Al Asalah",
        مريخ: "Mraykh",
        الشروق: "Ash Shrouk",
        "الامير فواز الشمالى": "Al Amir Fawaz Ash Shamaly",
        الرياض: "Ar Riyadh",
        الفروسية: "Al Frosyah",
        الرحمانية: "Ar Rahmanyah",
        الصالحية: "As Salhiyah",
        الحمدانية: "Al Hamadaniyah",
        "ام حبلين": "Um Hableen",
        بريمان: "Bryman",
        المنتزة: "Al Montazah",
        الثعالبة: "Ath Thaalibah",
        البلد: "Al Balad",
        الفاروق: "Al Farouk",
        العدل: "Al Adel",
        الهنداوية: "Al Hindawiyah",
        المحجر: "Al Mahjar",
        الخالدية: "Al Khalidiyah",
        "جامعة الملك عبدالعزيز": "King Abdul Aziz University",
        "البغدادية الشرقية": "Al Baghdadiyah Ash Sharqiyah",
        "النزلة الشرقية": "An Nazlah Ash Sharqiyah",
        البوادي: "Al Bawadi",
        السلامة: "As Salamah",
        الثغر: "Ath Thaghr",
        الشرفية: "Ash Sharafiyah",
        "قاعدة الملك فيصل البحرية": "King Faisal Naval Base",
        السنابل: "As Sanabel",
        التضامن: "At Tadamon",
        الكرامة: "Al Karamah",
        الرحمة: "Ar Rahmah",
        البركة: "Al Barakah",
        المسرة: "Al Masarah",
        المليساء: "Al Moulysaa",
        القوزين: "Al Qouzeen",
        الوادي: "Al Wadi",
        الفضيلة: "Al Fadeylah",
        السروات: "As Sarawat",
        الخمرة: "Al Khomrah",
        الضاحية: "Ad Dahiah",
        القرينية: "Al Qryniah",
        "النزلة اليمانية": "An Nazlah Al Yamaniyah",
        القريات: "Al Quraiyat",
        غليل: "Ghulail",
        الكندرة: "Al Kandarah",
        العمارية: "Al Ammariyah",
        الصحيفة: "As Sahifah",
        السبيل: "As Sabil",
        "بنى مالك": "Bani Malik",
        الرحاب: "Ar Rihab",
        مشرفة: "Mishrifah",
        الورود: "Al Wurud",
        "ابرق الرغامة": "Abruq Ar Rughamah",
        السليمانية: "As Sulimaniyah",
        البشائر: "Al Bashaer",
        النعيم: "An Naim",
        الربوة: "Ar Rabwah",
        المروة: "Al Marwah",
        السامر: "As Samir",
        الاجواد: "Al Ajwad",
        "مطار الملك عبدالعزيز الدولي": "King Abdul Aziz International Airport",
        "المنطقة الصناعية": "Industrial Area",
        "الامير عبدالمجيد": "Al Amir Abdoulmajed",
        الوزيريه: "Al Waziriyah",
        "الامير فواز الجنوبى": "Al Amir Fawaz Al Janouby",
        المتنزهات: "Al Mutanazahat",
        الفضل: "Al Fadel",
        "ام السلم": "Um Asalam",
        "مدينة الملك عبدالعزيز الطبية": "King Abdul Aziz Medical City",
        "البغدادية الغربية": "Al Baghdadiyah Al Gharbiyah",
        "ابحر الشمالية": "Abhur Ash Shamaliyah",
        الرويس: "Ar Ruwais",
        بترومين: "Petromin",
        "ابحر الجنوبية": "Abhur Al Janubiyah",
        السرورية: "As Sororyah",
        الاجاويد: "Al Ajaweed",
        الشاطئ: "Ash Shati",
        "مدائن الفهد": "Madain Al Fahd",
        الزهراء: "Az Zahra",
        بحرة: "Bahrah",
        "ميناء جدة الاسلامى": "Jeddah Eslamic Seaport",
        النموذجية: "An Namudhajiyah",
        الجرادية: "Al Jaradiyah",
        "منفوحة الجديدة": "Manfuhah Al Jadidah",
        الفاخرية: "Al Fakhriyah",
        الديرة: "Ad Dirah",
        "ام الحمام الشرقي": "Umm Al Hamam Ash Sharqi",
        "المعذر الشمالي": "Al Madhar Ash Shamali",
        "ام الحمام الغربي": "Umm Al Hamam Al Gharbi",
        لبن: "Laban",
        الرفيعة: "Ar Rafiah",
        الشهداء: "Al Shohada",
        "الملك فهد": "King Fahd",
        السويدي: "As Suwaidi",
        الحزم: "Al Hazm",
        عتيقة: "Utaiqah",
        المربع: "Al Murabba",
        المرسلات: "Al Mursalat",
        "الملك فيصل": "King Faisal",
        "المدينة الصناعية الجديدة": "New Industrial Area",
        المنصورة: "Al Mansurah",
        غبيرة: "Ghubairah",
        الجزيرة: "Al Jazirah",
        السعادة: "As Saadah",
        المناخ: "Al Manakh",
        الدفاع: "Ad Difa",
        "الملك عبدالله": "King Abdullah",
        "صلاح الدين": "Salah Ad Din",
        "الملك عبدالعزيز": "King Abdul Aziz",
        الوزارات: "Al Wazarat",
        سكيرينة: "Skirinah",
        جرير: "Jarir",
        المعذر: "Al Madhar",
        الملز: "Al Malaz",
        منفوحة: "Manfuhah",
        عليشة: "Ilaishah",
        الضباط: "Adh Dhubbat",
        "السويدي الغربي": "As Suwaidi Al Gharbi",
        ديراب: "Dirab",
        نمار: "Namar",
        عكاظ: "Uqaz",
        شبرا: "Shubra",
        الزهرة: "Az Zahrah",
        صياح: "Siyah",
        سلطانة: "Sultanah",
        اليمامة: "Al Yamamah",
        البديعة: "Al Badiah",
        المصانع: "Al Masani",
        الدريهمية: "Ad Duraihimiyah",
        "النسيم الشرقي": "An Nasim Ash Sharqi",
        القدس: "Al Quds",
        النفل: "An Nafl",
        المصيف: "Al Masif",
        الازدهار: "Al Izdihar",
        "ظهرة البديعة": "Dhahrat Al Badiah",
        النظيم: "An Nazim",
        الرماية: "Ar Rimayah",
        البرية: "Al Bariah",
        المنصورية: "Mansuriyah",
        "ضاحية نمار": "Dahiat Namar",
        المصفاة: "Al Misfat",
        السفارات: "As Safarat",
        "خشم العان": "Khashm Al An",
        طويق: "Tuwaiq",
        "ظهرة نمار": "Dhahrat Namar",
        المغرزات: "Al Mughrizat",
        السلي: "As Sulai",
        الغدير: "Al Ghadir",
        المروج: "Al Muruj",
        العود: "Al Ud",
        ثليم: "Thulaim",
        الشميسي: "Ash Shimaisi",
        الوشام: "Al Wisham",
        الدوبية: "Ad Dubiyah",
        المعكال: "Al Mikal",
        جبرة: "Jabrah",
        القرى: "Al Qura",
        المرقب: "Al Marqab",
        الفوطة: "Al Futah",
        "ام سليم": "Umm Salim",
        الصحافة: "As Sahafah",
        الرائد: "Ar Raid",
        "العريجاء الغربية": "Al Uraija Al Gharbiyah",
        العريجاء: "Al Uraija",
        "العريجاء الوسطى": "Al Uraija Al Wusta",
        "الدار البيضاء": "Ad Dar Al Baida",
        البطحاء: "Al Batha",
        المؤتمرات: "Al Mutamarat",
        الوسيطاء: "Al Wusayta",
        الجنادرية: "Al Janadriyah",
        اشبيلية: "Ishbiliyah",
        المعيزلة: "Al Maizilah",
        المونسية: "Al Munsiyah",
        عرقة: "Irqah",
        "ظهرة لبن": "Dhahrat Laban",
        حطين: "Hittin",
        الملقا: "Al Malqa",
        القيروان: "Al Qirawan",
        الياسمين: "Al Yasmin",
        العارض: "Al Arid",
        "مطار الملك خالد": "King Khalid Airport",
        النرجس: "An Narjis",
        "جامعة الامام": "Al Imam University",
        بنبان: "Banban",
        الرمال: "Ar Rimal",
        الدحو: "Al Dho",
        العماجية: "Al Umjiah",
        هيت: "Hyt",
        الحائر: "Al Haeer",
        "ام الشعال": "Um Alshal",
        " الغنامية": "Al Ghnamiah",
        عريض: "Areed",
        المهدية: "Al Mahdiyah",
        "جامعة الملك سعود": "King Saud University",
        "النسيم الغربي": "An Nasim Al Gharbi",
        المشاعل: "Al Mishal",
      },
    },
    sidebar: {
      reservations: "Reservations",
      locations: "Locations",
      subscriptions: "Subscriptions",
      lockers: "Lockers",
      users: "Users",
      hosts: "Hosts",
      settings: "Settings",
    },
    navbar: {
      logo: "Update Logo",
      password: "Change password",
      signOut: "Sign Out",
    },
    tabs: {
      Active: "Current",
      Inactive: "Inactive",
      Completed: "Completed",
      Canceled: "Canceled",
      Expired: "Expired",
      notFound: "Not Found",
    },
    reactTable: {
      ofText: "of",
      pageText: "Page",
      nextText: "Next",
      previousText: "Previous",
    },
    columnsHeaders: {
      reservations: {
        number: "No.",
        name: "Name",
        phone: "Phone Number",
        location: "Location",
        locker: "Locker No.",
        cabinet: "Cabinet No.",
        size: "Cabinet Size",
        duration: "Duration",
        price: "Price",
        amount: "Amount",
        date: "Final Date & Time",
        status: "Status",
        actions: "Actions",
        poppers: {
          details: "Details",
          logs: "Logs",
        },
      },
      reservationDetails: {
        key: "Key No.",
        type: "Key type",
        owner: "Owner",
        phone: "Phone Number",
        status: "Key status",
      },
      users: {
        name: "Name",
        type: "Type",
        phone: "Phone Number",
        email: "Email",
        status: "Status",
        actions: "Actions",
        poppers: {
          edit: "Edit",
          deactivate: "Deactivate",
          activate: "Activate",
        },
      },
      subscriptions: {
        visibleName: "Visible Name",
        sizes: "Sizes",
        small: "Small Price",
        medium: "Medium Price",
        large: "Large Price",
        duration: "Duration",
        finePerMinute: "Overdue fees/ minute",
        key: "Share Key",
        location: "Location",
        status: "Status",
        actions: "Actions",
        poppers: {
          edit: "Edit",
          details: "Details",
        },
      },
      subscriptionsForm: {
        name: "Name",
        address: "Address",
        location: "Location Id",
        action: "Action",
        btnDelete: "delete",
      },
      locations: {
        name: "Name",
        address: "Address",
        map: "Map",
        locker: "Locker Name",
        sizes: "Sizes",
        utilization: "Utilization",
        statusLocker: "Locker Status",
        statusLocation: "Location Status",
        actions: "Actions",
        host: "Host Name",
        status: {
          unlimited: "Unlimited",
          limited: "Limited",
        },
        poppers: {
          edit: "Edit",
          details: "Details",
        },
      },
      reservationShareKey: {
        name: "Name",
        phoneNumber: "Phone Number",
        status: "Status",
        actions: "Actions",
        poppers: {
          revoke: "Revoke",
          resend: "Re-send key link",
          copy: "Copy key-link",
        },
      },
      hosts: {
        name: "Name",
        numberLocation: "Number of locations",
        status: "Status",
        actions: "Actions",
        poppers: {
          edit: "Edit",
          deactivate: "Deactivate",
          activate: "Activate",
        },
      },
      lockers: {
        locker: "Locker",
        location: "Location name",
        utilization: "Utilization",
        sizes: "Sizes",
        actions: "Actions",
        poppers: {
          details: "Details",
        },
      },
    },
    validationForm: {
      name: "Minimum 1 character",
      password: "Minimum 6 character",
      email: "Invalid email",
      urlValidation: "Invalid url. Enter the url in the format https://xxxxxx.xxx (for example, https://khzn.net)",
      durationValidation: "Invalid value, enter it in the format HH:MM:SS",
      priceValidation: "Invalid value, enter it in the format 00.00",
      phoneMin: "In the format +966xxxxxxxxx",
      phoneMinClient: "In the format XXXXXXXXX",
      phoneMax: "Maximum 13 characters",
      phoneValidation: "Invalid value",
      pinMin: "Should be 4 characters",
      pinMax: "Maximum 4 characters",
      roleHelperText: "Please select your role",
      hostHelperText: "Please select your host",
      required: "Required field",
    },
  },
};
