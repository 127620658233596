import { Route, Redirect } from 'react-router-dom'

import { useAuth } from '../context/authContext'

export const PublicRoute = ({ component: Component, redirectTo, ...routeProps }) => {
    const { isAuth } = useAuth()
    return (
        <Route
            {...routeProps}
            render={(props) =>
                isAuth && routeProps.restricted ? <Redirect to={redirectTo} /> : <Component {...props} />
            }
        />
    )
}
