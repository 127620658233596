import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import s from './NavBarByRole.module.scss'

export const NavBarByRole = () => {
    const {pathname} = useLocation();
    const { t } = useTranslation();
    const currentPath = pathname.split('/')[1]
    
    return(
        <ul className={s.list}>
            <li key='client'>
                <a href={process.env.NODE_ENV === "development" 
                ? `${process.env.REACT_APP_LOCAL}/client/login`
                : `${process.env.REACT_APP_PROD}/client/login`} 
                className={currentPath === 'client' ? `${s.link} ${s.activeLink}` : s.link}
                >
                {t("navBarByRole").client}
          </a>
         </li>
        <li key='admin'>
            <a href={process.env.NODE_ENV === "development" 
                ? `${process.env.REACT_APP_LOCAL}/admin/login`
                : `${process.env.REACT_APP_PROD}/admin/login`} 
                className={currentPath === 'admin' ? `${s.link} ${s.activeLink}` : s.link}
                >
                {t("navBarByRole").admin}
          </a>
        </li>
        <li key='host'>
            <a href={process.env.NODE_ENV === "development" 
                ? `${process.env.REACT_APP_LOCAL}/host/login`
                : `${process.env.REACT_APP_PROD}/host/login`} 
                className={currentPath === 'host' ? `${s.link} ${s.activeLink}` : s.link}
                >
                {t("navBarByRole").host}
          </a>
       </li>
     </ul>
    )
}