import { useState } from 'react'
import { useLocation } from "react-router-dom";
import { useAsync } from "react-use";
import { useAuth } from '../../../../../context'
import { signInAdmin, verifyEmail} from "../../../../../services/admin";
import {signInHost } from "../../../../../services/host"
import { showErrorMessage } from "../../../../../reusableComponents";

export const useSignInForm = () => {
    const { login } = useAuth()
    const { pathname } = useLocation();
    const [error, setError] = useState(false)

    const signIn = async (credentials) => {
        try {
          if(pathname.includes("admin")){
            const { data } = await signInAdmin(credentials)
            await login(data)
          } else {
            const { data } = await signInHost(credentials);
            await login(data);
            }
        } catch (error) {
          if (error.response?.data?.message === "User is not active") {
            showErrorMessage(error.response?.data?.message);
          } else {
            showErrorMessage("Incorrect email or password");
          }
        }
    }

     useAsync(async () => {
       try {
         if (pathname.includes("token")) {
           const arr = pathname.split("/");
           const token = arr[arr.length-1];
           await verifyEmail(token);
         }
       } catch (error) {
         //showErrorMessage(error.response.data.message);
       }
     }, []);

    return { error, signIn }
}
