import { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Button, ButtonGroup } from "@material-ui/core";
import { Logs, Loader } from "../../../reusableComponents";
import { useLogsModal } from "./useLogsModal";
import s from "./LogsModal.module.scss";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-50%, -40%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #e6c727",
    boxShadow: theme.shadows[5],
    padding: "30px",
    textAlign: "center",
  },
}));

export const LogsModal = ({ isOpen, id, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [modalStyle] = useState(getModalStyle);

  const { logs, loading } = useLogsModal({ isFetch: isOpen, id });

  return (
    <Modal
      open={isOpen}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{ overflow: "scroll", height: "100vh" }}
    >
      <div style={modalStyle} className={classes.paper}>
        <h2 className={s.title}>{t("reservationsRoute").logsModal.title}</h2>
        <Loader show={loading} />
        <div>
          {logs && <div className={s.logsTable}>{logs?.length ? <Logs listLogs={logs} /> : t("logs").notFound}</div>}
        </div>
        <ButtonGroup
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
          fullWidth
          className={s.btn}
        >
          <Button color="secondary" variant="outlined" fullWidth type="button" onClick={onClose}>
            {t("reservationsRoute").logsModal.cancelBtn}
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  );
};
