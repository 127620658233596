import axios from "axios";

export const getCurrentUser = async () => {
  try {
    const { data } = await axios.get("/user");
    return data;
  } catch (error) {
    return null;
  }
};

export const getUsers = async ({ page, status = "Active" }) => {
  const params = {
    query: { $and: [{ status }] },
    sort: "-_id",
    page,
  };
  const response = await axios.get("/users/admin", { params });

  const hasMore = response.data.page < response.data.totalPages;
  const data = { ...response.data, hasMore };

  return data;
};

export const getUserById = async (id) => {
  const response = await axios.get(`/users/admin/${id}`);
  return response;
};

export const updateUserById = async (data) => {
  const response = await axios.put("/user/admin", data);
  return response;
};

export const updateUserStatus = async (data) => {
  const response = await axios.put("/user/admin/status", data);
  return response;
};

export const postNewUser = async (data) => {
  const response = await axios.post("/user/admin", data);
  return response;
};

export const changeUserPassword = async (data) => {
  const response = await axios.post("user/admin/password", data);
  return response;
};

export const updateUserImage = async (data) => {
  const response = await axios.post("/user/image", data);
  return response;
};
