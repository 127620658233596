import axios from 'axios';

export const getLockers = async () => {
  const response = await axios.get('/lockers');
  const data = { rows: response.data };
  return data;
};

export const getLockerById = async ({ id, host = null }) => {
  const params = host ? { params: { host } } : null;

  const response = await axios.get(`/lockers/${id}`, params);
  return response;
};

export const postOpenCabinetAdmin = async data => {
  const response = await axios.post('/locker/open', data);
  return response;
};
