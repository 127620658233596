import { useAuth } from '../context'

import { ROLE } from '../data/constans'

const useUserRole = () => {
    const { authUser } = useAuth()

    if (authUser) {
        const { role } = authUser
        return {
            isAdmin: role === ROLE.ADMIN,
            isClient: role === ROLE.CLIENT,
            isHost: role === ROLE.HOST
        }
    } else {
        return {
            isAdmin: null,
            isClient: null,
            isHost: null
        }
    }
}

export { useUserRole }
