import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useAsync } from 'react-use';
import { Popover } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { CancelReservationModal } from '../../../reusableComponents';
import { getLockerById } from '../../../services';
import cn from 'classnames';
import s from './reservationDetailsPopover.module.scss';

export default function TransitionsPopover({
  reservation,
  setReservation,
  location,
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openCancelReservation, setOpenCancelReservation] = useState(false);
  const [locker, setLocker] = useState(null);

  const toogleCancelReservation = () => setOpenCancelReservation(prev => !prev);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCanceledReservation = data => {
    setReservation(data);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useAsync(async () => {
    const { data } = await getLockerById({ id: reservation.locker.id });
    if (data) {
      setLocker(data);
    }
  }, []);

  return (
    <div className={s.box}>
      {reservation && location && (
        <>
          <div
            variant="text"
            size="small"
            aria-describedby={id}
            onClick={handleClick}
            className={open ? s.iconActive : s.icon}
          >
            <ListIcon className={s.btnPopper} />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <div
              className={cn(s.popper, language === 'ar' && s.rtl)}
              onClick={handleClose}
            >
              <Link
                to={`/reservations/${reservation._id}/info`}
                className={s.link}
              >
                {t('reservationInfo').poppers.reservationInfo}
              </Link>
              {reservation?.status === 'Active' && (
                <Link
                  to={`/reservations/${reservation._id}/share-key`}
                  className={s.link}
                >
                  {t('reservationInfo').poppers.keys}
                </Link>
              )}
              <Link
                to={`/reservations/${reservation._id}/invoice`}
                className={s.link}
              >
                {t('reservationInfo').poppers.invoices}
              </Link>
              <Link
                to={`/reservations/${reservation._id}/logs`}
                className={s.link}
              >
                {t('reservationInfo').poppers.logs}
              </Link>

              {reservation.status === 'Active' && locker && (
                <>
                  <Link
                    to={{
                      pathname: `/cabinet/${reservation._id}/reservations`,
                      state: {
                        reReservation: true,
                        idReservation: reservation._id,
                        paymentType:
                          (reservation.status === 'Active' &&
                            !reservation.isPaid &&
                            'restore') ||
                          (reservation.status === 'Active' &&
                            reservation.isPaid &&
                            'proceed') ||
                          (reservation.status === 'Completed' && 'restore'),
                        reservationOrigin: reservation,
                        cabinet: {
                          number: reservation.locker.cabinet,
                          size: reservation.locker.size,
                        },
                        locker: {
                          ...locker,
                          estilamSubscriptions:
                            locker.estilamSubscriptions.filter(
                              subs =>
                                subs.cabinets[reservation.locker.size].price &&
                                subs.cabinets[reservation.locker.size].price !==
                                  '00.00',
                            ),
                        },
                        location,
                      },
                    }}
                    className={s.link}
                  >
                    {t('reservationInfo').poppers.extend}
                  </Link>

                  <div className={s.link} onClick={toogleCancelReservation}>
                    {t('reservationInfo').poppers.cancel}
                  </div>
                </>
              )}
            </div>
          </Popover>
        </>
      )}
      <CancelReservationModal
        isOpen={openCancelReservation}
        onClose={() => setOpenCancelReservation(false)}
        onSubmit={handleCanceledReservation}
      />
    </div>
  );
}
