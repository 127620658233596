import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { TextField, FormGroup, FormControlLabel, Button } from "@material-ui/core/";
import { AUTH_ADMIN_ROUTES } from "../../../../../routes";
import { useSignUpForm } from "./useSignUp";
import { BtnLanguage } from "../../../../../reusableComponents";
import estilamLogo from "../../../../../assets/estilamLogo.png";
import s from "../authForm.module.scss";

export const SignUpForm = () => {
  const { t } = useTranslation();
  const { signUp } = useSignUpForm();

  const f = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      passwordConfirm: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().min(1, t("validationForm").name).required(t("validationForm").required),
      lastName: Yup.string().min(1, t("validationForm").name).required(t("validationForm").required),
      email: Yup.string().email(t("validationForm").email).required(t("validationForm").required),
      phone: Yup.string()
        .min(12, t("validationForm").phoneMin)
        .max(13, t("validationForm").phoneMax)
        .required(t("validationForm").required)
        .matches(/^\+\d*$/, {
          message: t("validationForm").phoneValidation,
        }),
      password: Yup.string().min(6, t("validationForm").password).required(t("validationForm").required),
      passwordConfirm: Yup.string().min(6, t("validationForm").password).required(t("validationForm").required),
    }),
    onSubmit: (values) => {signUp(values)},
  });

  return (
    <div className={s.container}>
      <div className={s.formContainer}>
        <div className={s.logoBox}>
          <div className={s.logo}>
            <img src={estilamLogo} width="120" alt="logo"/>
          </div>
          <BtnLanguage />
        </div>
        <form onSubmit={f.handleSubmit} style={{ width: "100%" }}>
          <FormGroup>
            <FormControlLabel
              label={t("authForm").signUp.firstNamePlaceholder}
              labelPlacement="top"
              control={
                <TextField
                  id="firstName"
                  name="firstName"
                  value={f.values.firstName}
                  onChange={f.handleChange}
                  error={f.errors?.firstName && f.touched.firstName ? true : false}
                  helperText={f.errors?.firstName && f.touched.firstName ? f.errors?.firstName : " "}
                />
              }
            />
            <FormControlLabel
              label={t("authForm").signUp.lastNamePlaceholder}
              labelPlacement="top"
              control={
                <TextField
                  id="lastName"
                  name="lastName"
                  value={f.values.lastName}
                  onChange={f.handleChange}
                  error={f.errors?.lastName && f.touched.lastName ? true : false}
                  helperText={f.errors?.lastName && f.touched.lastName ? f.errors?.lastName : " "}
                />
              }
            />
            <FormControlLabel
              label={t("authForm").signUp.emailPlaceholder}
              labelPlacement="top"
              control={
                <TextField
                  id="email"
                  name="email"
                  value={f.values.email}
                  onChange={f.handleChange}
                  error={f.errors?.email && f.touched.email ? true : false}
                  helperText={f.errors?.email && f.touched.email ? f.errors?.email : "qwerty@gmail.com"}
                />
              }
            />
            <FormControlLabel
              label={t("authForm").signUp.phonePlaceholder}
              labelPlacement="top"
              control={
                <TextField
                  id="phone"
                  name="phone"
                  value={f.values.phone}
                  onChange={f.handleChange}
                  error={f.errors?.phone && f.touched.phone ? true : false}
                  helperText={f.errors?.phone && f.touched.phone ? f.errors?.phone : "+966xxxxxxxxx"}
                />
              }
            />
            <FormControlLabel
              label={t("authForm").signUp.passwordPlaceholder}
              labelPlacement="top"
              control={
                <TextField
                  id="password"
                  name="password"
                  value={f.values.password}
                  onChange={f.handleChange}
                  error={f.errors?.password && f.touched.password ? true : false}
                  helperText={f.errors?.password && f.touched.password ? f.errors?.password : " "}
                />
              }
            />
            <FormControlLabel
              label={t("authForm").signUp.confirmPasswordPlaceholder}
              labelPlacement="top"
              control={
                <TextField
                  id="passwordConfirm"
                  name="passwordConfirm"
                  value={f.values.passwordConfirm}
                  onChange={f.handleChange}
                  error={f.errors?.passwordConfirm && f.touched.passwordConfirm ? true : false}
                  helperText={f.errors?.passwordConfirm && f.touched.passwordConfirm ? f.errors?.passwordConfirm : " "}
                />
              }
            />
          </FormGroup>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            <Link to={AUTH_ADMIN_ROUTES.ADMIN_LOGIN.path}>
              <Button color="secondary" variant="outlined" type="button">
                {t("authForm").signUp.signinBtn}
              </Button>
            </Link>

            <Button color="primary" variant="contained" type="submit" style={{ marginLeft: 10 }}>
              {t("authForm").signUp.signupBtn}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
