import axios from "axios";

export const getHosts= async ({ page, status = "Active" }) => {
  const params = {
    query: { $and: [{ status }] },
    sort: "-_id",
    page,
  };

  const response = await axios.get("/hosts", { params });

  const hasMore = response.data.page < response.data.totalPages;
  const data = { ...response.data, hasMore };
  return data;
};

export const postNewHost = async (data) => {
  const response = await axios.post("/hosts", data);
  return response;
};

export const getHostById = async (id) => {
  const response = await axios.get(`/hosts/${id}`);
  return response;
};

export const updateHostById = async (data) => {
  const response = await axios.put(`/hosts/${data.id}`, data);
  return response;
};

export const updateHostStatus = async (data) => {
  const response = await axios.put(`/host/${data.id}/status`, data);
  return response;
};



