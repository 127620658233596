import axios from "axios";

export const postDocument = async (data) => {
  const response = await axios.post(`/settings/${data.id}/document`, data);
  return response;
};

export const getSettings = async () => {
  const response = await axios.get("/settings");
  return response;
};

export const delDocument = async (id) => {
    const response = await axios.delete(`/settings/${id}/document`);
    return response;
}

export const updateSettings = async (data) => {
  const response = await axios.put(`/settings/${data._id}`, data);
  return response;
};