import DrawerItem from './DrawerItem'
import { useUserRole } from "../../hooks/useUserRole";
import { SIDEBAR_ADMIN_ROUTES, SIDEBAR_HOST_ROUTES } from "../../routes/";

import s from './DrawerList.module.scss'

const optionsAdmin = Object.values(SIDEBAR_ADMIN_ROUTES)
const optionsHost = Object.values(SIDEBAR_HOST_ROUTES);

const DrawerList = ({ isExpanded }) => {
    const { isAdmin } = useUserRole();

    return (
      <nav>
        <ul className={s.drawerList}>
          {isAdmin ? (
            <>
              {optionsAdmin.map((option) => (
                <DrawerItem key={option.title} {...option} isExpanded={isExpanded} />
              ))}
            </>
          ) : (
            <>
              {optionsHost.map((option) => (
                <DrawerItem key={option.title} {...option} isExpanded={isExpanded} />
              ))}
            </>
          )}
        </ul>
      </nav>
    );
}

export default DrawerList
