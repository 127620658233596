import axios from 'axios'

export const logInClient = async (phone) => {
	return axios.post('/user/client/login', phone)
}

export const createClient = async (credentials) => {
	return axios
		.post('/user/client', credentials)
}

export const sendClientOTP = async (credentials) => {
	return axios.post('/user/client/otp', credentials)
}
