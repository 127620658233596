import { useState } from "react";
import { useAsync } from "react-use";
import { useQuery, useQueryClient } from "react-query";

const DEFAULT_PAGE = 1;

export const useTabsContainer = ({ queryKey, getDataCallback, initialTab }) => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [tab, setTab] = useState(initialTab);

  const { data, isFetching, refetch } = useQuery([queryKey, page, tab], () => getDataCallback({ page, status: tab }), {
    keepPreviousData: true,
    staleTime: 5000,
  });

  useAsync(async () => {
    if (data && data.hasMore) {
      try {
        await queryClient.prefetchQuery([queryKey, page + 1, tab], () =>
          getDataCallback({
            page: page + 1,
            status: tab,
          })
        );
      } catch (error) {
        console.error(error);
      }
    }
  }, [data, page, queryClient, tab]);

  useAsync(async () => {
    try {
      await queryClient.prefetchQuery([queryKey, page, tab], () =>
        getDataCallback({
          page,
          status: tab,
        })
      );
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleChangeTab = (newTab) => {
    setTab(newTab);
    setPage(DEFAULT_PAGE);
  };

  return {
    tab,
    data,
    page,
    isFetching,
    setPage,
    selectTab: handleChangeTab,
    refetch,
  };
};
