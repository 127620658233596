import { Link, useLocation } from 'react-router-dom'

import s from './RouterHeader.module.scss'

export const RouteHeader = ({ title, route = false, styleHeader,  children }) => {
    const { pathname } = useLocation()
    const isShowRoute = !!route
    return (
        <div className={s.headerBlock}>
            <header className={s.header} style={styleHeader}>
                {title} {children}
            </header>
            {isShowRoute && (
                <Link to={pathname + '/' + route.path} className={s.routeBtn}>
                    <div>{route.title}</div>
                </Link>
            )}
        </div>
    )
}

