import React, { Suspense, lazy } from "react";
import { Switch, Redirect } from "react-router-dom";
import { AppNavBar, Loader } from "../../../reusableComponents";
import { PublicRoute, CLIENT_ROUTES } from "../../../routes";

const LoggedAsync = lazy(() => import("./User/Logged"));
const CabinetAsync = lazy(() => import("./User/Cabinet"));
const CabinetSearchAsync = lazy(() => import("./User/Cabinet/CabinetSearch"));
const CabinetScannerAsync = lazy(() => import("../Client/User/Cabinet/CabinetScanner"));
const SelectedLocationAsync = lazy(() => import("./User/SelectedLocation/SelectedLocation"));
const AllReservationsAsync = lazy(() => import("./User/Reservations/AllReservations/AllReservations"));
const ReservationDetailsAsync = lazy(() => import("./User/Reservations/ReservationDetails/ReservationDetails"));
const CreateReservationAsync = lazy(() => import("./User/Reservations/CreateReservation/CreateReservation"));
const ReservationErrorPaymentAsync = lazy(() =>
  import("./User/Reservations/ReservationErrorPayment/ReservationErrorPayment")
);

export const ClientRoutes = () => {

  return (
    <>
      <AppNavBar />
      <Suspense fallback={<Loader />}>
        <Switch>
          <PublicRoute component={LoggedAsync} path={CLIENT_ROUTES.USER.loggedIn} />
          <PublicRoute component={CabinetAsync} path={CLIENT_ROUTES.CABINET.bookNewCabinet} exact />
          <PublicRoute component={CabinetSearchAsync} path={CLIENT_ROUTES.CABINET.search} exact />
          <PublicRoute component={CabinetScannerAsync} path={CLIENT_ROUTES.CABINET.bookScan} exact />
          <PublicRoute component={AllReservationsAsync} path={CLIENT_ROUTES.RESERVATIONS.reviews} exact />
          <PublicRoute component={CreateReservationAsync} path={CLIENT_ROUTES.RESERVATIONS.createReservations} exact />
          <PublicRoute component={SelectedLocationAsync} path={CLIENT_ROUTES.CABINET.selectedLocation} />
          <PublicRoute component={ReservationErrorPaymentAsync} path={CLIENT_ROUTES.RESERVATIONS.errorPayment} exact />
          <PublicRoute component={ReservationDetailsAsync} path={CLIENT_ROUTES.RESERVATIONS.details} />
          <Redirect to={CLIENT_ROUTES.CABINET.bookNewCabinet} />
        </Switch>
      </Suspense>
    </>
  );
};
