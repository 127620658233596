import { Suspense, lazy } from "react";
import { Switch, Redirect } from "react-router-dom";
import { PrivatRoute, ADMIN_ROUTES, HOST_ROUTES } from "../../../routes";
import { Drawer, Loader, AppNavBar } from "../../../reusableComponents";
import { useUserRole } from "../../../hooks/useUserRole";
import { Container } from "./Container";
import Settings from './Settings'

// isAdmin //
const ReservationsAsync = lazy(() => import("./Reservations" /*webpackChunkName: "Reservations"*/));
const ReservationDetailsAsync = lazy(() =>
  import("./Reservations/details/ReservationDetails" /*webpackChunkName: "Reservations"*/)
);

const LocationsAsync = lazy(() => import("./Locations" /*webpackChunkName: "Locations"*/));
const LocationFormAsync = lazy(() => import("./Locations/form/LocationForm" /*webpackChunkName: "Locations"*/));
const LocationDetailsAsync = lazy(() => import("./Locations/details/LocationDetails" /*webpackChunkName: "Locations"*/));

const SubscriptionsAsync = lazy(() => import("./Subscriptions" /*webpackChunkName: "Subscriptions"*/));
const SubscriptionFormAsync = lazy(() =>
  import("./Subscriptions/form/SubscriptionForm" /*webpackChunkName: "Subscriptions"*/)
);
const SubscriptionDetailsAsync = lazy(() =>
  import("./Subscriptions/details/SubscriptionDetails" /*webpackChunkName: "Subscriptions"*/)
);
const LockersAsync = lazy(() => import("./Lockers" /*webpackChunkName: "Lockers"*/));
const LockerDetailsAsync = lazy(() => import("./Lockers/details" /*webpackChunkName: "Lockers"*/));

const UsersAsync = lazy(() => import("./Users" /*webpackChunkName: "Users"*/));
const UserFormAsync = lazy(() => import("./Users/form/UserForm" /*webpackChunkName: "Users"*/));

const HostsAsync = lazy(() => import("./Hosts" /*webpackChunkName: "Hosts"*/));
const HostFormAsync = lazy(() => import("./Hosts/form/HostForm" /*webpackChunkName: "Hosts"*/));

// isHost //
const HostReservationsAsync = lazy(() => import("../Host/HostReservations" /*webpackChunkName: "HostReservations"*/));
const HostLocationsAsync = lazy(() => import("../Host/HostLocations" /*webpackChunkName: "HostLocations"*/));
const HostUsersAsync = lazy(() => import("../Host/HostUsers" /*webpackChunkName: "HostUsers"*/));
const HostUserFormAsync = lazy(() => import("../Host/HostUsers/form/HostUserForm" /*webpackChunkName: "HostUsers"*/));

export const AdminRoutes = () => {
  const { isAdmin } = useUserRole();

  return (
    <>
      <AppNavBar />
      <Container>
        <Drawer />
        {isAdmin ? (
          <Suspense fallback={<Loader />}>
            <Switch>
              <PrivatRoute component={ReservationsAsync} path={ADMIN_ROUTES.RESERVATIONS.path} exact />
              <PrivatRoute component={ReservationDetailsAsync} path={ADMIN_ROUTES.RESERVATIONS.details} />

              <PrivatRoute component={LocationsAsync} path={ADMIN_ROUTES.LOCATIONS.path} exact />
              <PrivatRoute component={LocationFormAsync} path={ADMIN_ROUTES.LOCATIONS.createPath} />
              <PrivatRoute component={LocationFormAsync} path={ADMIN_ROUTES.LOCATIONS.editPath} exact />
              <PrivatRoute component={LocationDetailsAsync} path={ADMIN_ROUTES.LOCATIONS.details} />

              <PrivatRoute component={SubscriptionsAsync} path={ADMIN_ROUTES.SUBSCRIPTIONS.path} exact />
              <PrivatRoute component={SubscriptionFormAsync} path={ADMIN_ROUTES.SUBSCRIPTIONS.createPath} />
              <PrivatRoute component={SubscriptionFormAsync} path={ADMIN_ROUTES.SUBSCRIPTIONS.editPath} exact />
              <PrivatRoute component={SubscriptionDetailsAsync} path={ADMIN_ROUTES.SUBSCRIPTIONS.details} />

              <PrivatRoute component={LockersAsync} path={ADMIN_ROUTES.LOCKERS.path} exact />
              <PrivatRoute component={LockerDetailsAsync} path={ADMIN_ROUTES.LOCKERS.details} exact />

              <PrivatRoute component={UsersAsync} path={ADMIN_ROUTES.USERS.path} exact />
              <PrivatRoute component={UserFormAsync} path={ADMIN_ROUTES.USERS.createPath} />
              <PrivatRoute component={UserFormAsync} path={ADMIN_ROUTES.USERS.editPath} />

              <PrivatRoute component={HostsAsync} path={ADMIN_ROUTES.HOSTS.path} exact />
              <PrivatRoute component={HostFormAsync} path={ADMIN_ROUTES.HOSTS.createPath} />
              <PrivatRoute component={HostFormAsync} path={ADMIN_ROUTES.HOSTS.editPath} />

              <Settings />
            </Switch>
          </Suspense>
        ) : (
          <Suspense fallback={<Loader />}>
            <Switch>
              <PrivatRoute component={HostReservationsAsync} path={HOST_ROUTES.RESERVATIONS.path} exact />

              <PrivatRoute component={HostLocationsAsync} path={HOST_ROUTES.LOCATIONS.path} exact />

              <PrivatRoute component={HostUsersAsync} path={HOST_ROUTES.USERS.path} exact />
              <PrivatRoute component={HostUserFormAsync} path={HOST_ROUTES.USERS.createPath} />
              <PrivatRoute component={HostUserFormAsync} path={HOST_ROUTES.USERS.editPath} />
              
              <Redirect to={HOST_ROUTES.RESERVATIONS.path} />
              {/* <PrivatRoute component={HostReservationDetailsAsync} path={HOST_ROUTES.RESERVATIONS.details} /> */}
            </Switch>
          </Suspense>
        )}
      </Container>
    </>
  );
};
