import { useTranslation } from "react-i18next";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import cn from "classnames";

const useStyles = makeStyles(() => ({
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "3px",
  },

  cardRoot: {
    width: "100%",
    marginBottom: "20px",
  },
  cardArFooter: {
    flexDirection: "row-reverse",
  },
  cardArStyle: {
    direction: "rtl",
  },
  header: {
    color: "#e6c727",
  },
  distance: {
    paddingTop: "7px",
    fontSize: "14px",
    color: "#e6c727",
  },
  cardLocation: {
    marginBottom: "20px",
    color: "#631f9d",
    fontSize: "12px",
  },
  district: {
    display: "inline-block",
    marginLeft: "5px",
  },
  freePlan: {
    color: "green",
  },
}));

export const CardTemplate = ({ title, location, betterSubscription = false, city, district, distance }) => {
  const classes = useStyles();
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Card className={cn(classes.cardRoot, language === "ar" && classes.cardArStyle)}>
      <CardContent className={classes.cardContent} style={{ paddingBottom: "0" }}>
        <div className={classes.cardFooter}>
          <Typography className={classes.header} variant="h6" component="h2" color="textPrimary">
            {title}
          </Typography>
          {distance ? (
            <Typography className={classes.distance} variant="h6" component="h6" color="textPrimary">
              +{distance}km
            </Typography>
          ) : (
            <CircularProgress size="20px" disableShrink />
          )}
        </div>
        <span className={classes.cardLocation}>{language === "en" ? location.nameEnglish : location.nameArabic}</span>
        <div className={classes.cardFooter}>
          <Typography className={classes.h2} color="textSecondary" gutterBottom>
            {city},<span className={classes.district}>{district}</span>
          </Typography>
          <Typography className={`${classes.h2} ${classes.freePlan}`} color="textSecondary" gutterBottom>
            {betterSubscription ? `${betterSubscription} Free` : null}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};
