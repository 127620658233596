import { useState } from "react";
import { useAsync } from "react-use";

import { getLogsById } from "../../../services/admin";

export const useLogsModal = ({ isFetch, id }) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useAsync(async () => {
    if (!isFetch) return;
    setLoading(true);
    try {
      const {data} = await getLogsById(id);
      setLogs(data);
    } catch (error) {}
    setLoading(false);
  }, [isFetch]);

  return { logs, loading };
};


