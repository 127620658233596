import React, { Suspense, lazy, useState } from "react";
import { Switch } from "react-router-dom";
import { useAsync } from "react-use";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import { PublicRoute, USER_PAGE_ROUTES } from "../../../routes";
import { Loader } from "../../../reusableComponents";
import estilamLogo from "../../../assets/estilamLogo.png";
import s from "./UserPage.module.scss";

const UserPageCabinetAsync = lazy(() => import("./Cabinet"));
const UserPageInfoDetailsAsync = lazy(() => import("./Details"));

const useStyles = makeStyles(() => ({
  tabs: {
    marginTop: "10px",
    marginBottom: "20px",
    backgroundColor: "#f0f0f0",
    minHeight: "18px",
    width: "100%",
    borderRadius: "7px",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .Mui-selected": {
      backgroundColor: "#e6c727",
      borderRadius: "7px",
    },
  },
  tab: {
    minHeight: "18px",
    maxWidth: "50%",
    width: "50%",
  },
}));

export const UserPageRoutes = () => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(0);

  useAsync(async () => {
    if (i18n.language === "en") {
      setValue(0);
    } else {
      setValue(1);
    }
  }, []);

  const handleChange = (_, newValue) => {
    if (!!newValue) {
      i18n.changeLanguage("ar");
    } else {
      i18n.changeLanguage("en");
    }
    setValue(newValue);
  };

  return (
    
    <div className={s.container}>
      <img src={estilamLogo} width="120" alt="Logo" />

      <Tabs value={value} onChange={handleChange} className={classes.tabs}>
        <Tab label="English" id="simple-tab-0" aria-controls="simple-tabpanel-0" className={classes.tab} />
        <Tab label="عرب" id="simple-tab-1" aria-controls="simple-tabpanel-1" className={classes.tab} />
      </Tabs>

      <Suspense fallback={<Loader />}>
        <Switch>
          <PublicRoute component={UserPageCabinetAsync} path={USER_PAGE_ROUTES.CABINET.main} exact />
          <PublicRoute component={UserPageInfoDetailsAsync} path={USER_PAGE_ROUTES.CABINET.details} exact />
        </Switch>
      </Suspense>
      <div className={s.btn}>
        <HeadsetMicIcon />
        {t("userPageCabinet").callBtn}
      </div>
      </div>
    
  );
};
