import { useState } from "react";
import { useHistory } from "react-router-dom";
import { CLIENT_ROUTES_USER_LINKS } from "../../../../../routes";
import { useAuth } from "../../../../../context";
import { sendClientOTP, logInClient } from "../../../../../services";

export const useVerifyForm = () => {
  const { login, otp } = useAuth();
  const { push } = useHistory();
  const [error, setError] = useState(false);

  const verify = async (credentials) => {
    try {
      const { data } = await sendClientOTP(credentials);
      login(data);
      otp(false);
      push({ pathname: CLIENT_ROUTES_USER_LINKS.USER_LOGGED_IN.path });
    } catch (error) {
      setError(true);
    }
  };
  const sendOTPPin = async (phone) => {
    try {
      await logInClient(phone);
      otp(true);
    } catch (error) {
      setError(true);
    }
  };
  return { error, verify, sendOTPPin };
};
