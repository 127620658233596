import { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Button } from "@material-ui/core";
import { postCompleteReservation } from "../../../services/client/reservations";
import { showErrorMessage } from "../../../reusableComponents";
import s from "./CompleteReservationModal.module.scss";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #e6c727",
    boxShadow: theme.shadows[5],
    padding: "30px",
    textAlign: "center",
  },
}));

export const CompleteReservationModal = ({ isOpen, onClose, onCompleteReservation, reservationId }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [modalStyle] = useState(getModalStyle);

    const handleClick = async () => {
      try {
        const { data } = await postCompleteReservation(reservationId);
        onCompleteReservation(data);
        onClose();
      } catch (error) {
        showErrorMessage(error.response.data.message);
      }
    };
  
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <h3 className={s.title}> {t("completeReservationModal").questionComplete}</h3>
        <div className={s.btns}>
          <Button className={s.btn} variant="outlined" fullWidth type="button" onClick={onClose}>
            {t("completeReservationModal").cancelBtn}
          </Button>
          <Button className={s.btn} color="primary" variant="contained" fullWidth type="button" onClick={handleClick}>
            {t("completeReservationModal").yesBtn}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
