import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Route, Redirect, Switch } from "react-router-dom";
import { AdminRoutes } from "./Admin";
import { ClientRoutes } from "./Client";

import { SignInForm, SignUpForm, ForgotPasswordForm } from "./Admin/AuthForms";
import { LoginForm, RegisterForm, VerifyForm } from "./Client/AuthForms";
import { Loader } from "../../reusableComponents";
import { UserPageRoutes } from "../Routes/UserPage";
import { useUserRole } from "../../hooks/useUserRole";
import { useAuth } from "../../context";
import {
  AUTH_ADMIN_ROUTES,
  AUTH_HOST_ROUTES,
  AUTH_CLIENT_ROUTES,
  SIDEBAR_ADMIN_ROUTES,CLIENT_ROUTES,
  USER_PAGE_ROUTES,
} from "../../routes";
import s from './Routes.module.scss';

const Routes = () => {
  const { i18n: {language} } = useTranslation();
  const { isAdmin, isClient, isHost } = useUserRole();
  const { isAuth, isWaitingForPin, isProcessing } = useAuth();

  const redirectTo = () => {
    if (isAuth) {
      return isAdmin || isHost ? SIDEBAR_ADMIN_ROUTES.RESERVATIONS.path : CLIENT_ROUTES.USER.loggedIn;
    } else {
      const role = sessionStorage.getItem('role');
      const redirectByRole = `/${role?.split('"').join('').toLowerCase()}/login`
      const redirectLink = !!role ? redirectByRole : AUTH_CLIENT_ROUTES.CLIENT_LOGIN.path
      return redirectLink
    }
  };
  document.body.style.fontFamily = language === 'ar' ? 'Kufyan Arabic, serif' : 'Farro, sans-serif'

  return (
    !isProcessing && (
      <div className={language === "ar" ? s.styleAr: s.styleEng}>
        <Suspense fallback={<Loader />}>
          <Switch>
            {/* ADMIN */}
            {!isAuth && <Route {...AUTH_ADMIN_ROUTES.ADMIN_LOGIN} render={() => <SignInForm />} />}
            {!isAuth && <Route {...AUTH_ADMIN_ROUTES.ADMIN_VERIFY} render={() => <SignInForm />} />}
            {!isAuth && <Route {...AUTH_ADMIN_ROUTES.ADMIN_FORGOT_PASSWORD} render={() => <ForgotPasswordForm />} />}
            {!isAuth && <Route {...AUTH_ADMIN_ROUTES.ADMIN_SIGNUP} render={() => <SignUpForm />} />}
            {isAdmin && <AdminRoutes />}
            {/* HOST */}
            {!isAuth && <Route {...AUTH_HOST_ROUTES.HOST_LOGIN} render={() => <SignInForm />} />}
            {!isAuth && <Route {...AUTH_HOST_ROUTES.HOST_VERIFY} render={() => <SignInForm />} />}
            {!isAuth && <Route {...AUTH_HOST_ROUTES.HOST_FORGOT_PASSWORD} render={() => <ForgotPasswordForm />} />}
            {isHost && <AdminRoutes />}
            {/* CLIENT */}
            {!isAuth && <Route {...AUTH_CLIENT_ROUTES.CLIENT_LOGIN} render={() => <LoginForm />} />}
            {!isAuth && <Route {...AUTH_CLIENT_ROUTES.CLIENT_VERIFY_EMAIL} render={() => <LoginForm />} />}
            {!isAuth && <Route {...AUTH_CLIENT_ROUTES.CLIENT_SIGNUP} render={() => <RegisterForm />} />}
            {!isAuth && isWaitingForPin && (
              <Route {...AUTH_CLIENT_ROUTES.CLIENT_VERIFY} render={() => <VerifyForm />} />
            )}
            {isClient && <ClientRoutes />}
            {/* User Page */}
            <Route path={USER_PAGE_ROUTES.USER.logged} render={() => <UserPageRoutes />} exact />
            <Route path={USER_PAGE_ROUTES.CABINET.details} render={() => <UserPageRoutes />} />
            <Redirect to={redirectTo()} />
          </Switch>
        </Suspense>
      </div>
    )
  );
};

export default Routes;
