import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { TextField, FormGroup, FormControlLabel, Button, Input, InputAdornment, IconButton, FormHelperText } from "@material-ui/core/";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { AUTH_ADMIN_ROUTES, AUTH_HOST_ROUTES } from "../../../../../routes";
import { useSignInForm } from "./useSignIn";
import { BtnLanguage, NavBarByRole } from "../../../../../reusableComponents";
import estilamLogo from "../../../../../assets/estilamLogo.png";
import s from "../authForm.module.scss";


export const SignInForm = (props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { signIn } = useSignInForm();

  
  const f = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t("validationForm").email).required(t("validationForm").required),
      password: Yup.string().min(6, t("validationForm").password).required(t("validationForm").required),
    }),
    onSubmit: (values) => {signIn(values)},
  });

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    f.values.password = event.target.value;
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={s.container}>
      <div>
      <NavBarByRole/>
      <div className={s.formContainer}>
        <div className={s.logoBox}>
          <div className={s.logo}>
            <img src={estilamLogo} width="120" alt="Logo" />
          </div>
          <BtnLanguage />
        </div>
        <form onSubmit={f.handleSubmit} style={{ width: "100%" }}>
          <FormGroup>
            <FormControlLabel
              label={t("authForm").signIn.emailPlaceholder}
              labelPlacement="top"
              style={{ marginBottom: 10 }}
              control={
                <TextField
                  error={f.errors?.email && f.touched.email ? true : false}
                  id="email"
                  name="email"
                  value={f.values.email}
                  style={{ width: 200 }}
                  onChange={f.handleChange}
                  helperText={f.errors?.email && f.touched.email ? f.errors?.email : " "}
                />
              }
            />

            <div>
              <FormControlLabel
                label={t("authForm").signIn.passwordPlaceholder}
                labelPlacement="top"
                control={
                  <Input
                    error={f.errors?.password && f.touched.password ? true : false}
                    id="standard-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    style={{ width: 200 }}
                    onChange={handleChange("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                }
              ></FormControlLabel>
              <FormHelperText error style={{ width: 200, margin: "0px auto 30px" }}>
                {f.errors?.password && f.touched.password ? f.errors?.password : " "}
              </FormHelperText>
            </div>
          </FormGroup>
          <Link
            to={
              pathname.includes("admin")
                ? AUTH_ADMIN_ROUTES.ADMIN_FORGOT_PASSWORD.path
                : AUTH_HOST_ROUTES.HOST_FORGOT_PASSWORD.path
            }
          >
            {t("authForm").signIn.forgotPasswordLinkLabel}
          </Link>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            {/* <Link to={AUTH_ROUTES.ADMIN_SIGNUP.path}>
              <Button color="secondary" variant="outlined" type="button">
                {t("authForm").signIn.signupBtn}
              </Button>
            </Link> */}

            <Button color="primary" variant="contained" type="submit">
              {t("authForm").signIn.signinBtn}
            </Button>
          </div>
        </form>
      </div>
      </div>
    </div>
  );
};
