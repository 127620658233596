import { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Button, ButtonGroup } from "@material-ui/core";
import { useLockerLocation } from "./useLockerLocation";

import { LockersList } from "./LockersList/LockersList.js";
import { Loader } from "../../../reusableComponents";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #e6c727",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const LocationLockerModal = ({ isOpen, onSetLocker, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [modalStyle] = useState(getModalStyle);

  const { lockers, loading } = useLockerLocation({ isFetch: isOpen });
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <h4 style={{ textAlign: "center" }}>{t("locationFormRoute").modal.title}</h4>
        <Loader show={loading} />
        {!loading && <LockersList lockers={lockers} onSetLocker={onSetLocker} />}
        <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group" fullWidth>
          <Button color="secondary" variant="outlined" fullWidth type="button" onClick={onClose}>
            {t("locationFormRoute").modal.cancelBtn}
          </Button>
        </ButtonGroup>
      </div>
    </Modal>
  );
};
