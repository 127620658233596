import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Button } from "@material-ui/core";
import { updateReservationStatus } from "../../../services";
import { showErrorMessage } from "../../../reusableComponents";
import s from "./CancelReservationModal.module.scss";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #e6c727",
    boxShadow: theme.shadows[5],
    padding: "30px",
    textAlign: "center",
  },
  btn: {
    margin: "10px",
  },
}));

export const CancelReservationModal = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id } = useParams();

  const [modalStyle] = useState(getModalStyle);

  const handleClick = async () => {
    try {
      const {data} = await updateReservationStatus(id)
      onSubmit(data);
      onClose();
    } catch (error) {
      showErrorMessage(error.response.data.message);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
    >
      <div style={modalStyle} className={classes.paper}>
        <h3 className={s.title}> {t("cancelReservationModal").questionOpen}</h3>
        <div className={s.btns}>
          <Button className={classes.btn} variant="outlined" fullWidth type="button" onClick={onClose}>
            {t("cancelReservationModal").cancelBtn}
          </Button>
          <Button
            className={classes.btn}
            color="primary"
            variant="contained"
            fullWidth
            type="button"
            onClick={handleClick}
          >
            {t("cancelReservationModal").yesBtn}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
