import { useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/core/styles";
import { Modal, Button } from "@material-ui/core";
import { postFreeCabinet } from "../../../services/client/reservations";
import { showErrorMessage } from "../../../reusableComponents";
import s from "./ErrorPaymentModal.module.scss";


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #e6c727",
    boxShadow: theme.shadows[5],
    padding: "30px",
    textAlign: "center",
  },
}));

export const ErrorPaymentModal = ({ isOpen, onClose, message = null}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <h3 className={s.title}>{message === 'Payment gateway error' ? "Payment service is temporary unavailable. Please try again later or contact to support." : message}</h3>
        <div className={s.btns}>
          {/* <Button className={s.btn} variant="outlined" fullWidth type="button" onClick={()=> console.log('click')}>
            {t("errorPaymentModal").callBtn}
          </Button> */}
          <Button className={s.btn} color="primary" variant="contained" fullWidth type="button" onClick={onClose}>
            {t("errorPaymentModal").tryAgainBtn}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
