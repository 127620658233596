import { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Button } from "@material-ui/core";
import s from "./OpenCabinetModal.module.scss";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #e6c727",
    boxShadow: theme.shadows[5],
    padding: "30px",
    textAlign: "center",
  },
}));

export const OpenCabinetModal = ({ isOpen, onClose, onOpenCabinet }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [modalStyle] = useState(getModalStyle);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <h3 className={s.title}> {t("openCabinetModal").questionOpen}</h3>
        <div className={s.btns}>
          <Button className={s.btn} variant="outlined" fullWidth type="button" onClick={onClose}>
            {t("openCabinetModal").cancelBtn}
          </Button>
          <Button
            className={s.btn}
            color="primary"
            variant="contained"
            fullWidth
            type="button"
            onClick={() => onOpenCabinet(true)}
          >
            {t("openCabinetModal").yesBtn}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
