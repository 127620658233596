import i18n from "../localization/i18n";

// ADMIN //
export const AUTH_ADMIN_ROUTES = {
  ADMIN_LOGIN: {
    path: "/admin/login",
    exact: true,
  },
  ADMIN_SIGNUP: {
    path: "/admin/signup",
    exact: true,
  },
  ADMIN_FORGOT_PASSWORD: {
    path: "/admin/forgot-password",
    exact: true,
  },
  ADMIN_VERIFY: {
    path: "/admin/login/token/:token",
    exact: true,
  },
};

export const ADMIN_ROUTES = {
  RESERVATIONS: {
    path: "/reservations",
    createPath: "/reservations/create",
    editPath: "/reservations/:id",
    details: "/reservations/:id/details",
    restricted: true,
  },
  LOCATIONS: {
    path: "/locations",
    createPath: "/locations/create",
    editPath: "/locations/:id",
    details: "/locations/:id/details",
    restricted: true,
  },
  SUBSCRIPTIONS: {
    path: "/subscriptions",
    createPath: "/subscriptions/create",
    editPath: "/subscriptions/:id",
    details: "/subscriptions/:id/details",
    restricted: true,
  },
  LOCKERS: {
    path: "/lockers",
    details: "/lockers/:id/details",
  },
  USERS: {
    path: "/users",
    createPath: "/users/create",
    editPath: "/users/:id",
    restricted: true,
  },
  HOSTS: {
    path: "/hosts",
    createPath: "/hosts/create",
    editPath: "/hosts/:id",
    restricted: true,
  },
  SETTINGS: {
    path: "/settings",
    billings: "/settings/billings",
    conditions: "/settings/conditions",
    restricted: true,
  },
};

export const SIDEBAR_ADMIN_ROUTES = {
  RESERVATIONS: {
    path: ADMIN_ROUTES.RESERVATIONS.path,
    icon: "properties",
    title: i18n.t("sidebar").reservations,
  },
  LOCATIONS: {
    path: ADMIN_ROUTES.LOCATIONS.path,
    icon: "map-marker",
    title: i18n.t("sidebar").locations,
  },
  SUBSCRIPTIONS: {
    path: ADMIN_ROUTES.SUBSCRIPTIONS.path,
    icon: "feed-subscribed",
    title: i18n.t("sidebar").subscriptions,
  },
  LOCKERS: {
    path: ADMIN_ROUTES.LOCKERS.path,
    icon: "box",
    title: i18n.t("sidebar").lockers
  },
  USERS: {
    path: ADMIN_ROUTES.USERS.path,
    icon: "people",
    title: i18n.t("sidebar").users,
  },
  HOSTS: {
    path: ADMIN_ROUTES.HOSTS.path,
    icon: "crown",
    title: i18n.t("sidebar").hosts,
  },
  SETTINGS: {
    path: ADMIN_ROUTES.SETTINGS.billings,
    icon: "cog",
    title: i18n.t("sidebar").settings,
  },
};

// HOST //
export const HOST_ROUTES = {
  HOST: {
    login: "/host/login",
    verifyEmail: "/host/login/token/:token",
    forgotPassword: "/host/forgot-password",
  },
  RESERVATIONS: {
    path: "/host/reservations",
    details: "host/reservations/:id/details",
    restricted: true,
  },
  LOCATIONS: {
    path: "/host/locations",
    details: "/host/locations/:id/details",
    restricted: true,
  },
  USERS: {
    path: "/host/users",
    createPath: "/host/users/create",
    editPath: "/host/users/:id",
    restricted: true,
  },
};
export const AUTH_HOST_ROUTES = {
  HOST_LOGIN: {
    path: HOST_ROUTES.HOST.login,
    exact: true,
  },
  HOST_VERIFY: {
    path: HOST_ROUTES.HOST.verifyEmail,
    exact: true,
  },
  HOST_FORGOT_PASSWORD: {
    path: HOST_ROUTES.HOST.forgotPassword,
    exact: true,
  },
};
export const SIDEBAR_HOST_ROUTES = {
  RESERVATIONS: {
    path: HOST_ROUTES.RESERVATIONS.path,
    icon: "properties",
    title: i18n.t("sidebar").reservations,
  },
  LOCATIONS: {
    path: HOST_ROUTES.LOCATIONS.path,
    icon: "map-marker",
    title: i18n.t("sidebar").locations,
  },
  USERS: {
    path: HOST_ROUTES.USERS.path,
    icon: "people",
    title: i18n.t("sidebar").users,
  },
};

// CLIENT //
export const CLIENT_ROUTES = {
  USER: {
    loggedIn: "/client",
    login: "/client/login",
    verify: "/client/login/verify",
    verifyEmail: "/client/login/token/:token",
    signUp: "/client/signup",
  },
  CABINET: {
    bookNewCabinet: "/cabinet",
    search: "/cabinet/search",
    bookScan: "/cabinet/scanner",
    selectedLocation: "/cabinet/:id",
  },
  RESERVATIONS: {
    reviews: "/reservations",
    createReservations: "/cabinet/:lockerId/reservations",
    details: "/reservations/:id",
    info: "/reservations/:id/info",
    paymentUrl: "/reservations/:id/payment",
    invoice: "/reservations/:id/invoice",
    shareKey: "/reservations/:id/share-key",
    logs: "/reservations/:id/logs",
    errorPayment: "/reservations/:id/error-payment",
  },
};

export const AUTH_CLIENT_ROUTES = {
  CLIENT_LOGIN: {
    path: CLIENT_ROUTES.USER.login,
    exact: true,
  },
  CLIENT_VERIFY: {
    path: CLIENT_ROUTES.USER.verify,
    exact: true,
  },
  CLIENT_SIGNUP: {
    path: CLIENT_ROUTES.USER.signUp,
    exact: true,
  },
  CLIENT_VERIFY_EMAIL: {
    path: CLIENT_ROUTES.USER.verifyEmail,
    exact: true,
  },
};
export const CLIENT_ROUTES_USER_LINKS = {
  USER_LOGGED_IN: {
    path: CLIENT_ROUTES.USER.loggedIn,
    title: "Logged in",
  },
  USER_BOOK_NEW_CABINET: {
    path: CLIENT_ROUTES.CABINET.bookNewCabinet,
    title: i18n.t("clientLogged").cabinet,
  },
};

export const CLIENT_ROUTES_RESERVATIONS_LINKS = {
  RESERVATIONS: {
    path: CLIENT_ROUTES.RESERVATIONS.reviews,
    title: i18n.t("clientLogged").reservations,
  },
};

export const USER_PAGE_ROUTES = {
  USER: {
    logged: "/:idReservation/ownerKey/:ownersPhoneNumber",
  },
  CABINET: {
    main: "/:idReservation/ownerKey/:ownersPhoneNumber",
    details: "/:idReservation/ownerKey/:ownersPhoneNumber/details",
  },
};
