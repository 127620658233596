import { useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";

import { Modal, TextField, Button, ButtonGroup } from "@material-ui/core";
import { showErrorMessage } from "../../../reusableComponents";
import { postShareKey } from "../../../services/client/reservations";
import s from "./ShareKeyModal.module.scss";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #e6c727",
    boxShadow: theme.shadows[5],
    padding: "30px",
    textAlign: "center",
  },
}));

export const ShareKeyModal = ({ isOpen, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id } = useParams();

  const [modalStyle] = useState(getModalStyle);

  const f = useFormik({
    initialValues: {
      phoneNumber: "",
      reservationId: id,
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .min(12, t("validationForm").phoneMin)
        .max(13, t("validationForm").phoneMax)
        .required("Required field")
        .matches(/^\+\d*$/, {
          message: t("validationForm").phoneValidation,
        }),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const { data } = await postShareKey(values);
        await onClose();
        await onSubmit(data);
        resetForm({});
      } catch (error) {
        onClose();
        showErrorMessage(error.response.data.message);
      }
    },
  });
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <h2 className={s.title}> {t("reservationShareKey").modal.title}</h2>
        <form onSubmit={f.handleSubmit}>
          <div className={s.form} style={{ display: "flex" }}>
            <TextField
              error={f.errors?.phoneNumber && f.touched.phoneNumber ? true : false}
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              label={t("reservationShareKey").modal.phone}
              value={f.values.phoneNumber}
              onChange={f.handleChange}
              className={s.input}
              helperText={f.errors?.phoneNumber && f.touched.phoneNumber ? f.errors?.phoneNumber : " "}
            />
          </div>
          <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
            <Button variant="outlined" fullWidth type="button" onClick={onClose}>
              {t("reservationShareKey").modal.cancelBtn}
            </Button>
            <Button color="primary" variant="contained" fullWidth type="submit">
              {t("reservationShareKey").modal.submitBtn}
            </Button>
          </ButtonGroup>
        </form>
      </div>
    </Modal>
  );
};
