import axios from "axios";

export const getLocations = async ({ page = 1, pageSize = 100, status = "Active" }) => {
  const params = {
    query: { $and: [{ status }] },
    sort: "-_id",
    page,
    pageSize,
  };
  const response = await axios.get("/locations", { params });

  const hasMore = response.data.page < response.data.totalPages;
  const data = { ...response.data, hasMore };
  return data;
};

export const postLocation = async (data) => {
  const response = await axios.post("/locations", data);
  return response;
};

export const updateLocationStatus = async (id, data) => {
  const response = await axios.put(`/location/${id}/status`, data);
  return response;
};

export const getLocationById = async (id) => {
  const response = await axios.get(`/locations/${id}`);
  return response;
};

export const updateLocationById = async (id, data) => {
  const response = await axios.put(`/locations/${id}`, data);
  return response;
};

export const postImgsLocation = async (id, data) => {
  const response = await axios.post(`/locations/${id}/image`, data);
  return response;
};

export const deleteImgLocation = async (id, data) => {
  const response = await axios.delete(`/locations/${id}/image`, { data });
  return response;
};

export const getShuttleLockers = async () => {
  const response = await axios.get("/lockers");
  return response.data;
};
