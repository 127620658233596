export const ar = {
  translation: {
    navBarByRole: {
      client: "دخول كعميل",
      admin: "دخول كمشرف",
      host: "دخول كمستضيف",
    },
    authForm: {
      forgotPassword: {
        email: "البريد الالكتروني",
        signinBtn: "تسجيل الدخول",
        restoreBtn: "إعادة الضبط",
      },
      signIn: {
        emailPlaceholder: "البريد الالكتروني",
        passwordPlaceholder: "الرمز السري",
        forgotPasswordLinkLabel: "نسيت الرقم السري",
        signupBtn: "تسجيل جديد",
        signinBtn: "تسجيل الدخول",
      },
      signUp: {
        firstNamePlaceholder: "اسمك الأول",
        lastNamePlaceholder: "العائلة الكريمة",
        emailPlaceholder: "البريد الالكتروني",
        phonePlaceholder: "رقم الجوال",
        passwordPlaceholder: "الرمز السري",
        confirmPasswordPlaceholder: "تأكيد رقم الجوال",
        signupBtn: "تسجيل جديد",
        signinBtn: "تسجيل الدخول",
      },
      changePassword: {
        old: "الرمز السري القديم",
        new: "الرمز السري الجديد",
        confirm: "تأكيد الرمز السري الجديد",
        cancelBtn: "الغاء",
        changeBtn: "تغيير",
      },
      changeProfileLogo: {
        cancelBtn: "الغاء",
        updateBtn: "تحديث",
      },
      logInClient: {
        title: "تسجيل الدخول",
        logInBtn: "تسجيل الدخول",
        code: "حدد الرمز",
        phonePlaceholder: "966xxxxxxxxx+ جوال",
        signUpLinkLabel: "ما عندك حساب؟",
        signUpBtn: "تسجيل جديد",
      },
      signUpClient: {
        title: "إنشاء حساب",
        firstNamePlaceholder: "اسمك الأول",
        lastNamePlaceholder: "العائلة الكريمة",
        emailPlaceholder: "البريد الالكتروني",
        phonePlaceholder: "966xxxxxxxxx+ جوال",
        signUpBtn: "تسجيل جديد",
        errorMessage: "بعض المعلومات موجودة مسبقا",
      },
      verifyForm: {
        title: "التحقق",
        getSMS: "بيوصل لك رسالة فيها رمز",
        pin: "رقم",
        verifyBtn: "التحقق",
        getSMSAgain: "ارسال الرمز من جديد",
      },
    },
    usersRoute: {
      title: "المستخدمين",
      newUser: "مستخدم جديد",
    },
    userFormRoute: {
      titleCreate: "إنشاء مستخدم",
      titleEdit: "تعديل مستخدم",
      firstNamePlaceholder: "اسمك الأول",
      lastNamePlaceholder: "العائلة الكريمة",
      emailPlaceholder: "البريد الالكتروني",
      phonePlaceholder: "رقم الجوال",
      hostPlaceholder: "مضيف",
      rolePlaceholder: "مهام",
      cancelBtn: "الغاء",
      submitBtn: "انشاء",
    },
    subscriptionsRoute: {
      title: "الإشتراكات",
      newPlan: "خطة جديدة",
    },
    subscriptionFormRoute: {
      titleCreate: "إنشاء خطة",
      titleEdit: "تعديل خطة",
      visibleName: "الاسم الظاهر",
      titleSizes: "احجام الخزنة",
      sizeS: "صغير",
      sizeM: "وسط",
      sizeL: "كبير",
      titlePriceS: "سعر الخزنة الصغيرة",
      titlePriceM: "سعر الخزنة الوسط",
      titlePriceL: "سعر الخزنة الكبيرة",
      titleDuration: "المدة",
      selectEffectsPlaceholder: "يرجى تحديد ما يحدث عند تأخر الحجز",
      selectEffectsOptions: {
        none: "لا أحد",
        release: "حرر الخزانة",
        fine: "يدفع العميل الرسوم المتأخرة",
      },
      titleFinePerMinute: "الرسوم المتأخرة (currency / دقيقة)",
      key: "مشاركة مفتاح الخزنة",
      titleLocations: "تفضيلات المواقع",
      cancelBtn: "الغاء",
      submitBtn: "انشاء",
    },
    subscriptionDetailsRoute: {
      title: "تفاصيل الخطة",
      plan: "الخطة",
      titlePlan: "اسم الخطة",
      titleSizes: "احجام الخزنة",
      titleS: "سعر الخزنة الصغيرة",
      titleM: "سعر الخزنة الوسط",
      titleL: "سعر الخزنة الكبيرة",
      titleLocations: "خطة تفضيلات الأماكن",
      locations: "الأماكن",
      finePerMinute: "رسوم التأخير / دقيقة",
      duration: "المدة",
      status: "حالة الخطة",
      key: "مشاركة مفتاح الخزنة",
      editBtn: "تعديل",
      deactivateBtn: "تعطيل",
      activateBtn: "تنشيط",
      changeBtn: "تغيير تفضيلات المواقع",
    },
    lockersRoute: {
      title: "الخزائن",
    },
    lockerDetailsRoute: {
      backBtnText: "رجوع",
      lockerInfo: {
        locker: "خزنة",
        location: "موقع",
        utilization: "الاستخدام",
        sizes: "الاحجام",
      },
      cabinetsGrid: {
        title: "الكبائن",
        cabinetInfo: {
          status: "الحالة",
          state: "المنطقة",
          number: "رقم",
          size: "حجم",
          width: "العرض",
          height: "الطول",
          row: "الصف",
          column: "العمود",
          hideBtnsText: "إخفاء معلومات الكبينة",
          openCabinetBtnText: "افتح الخزنة",
        },
        reservationInfo: {
          number: "رقم",
          phone: "رقم الجوال",
          duration: "المدة",
          price: "سعر",
          amount: "السعر",
          date: "الوقت واليوم",
          status: "الحالة",
        },
      },
      openCabinet: { success: "تم فتح الكبينة بنجاح" },
    },
    reservationsRoute: {
      title: "الحجوزات",
      download: "CSV تحميل",
      logsModal: {
        title: "نشاط الخزنة",
        cancelBtn: "الغاء",
      },
    },
    reservationDetailsRoute: {
      title: "تفاصيل الحجز",
      reservationId: "الحجز",
      name: "الاسم",
      phone: "رقم الجوال",
      location: "المكان",
      locker: "رقم الخزنة",
      cabinet: "رقم الخزنة",
      size: "حجم الخزنة",
      duration: "المدة",
      amount: "السعر",
      price: "سعر",
      date: "الوقت واليوم",
      status: "الحالة",
      key: "مشاركة مفتاح الخزنة",
      completeBtn: "مكتمل",
      disactiveBtn: "غير نشط",
      cabinetBtn: "افتح الخزنة",
      resendBtn: "إعادة ارسال المفتاح",
      revokeBtn: "إيقاف مشاركة المفتاح",
    },
    locationsRoute: {
      title: "الأماكن",
      newLocation: "موقع جديد",
    },
    locationFormRoute: {
      titleCreate: "إنشاء موقع",
      titleEdit: "تعديل موقع",
      host: "اسم المستضيف",
      nameAr: "اسم الموقع بالعربي",
      nameEn: "اسم الموقع بالانجليزي",
      address: "عنوان الموقع",
      url: "رابط الموقع",
      descriptionEnglish: "وصف مكان الخزنة بالانجليزي",
      descriptionArabic: "وصف مكان الخزنة بالعربي",
      photo: "صورة مكان الخزنة",
      largeImageError: "حجم الصورة أعلى من 15 ميقابايت",
      setLockerBtn: "اختر خزنة",
      browseBtn: "بحث",
      cancelBtn: "الغاء",
      submitBtn: "انشاء",
      modal: {
        title: "قائمة الخزنات",
        cancelBtn: "الغاء",
      },
    },
    locationDetailsRoute: {
      title: "تفاصيل الموقع",
      location: "المكان",
      name: "اسم الموقع",
      address: "العنوان",
      map: "الخريطة",
      status: "حالة الموقع",
      locker: "رقم الخزنة",
      size: "الاحجام",
      utilization: "الاستخدام",
      lockerStatus: "حالة الخزنة",
      info: "معلومات الخزنة",
      description: "تفاصيل موقع الخزنة",
      photo: "صورة مكان الخزنة",
      editBtn: "تعديل",
      deleteBtn: "مسح",
      uploadBtn: "تحميل",
      deactivateBtn: "تعطيل",
      activateBtn: "تنشيط",
    },
    hostsRoute: {
      title: "المستضيفين",
      newHost: "مستضيف جديد",
    },
    hostFormRoute: {
      titleCreate: "إنشاء مستضيف",
      titleEdit: "تعديل مستضيف",
      hostNamePlaceholder: "اسم المستضيف",
      cancelBtn: "الغاء",
      submitBtn: "انشاء",
    },
    settingsRoute: {
      title: "إعدادات",
      billings: {
        title: "الفواتير",
        companyName: "اسم الشركة",
        commercialRecord: "رقم السجل التجاري",
        currency: "العملة",
        vat: "الضريبة",
        cancelBtn: "الغاء",
        submitBtn: "انشاء",
      },
      conditions: {
        title: "الشروط والاحكام",
        browseBtn: "بحث",
        placeholder: "PDF ملف",
        description: "وثيقة شروط واحكام تقديم الخدمات",
      },
    },
    clientLogged: {
      welcome: "مرحبًا بك عزيزي العميل",
      cabinet: "حجز خزنة جديدة",
      reservations: "حجوزاتي",
    },
    clientCabinet: {
      search: "البحث عن خزنة",
      scan: "QR مسح رمز",
    },
    clientListLockers: {
      title: "قائمة الخزنات",
      search: "ادخل اسم الخزنة",
      notFound: "لا يوجد",
      filterForm: {
        title: "قم بالاختيار",
        cityPlaceholder: "المدينة",
        districtPlaceholder: "الحي",
        loading: "...جاري التحميل",
        cancelBtn: "الغاء",
        okBtn: "تمام",
      },
    },
    clientScanner: {
      title: "QR توجيه الكاميرا على كود",
      error: "كود الـ QR غير فعَال",
    },
    clientSelectSize: {
      title: "اختيار الحجم",
      notAvaliableCabibets: "لا يوجد كبائن متاحة",
      notFound: "لا يوجد",
    },
    createReservation: {
      title: "معلومات الخزنة",
      cabinetNumber: "رقم الخزنة",
      cabinetSize: "حجم الخزنة",
      lockerNumber: "رقم الخزنة",
      lockerName: "اسم الخزنة",
      chosePlan: "اختيار الخطة",
      selectPlaceholder: "اختيار",
      payBtn: "الدفع",
      location: "المكان",
      agreementText: "بحجز الخزنة، انتم موافقون على",
      agreementLink: "الشروط والاحكام",
      notFound: "لا يوجد",
      invoice: {
        title: "فاتورة الحجز",
        cabinetNumber: "رقم الخزنة",
        duration: "المدة",
        price: "السعر",
        tax: "الضريبة",
        total: "الإجمالي",
      },
      loaders: {
        cabinet: "التحقق من الكبينة",
        reservation: "انشاء الحجز",
        payment: "تفعيل الدفع",
      },
    },
    clientAllReservations: {
      title: "حجوزاتي",
      notFound: "لا يوجد",
      paid: "مدفوع",
      unpaid: "غير مدفوع",
      tabs: {
        current: "حاليا",
        completed: "مكتمل",
        canceled: "ملغى",
      },
    },
    reservationInfo: {
      title: ":معلومات الحجز",
      reservationNumber: "رقم الحجز",
      status: { title: "الحالة", paid: "مدفوع", unpaid: "غير مدفوع" },
      plan: "الخطة",
      cabinetNumber: "رقم الخزنة",
      cabinetSize: "حجم الخزنة",
      lockerNumber: "رقم الخزنة",
      location: "المكان",
      finalDate: "اخر يوم",
      warn: {
        titleWarn: "حجزك قريب من الانتهاء",
        textWarn1: "من فضلك قم بتأكيد أنك استلمت جميع أغراضك من الخزنة ليتم انهاء الحجز بنجاح",
        textWarn2: "يمكنك أيضا تمديد حجزك قبل انتهائه",
        textGrateful: "لقد قمت بتأكيد أنك استملت جميع أغراضك من الخزنة، شكرا لاستخدامك خزن",
        confirmBtn: "تأكيد",
        titleExpired: "حجزك انتهى!",
        textExpired1: "لا يمكنك فتح الكبينة",
        textExpired2: "لتتمكن من فتح الكبينة، من فضلك قم بدفع رسوم التأخير",
        subtitleExpired: "ملاحظة",
        textExpired3: "بعد دفعك لرسوم التأخير، سيتم تفعيل زر فتح الكبينة لمرة واحدة فقط، بعد ذلك سيتم انهاء حجزك",
      },
      openCabinetBtn: "افتح الخزنة",
      shareKeyBtn: "مشاركة مفتاح الخزنة",
      payOverdueFeesBtn: "ادفع رسوم التأخير",
      finePerMinute: "رسوم التأخير / دقيقة",
      endBtn: "إنهاء الحجز",
      poppers: {
        reservationInfo: "معلومات الحجز",
        invoices: "الفواتير",
        keys: "جدول مشاركة المفاتيح",
        logs: "نشاط الخزنة",
        extend: "تمديد",
        cancel: "الغاء",
      },
    },
    reservationInvoice: {
      title: "الفواتير",
      invoiceNumber: "رقم الفاتورة",
      vat: "الضريبة",
      client: "العميل",
      price: "السعر",
      currency: "SAR",
      total: "الإجمالي",
    },
    reservationShareKey: {
      title: "جدول مشاركة المفاتيح",
      shareKeyBtn: "مشاركة المفتاح مع الاخرين",
      error: "للأسفن لايمكن مشاركة المفتاح مع الاخرين",
      modal: {
        title: "معلومات المفتاح الثانوي للمستخدم",
        name: "الاسم",
        phone: "966xxxxxxxxx+ جوال",
        cancelBtn: "الغاء",
        submitBtn: "انشاء",
      },
    },
    reservationErrorPayment: {
      errorText: "خطأ حصل وقت الدفع، الرجاء محاولة مرة أخرى",
    },
    userPageCabinet: {
      title: "مفتاح ذكي",
      hello: { hey: ",اهلين", cabinetOwner: "مالك الخزنة", shared: "تم مشاركة مفتاح الخزنة معك" },
      cabinetInfo: "معلومات الخزنة",
      cabinetOwner: ":مالك الخزنة",
      reservationNumber: ":رقم الحجز",
      location: "موقع:",
      lockerId: ":رقم هوية الخزنة",
      cabinetNumber: ":رقم الخزنة",
      lockerDirection: ":اتجاهات الخزنة",
      lockerDescription: ":وصف الخزنة",
      detailsBtn: "تفاصيل اكثر",
      openCabinetBtn: "افتح الخزنة",
      callBtn: "الاتصال على خدمة العملاء",
      error: {
        revoked: "مالك الخزنة الغى المفتاح الذكي",
        toDoTitle: "ننصحك القيام بالتالي",
        reach: "تواصل مع خدمة العملاء",
        askHim: "اطلب إعادة تنشيط/ارسال المفتاح الذكي من المستأجر الرئيسي للخزنة",
        support: "التواصل مع خدمة العملاء",
        activeKey: "اذا المفتاح الذكي نشط ولا زال عندك مشلكة، الرجاء التواصل مع خدمة العملاء",
      },
    },
    userPageDetails: {
      lockerInfo: "معلومات الخزنة",
      lockerDescription: ":وصف الخزنة",
      lockerPhotosTitle: ":صور الخزنة",
      openCabinetBtn: "افتح الخزنة",
      back: "العودة",
      lockerPhotosNotFound: "لم نجد شيئ",
    },
    openCabinetModal: {
      questionOpen: "نفتح الخزنة؟",
      cancelBtn: "الغاء",
      yesBtn: "نعم",
    },
    freeCabinetModal: {
      questionFree: "هل تأكد أنك ترغب بإنهاء حجزك ولن تستمر في حجزه؟",
      cancelBtn: "الغاء",
      yesBtn: "نعم",
    },
    errorPaymentModal: {
      tryAgainBtn: "حاول مرة أخرى",
      callBtn: "يتصل",
      payment: "خدمة الدفع غير متوفرة مؤقتًا. يرجى المحاولة مرة أخرى لاحقًا أو الاتصال بالدعم",
      location: "الموقع غير نشط",
      cabinetUsed: "الخزانة تستخدم بالفعل",
      cabinetReserved: "الخزانة ليست محجوزة",
    },
    completeReservationModal: {
      questionComplete: "اذا ضغطت نعم، فهذا يعني انك انتهيت من استخدام الخزنة ولن تكون قادرا على فتح الخزنة مرة اخرى.",
      cancelBtn: "الغاء",
      yesBtn: "نعم",
    },
    copyLinkModal: {
      copyBtn: "ينسخ",
      cancelBtn: "الغاء",
    },
    successPaymentModal: {
      title: "مبروك! حجزنا لك الخزنة",
      yesBtn: "نعم",
    },
    cancelReservationModal: {
      questionOpen: "نلغي حجز الخزنة لك؟",
      cancelBtn: "الغاء",
      yesBtn: "نعم",
    },
    header: {
      backBtn: "العودة",
    },
    logs: {
      title: "نشاط الخزنة",
      notFound: "لا يوجد",
      create: "الخزنة محجوزة من",
      statusChanged: "حالة الخزنة تغيرت",
      openedCabinet: "الخزنة فتحها",
      sharedKey: "مشاركة مفتاح الخزنة مع",
      revokeKey: { revoke: "إيقاف مشاركة المفتاح", key: "مفتاح" },
      canceled: "الغاء حجز الخزنة",
      paid: "الحجز مدفوع من",
      extended: "تم تمديد الحجز بواسطة",
      errorPayment: "خطأ حصل وقت الدفع",
      openedCabinetAdmin: "تم فتح الكبينة من قبل المشرف",
      completed: "تم إكمال الحجز يدويًا بواسطة",
    },
    sizes: {
      s: { title: "حجم صغير" },
      m: { title: "حجم متوسط" },
      l: { title: "حجم كبير" },
    },
    address: {
      none: "ولا شي",
      city: {
        Riyadh: "الرياض",
        Dammam: "الدمام",
        Jeddah: "جدة",
        Kharj: "الخبر",
        الرياض: "الرياض",
        الدمام: "الدمام",
        جدة: "جدة",
        الخرج: "الخرج",
      },
      districts: {
        "ابن خلدون": "ابن خلدون",
        احد: "احد",
        الاتصالات: "الاتصالات",
        الاسكان: "الاسكان",
        "الإسكان (الدمام)": "الإسكان (الدمام)",
        "الاسكان (الخبر)": "الاسكان (الخبر)",
        "الامير محمد بن سعود": "الامير محمد بن سعود",
        الأثير: "الأثير",
        الأمانة: "الأمانة",
        العمل: "العمل",
        الأنوار: "الأنوار",
        البادية: "البادية",
        "البحيرة (الدمام)": "البحيرة (الدمام)",
        "البحيرة (الخبر)": "البحيرة (الخبر)",
        البديع: "البديع",
        البساتين: "البساتين",
        الجامعيين: "الجامعيين",
        الجلوية: "الجلوية",
        الجوهرة: "الجوهرة",
        "الجوهرة (الدمام)": "الجوهرة (الدمام)",
        "الجوهرة (الخبر)": "الجوهرة (الخبر)",
        الحسام: "الحسام",
        الحمراء: "الحمراء",
        "الحمراء (الدمام)": "الحمراء (الدمام)",
        "الحمراء (الخبر)": "الحمراء (الخبر)",
        "الخالدية الجنوبية": "الخالدية الجنوبية",
        "الخالدية الشمالية": "الخالدية الشمالية",
        الخضرية: "الخضرية",
        الخليج: "الخليج",
        الدانة: "الدانة",
        الدواسر: "الدواسر",
        الرابية: "الرابية",
        "الراكة الشمالية": "الراكة الشمالية",
        الربيع: "الربيع",
        الروضة: "الروضة",
        الريان: "الريان",
        الزهور: "الزهور",
        السلام: "السلام",
        السوق: "السوق",
        السيف: "السيف",
        "الشاطئ الشرقي": "الشاطئ الشرقي",
        "الشاطئ الغربي": "الشاطئ الغربي",
        الشرق: "الشرق",
        الشعلة: "الشعلة",
        الشفا: "الشفا",
        الصدفة: "الصدفة",
        الصفا: "الصفا",
        الصناعية: "الصناعية",
        "الصناعية الاولى": "الصناعية الاولى",
        الضباب: "الضباب",
        الطبيشي: "الطبيشي",
        العدامة: "العدامة",
        العزيزية: "العزيزية",
        العمامرة: "العمامرة",
        العنود: "العنود",
        الفردوس: "الفردوس",
        الفرسان: "الفرسان",
        الفنار: "الفنار",
        الفيحاء: "الفيحاء",
        الفيصلية: "الفيصلية",
        القادسية: "القادسية",
        القزاز: "القزاز",
        المحمدية: "المحمدية",
        "المدينة الصناعية الثانية": "المدينة الصناعية الثانية",
        المريكبات: "المريكبات",
        المزروعية: "المزروعية",
        المطار: "المطار",
        المنار: "المنار",
        المنتزه: "المنتزه",
        "المها (الدمام)": "المها (الدمام)",
        "المها (الخبر)": "المها (الخبر)",
        الناصرية: "الناصرية",
        النخيل: "النخيل",
        الندى: "الندى",
        النزهة: "النزهة",
        النسيم: "النسيم",
        النهضة: "النهضة",
        النور: "النور",
        النورس: "النورس",
        الهضبة: "الهضبة",
        الواحة: "الواحة",
        بدر: "بدر",
        "ضاحية الملك فهد": "ضاحية الملك فهد",
        طيبة: "طيبة",
        غرناطة: "غرناطة",
        "قصر الخليج": "قصر الخليج",
        "مدينة العمال (الدمام)": "مدينة العمال (الدمام)",
        "مدينة العمال(الخبر)": "مدينة العمال(الخبر)",
        "مدينة الملك فيصل الجامعية": "مدينة الملك فيصل الجامعية",
        "مطار الملك فهد الدولي": "مطار الملك فهد الدولي",
        "ميناء الملك عبدالعزيز": "ميناء الملك عبدالعزيز",
        "ابن سيناء": "ابن سيناء",
        اشبيليا: "اشبيليا",
        الامواج: "الامواج",
        الاندلس: "الاندلس",
        البحر: "البحر",
        البستان: "البستان",
        البندرية: "البندرية",
        التحلية: "التحلية",
        التعاون: "التعاون",
        الثقبة: "الثقبة",
        الجسر: "الجسر",
        "الحزام الاخضر": "الحزام الاخضر",
        "الحزام الذهبي": "الحزام الذهبي",
        "الخبر الجنوبية": "الخبر الجنوبية",
        "الخبر الشمالية": "الخبر الشمالية",
        الخزامى: "الخزامى",
        الخور: "الخور",
        "الراكة الجنوبية": "الراكة الجنوبية",
        الرجاء: "الرجاء",
        الروابي: "الروابي",
        الساحل: "الساحل",
        السفن: "السفن",
        الشراع: "الشراع",
        الصواري: "الصواري",
        العقربية: "العقربية",
        العقيق: "العقيق",
        العليا: "العليا",
        الكوثر: "الكوثر",
        الكورنيش: "الكورنيش",
        اللؤلؤ: "اللؤلؤ",
        "المدينة الرياضية": "المدينة الرياضية",
        المرجان: "المرجان",
        الهدا: "الهدا",
        اليرموك: "اليرموك",
        "صناعية الثقبة": "صناعية الثقبة",
        "صناعية الفوازية": "صناعية الفوازية",
        قرطبة: "قرطبة",
        "الدوحة الجنوبية": "الدوحة الجنوبية",
        "الدانة الشمالية": "الدانة الشمالية",
        القصور: "القصور",
        "الدوحة الشمالية": "الدوحة الشمالية",
        "الدانة الجنوبية": "الدانة الجنوبية",
        الجامعة: "الجامعة",
        "الحرس الوطني": "الحرس الوطني",
        تهامة: "تهامة",
        القشلة: "القشلة",
        هجر: "هجر",
        "غرب الظهران": "غرب الظهران",
        "جامعة الملك فهد للبترول والمعادن": "جامعة الملك فهد للبترول والمعادن",
        الزمرد: "الزمرد",
        الياقوت: "الياقوت",
        الصوارى: "الصوارى",
        الفلاح: "الفلاح",
        الاصالة: "الاصالة",
        مريخ: "مريخ",
        الشروق: "الشروق",
        "الامير فواز الشمالى": "الامير فواز الشمالى",
        الرياض: "الرياض",
        الفروسية: "الفروسية",
        الرحمانية: "الرحمانية",
        الصالحية: "الصالحية",
        الحمدانية: "الحمدانية",
        "ام حبلين": "ام حبلين",
        بريمان: "بريمان",
        المنتزة: "المنتزة",
        الثعالبة: "الثعالبة",
        البلد: "البلد",
        الفاروق: "الفاروق",
        العدل: "العدل",
        الهنداوية: "الهنداوية",
        المحجر: "المحجر",
        الخالدية: "الخالدية",
        "جامعة الملك عبدالعزيز": "جامعة الملك عبدالعزيز",
        "البغدادية الشرقية": "البغدادية الشرقية",
        "النزلة الشرقية": "النزلة الشرقية",
        البوادي: "البوادي",
        السلامة: "السلامة",
        الثغر: "الثغر",
        الشرفية: "الشرفية",
        "قاعدة الملك فيصل البحرية": "قاعدة الملك فيصل البحرية",
        السنابل: "السنابل",
        التضامن: "التضامن",
        الكرامة: "الكرامة",
        الرحمة: "الرحمة",
        البركة: "البركة",
        المسرة: "المسرة",
        المليساء: "المليساء",
        القوزين: "القوزين",
        الوادي: "الوادي",
        الفضيلة: "الفضيلة",
        السروات: "السروات",
        الخمرة: "الخمرة",
        الضاحية: "الضاحية",
        القرينية: "القرينية",
        "النزلة اليمانية": "النزلة اليمانية",
        القريات: "القريات",
        غليل: "غليل",
        الكندرة: "الكندرة",
        العمارية: "العمارية",
        الصحيفة: "الصحيفة",
        السبيل: "السبيل",
        "بنى مالك": "بنى مالك",
        الرحاب: "الرحاب",
        مشرفة: "مشرفة",
        الورود: "الورود",
        "ابرق الرغامة": "ابرق الرغامة",
        السليمانية: "السليمانية",
        البشائر: "البشائر",
        النعيم: "النعيم",
        الربوة: "الربوة",
        المروة: "المروة",
        السامر: "السامر",
        الاجواد: "الاجواد",
        "مطار الملك عبدالعزيز الدولي": "مطار الملك عبدالعزيز الدولي",
        "المنطقة الصناعية": "المنطقة الصناعية",
        "الامير عبدالمجيد": "الامير عبدالمجيد",
        الوزيريه: "الوزيريه",
        "الامير فواز الجنوبى": "الامير فواز الجنوبى",
        المتنزهات: "المتنزهات",
        الفضل: "الفضل",
        "ام السلم": "ام السلم",
        "مدينة الملك عبدالعزيز الطبية": "مدينة الملك عبدالعزيز الطبية",
        "البغدادية الغربية": "البغدادية الغربية",
        "ابحر الشمالية": "ابحر الشمالية",
        الرويس: "الرويس",
        بترومين: "بترومين",
        "ابحر الجنوبية": "ابحر الجنوبية",
        السرورية: "السرورية",
        الاجاويد: "الاجاويد",
        الشاطئ: "الشاطئ",
        "مدائن الفهد": "مدائن الفهد",
        الزهراء: "الزهراء",
        بحرة: "بحرة",
        "ميناء جدة الاسلامى": "ميناء جدة الاسلامى",
        النموذجية: "النموذجية",
        الجرادية: "الجرادية",
        "منفوحة الجديدة": "منفوحة الجديدة",
        الفاخرية: "الفاخرية",
        الديرة: "الديرة",
        "ام الحمام الشرقي": "ام الحمام الشرقي",
        "المعذر الشمالي": "المعذر الشمالي",
        "ام الحمام الغربي": "ام الحمام الغربي",
        لبن: "لبن",
        الرفيعة: "الرفيعة",
        الشهداء: "الشهداء",
        "الملك فهد": "الملك فهد",
        السويدي: "السويدي",
        الحزم: "الحزم",
        عتيقة: "عتيقة",
        المربع: "المربع",
        المرسلات: "المرسلات",
        "الملك فيصل": "الملك فيصل",
        "المدينة الصناعية الجديدة": "المدينة الصناعية الجديدة",
        المنصورة: "المنصورة",
        غبيرة: "غبيرة",
        الجزيرة: "الجزيرة",
        السعادة: "السعادة",
        المناخ: "المناخ",
        الدفاع: "الدفاع",
        "الملك عبدالله": "الملك عبدالله",
        "صلاح الدين": "صلاح الدين",
        "الملك عبدالعزيز": "الملك عبدالعزيز",
        الوزارات: "الوزارات",
        سكيرينة: "سكيرينة",
        جرير: "جرير",
        المعذر: "المعذر",
        الملز: "الملز",
        منفوحة: "منفوحة",
        عليشة: "عليشة",
        الضباط: "الضباط",
        "السويدي الغربي": "السويدي الغربي",
        ديراب: "ديراب",
        نمار: "نمار",
        عكاظ: "عكاظ",
        شبرا: "شبرا",
        الزهرة: "الزهرة",
        صياح: "صياح",
        سلطانة: "سلطانة",
        اليمامة: "اليمامة",
        البديعة: "البديعة",
        المصانع: "المصانع",
        الدريهمية: "الدريهمية",
        "النسيم الشرقي": "النسيم الشرقي",
        القدس: "القدس",
        النفل: "النفل",
        المصيف: "المصيف",
        الازدهار: "الازدهار",
        "ظهرة البديعة": "ظهرة البديعة",
        النظيم: "النظيم",
        الرماية: "الرماية",
        البرية: "البرية",
        المنصورية: "المنصورية",
        "ضاحية نمار": "ضاحية نمار",
        المصفاة: "المصفاة",
        السفارات: "السفارات",
        "خشم العان": "خشم العان",
        طويق: "طويق",
        "ظهرة نمار": "ظهرة نمار",
        المغرزات: "المغرزات",
        السلي: "السلي",
        الغدير: "الغدير",
        المروج: "المروج",
        العود: "العود",
        ثليم: "ثليم",
        الشميسي: "الشميسي",
        الوشام: "الوشام",
        الدوبية: "الدوبية",
        المعكال: "المعكال",
        جبرة: "جبرة",
        القرى: "القرى",
        المرقب: "المرقب",
        الفوطة: "الفوطة",
        "ام سليم": "ام سليم",
        الصحافة: "الصحافة",
        الرائد: "الرائد",
        "العريجاء الغربية": "العريجاء الغربية",
        العريجاء: "العريجاء",
        "العريجاء الوسطى": "العريجاء الوسطى",
        "الدار البيضاء": "الدار البيضاء",
        البطحاء: "البطحاء",
        المؤتمرات: "المؤتمرات",
        الوسيطاء: "الوسيطاء",
        الجنادرية: "الجنادرية",
        اشبيلية: "اشبيلية",
        المعيزلة: "المعيزلة",
        المونسية: "المونسية",
        عرقة: "عرقة",
        "ظهرة لبن": "ظهرة لبن",
        حطين: "حطين",
        الملقا: "الملقا",
        القيروان: "القيروان",
        الياسمين: "الياسمين",
        العارض: "العارض",
        "مطار الملك خالد": "مطار الملك خالد",
        النرجس: "النرجس",
        "جامعة الامام": "جامعة الامام",
        بنبان: "بنبان",
        الرمال: "الرمال",
        الدحو: "الدحو",
        العماجية: "العماجية",
        هيت: "هيت",
        الحائر: "الحائر",
        "ام الشعال": "ام الشعال",
        " الغنامية": " الغنامية",
        عريض: "عريض",
        المهدية: "المهدية",
        "جامعة الملك سعود": "جامعة الملك سعود",
        "النسيم الغربي": "النسيم الغربي",
        المشاعل: "المشاعل",
        "Ad Dar Al Baida": "الدار البيضاء",
        "Ad Difa": "الدفاع",
        "Ad Dirah": "الديرة",
        "Ad Dubiyah": "الدوبية",
        "Ad Duraihimiyah": "الدريهمية",
        "Adh Dhubbat": "الضباط",
        "Al Arid": "العارض",
        "Al Badiah": "البادية",
        "Al Bariah": "البرية",
        "Al Batha": "البرية",
        "Al Dho": "الدحو",
        "Al Fakhriyah": "الفاخرية",
        "Al Falah": " الفلاح",
        "Al Faruq": "الفاروق",
        "Al Futah": "الفوطة",
        "Al Ghadir": "الغدير",
        "Al Ghnamiah": "الغنامية",
        "Al Haeer": "الحائر",
        "Al Hamra": "الحمراء",
        "Al Hazm": "الحزم",
        "Al Imam University": "جامعة الامام",
        "Al Iskan": "الاسكان",
        "Al Izdihar": "الازدهار",
        "Al Janadriyah": "الجنادرية",
        "Al Jaradiyah": "الجرادية",
        "Al Jazirah": "الجزيرة",
        "Al Khalidiyah": "الخالدية",
        "Al Madhar": "المعذر",
        "Al Madhar Ash Shamali": "المعذر الشمالي",
        "Al Mahdiyah": "المهدية",
        "Al Maizilah": "المعيزلة",
        "Al Malaz": "الملز",
        "Al Malqa": "الملقا",
        "Al Manakh": "المناخ",
        "Al Mansurah": "المنصورة",
        "Al Marqab": "المرقب",
        "Al Marwah": "المروة",
        "Al Masani": "المصانع",
        "Al Masif": "المصيف",
        "Al Mikal": "المعكال",
        "Al Misfat": "المصفاة",
        "Al Mishal": "المشاعل",
        "Al Mughrizat": "المغرزات",
        "Al Munsiyah": "المونسية",
        "Al Murabba": "المربع",
        "Al Mursalat": "المرسلات",
        "Al Muruj": "المروج",
        "Al Mutamarat": "المؤتمرات",
        "Al Qirawan": "القيروان",
        "Al Quds": "القدس",
        "Al Qura": "القرى",
        "Al Shohada": "الشهداء",
        "Al Ud": "العود",
        "Al Umjiah": "العماجية",
        "Al Uraija": "العريجاء",
        "Al Uraija Al Gharbiyah": "العريجاء الغربية",
        "Al Uraija Al Wusta": "العريجاء الوسطى",
        "Al Wadi": "الوادي",
        "Al Wazarat": "الوزارات",
        "Al Wisham": "الوشام",
        "Al Wurud": "الورود",
        "Al Wusayta": "الوسيطاء",
        "Al Yamamah": "اليمامة",
        "Al Yarmuk": "اليرموك",
        "Al Yasmin": "الياسمين",
        "An Nafl": "النفل",
        "An Nahdah": "النهضة",
        "An Namudhajiyah": "النموذجية",
        "An Narjis": "النرجس",
        "An Nasim Al Gharbi": "النسيم الغربي",
        "An Nasim Ash Sharqi": "النسيم الشرقي",
        "An Nasriyah": "الناصرية",
        "An Nazim": "النظيم",
        "An Nur": "النور",
        "An Nuzhah": "النزهة",
        "Ar Rabi": "الربيع",
        "Ar Rabwah": "الربوة",
        "Ar Rafiah": "الرفيعة",
        "Ar Rahmaniyah": "الرحمانية",
        "Ar Raid": "الرائد",
        "Ar Rawabi": "الروابي",
        "Ar Rawdah": "الروضة",
        "Ar Rayan": "الريان",
        "Ar Rimal": "الرمال",
        "Ar Rimayah": "الرماية",
        Areed: "عريض",
        "As Saadah": "السعادة",
        "As Safa": "الصفا",
        "As Safarat": "السفارات",
        "As Sahafah": "الصحافة",
        "As Salam": "السلام",
        "As Salhiyah": "الصالحية",
        "As Sinaiyah": "الصناعية",
        "As Sulai": "السلي",
        "As Sulimaniyah": "السليمانية",
        "As Suwaidi": "السويدي",
        "As Suwaidi Al Gharbi": "السويدي الغربي",
        "Ash Sharafiyah": "الشرفية",
        "Ash Sharq": "الشرق",
        "Ash Shifa": "الشفا",
        "Ash Shimaisi": "الشميسي",
        "At Taawn": "التعاون",
        "Az Zahra": "الزهراء",
        "Az Zahrah": "الزهرة",
        Badr: "بدر",
        Banban: "بنبان",
        "Dahiat Namar": "ضاحية نمار",
        "Dhahrat Al Badiah": "ظهرة البديعة",
        "Dhahrat Laban": "ظهرة لبن",
        "Dhahrat Namar": "ظهرة نمار",
        Dirab: "ديراب",
        Ghirnatah: "غرناطة",
        Ghubairah: "غبيرة",
        Hittin: "حطين",
        Hyt: "هيت",
        Ilaishah: "عليشة",
        Irqah: "عرقة",
        Ishbiliyah: "اشبيلية",
        Jabrah: "جبرة",
        Jarir: "جرير",
        "Khashm Al An": "خشم العان",
        "King Abdul Aziz": "الملك عبدالعزيز",
        "King Abdullah": "الملك عبدالله",
        "King Fahd": "الملك فهد",
        "King Faisal": "الملك فيصل",
        "King Khalid Airport": "مطار الملك خالد",
        "King Saud University": "جامعة الملك سعود",
        Laban: "لبن",
        Manfuhah: "منفوحة",
        "Manfuhah Al Jadidah": "منفوحة الجديدة",
        Mansuriyah: "المنصورية",
        Namar: " نمار",
        "New Industrial Area": "المدينة الصناعية الجديدة",
        Qurtubah: "قرطبة",
        "Salah Ad Din": "صلاح الدين",
        Shubra: "شبرا",
        Siyah: "صياح",
        Skirinah: "سكيرينة",
        Sultanah: "سلطانة",
        Taibah: "طيبة",
        Thulaim: "ثليم",
        Tuwaiq: "طويق",
        Uhud: "احد",
        "Um Alshal": "ام الشعال",
        "Umm Al Hamam Al Gharbi": "ام الحمام الغربي",
        "Umm Al Hamam Ash Sharqi": "ام الحمام الشرقي",
        "Umm Salim": "ام سليم",
        Uqaz: "عكاظ",
        Utaiqah: "عتيقة",
        "Ad Dabab": "الضباب",
        "Ad Danah": "الدانة",
        "Ad Danah Al Janubiyah": "الدانة الجنوبية",
        "Ad Danah Ash Shamaliyah": "الدانة الشمالية",
        "Ad Dawasir": "الدواسر",
        "Ad Dawhah Al Janubiyah": "الدوحة الجنوبية",
        "Ad Dawhah Ash Shamaliyah": "الدوحة الشمالية",
        "Al Adamah": "العدامة",
        "Al Amal": "العمل",
        "Al Amamrah": "العمامرة",
        "Al Amanah": "الأمانة",
        "Al Amir Muhammed Ibn Saud": "الامير محمد بن سعود",
        "Al Amwaj": "الامواج",
        "Al Andalus": "الاندلس",
        "Al Anud": "العنود",
        "Al Anwar": "الأنوار",
        "Al Aqiq": "العقيق",
        "Al Aqrabiyah": "العقربية",
        "Al Athir": "الأثير",
        "Al Aziziyah": "العزيزية",
        "Al Badi": "البديع",
        "Al Badiyah": "البادية",
        "Al Bahar": "البحر",
        "Al Bandariyah": "البندرية",
        "Al Basatin": "البساتين",
        "Al Buhayrah (Dammam)": "البحيرة (الدمام)",
        "Al Buhayrah (Khobar)": "البحيرة (الخبر)",
        "Al Bustan": "البستان",
        "Al Faiha": "الفيحاء",
        "Al Faisaliyah": "الفيصلية",
        "Al Fanar": "الفنار",
        "Al Firdaws": "الفردوس",
        "Al Fursan": "الفرسان",
        "Al Hadabah": "الهضبة",
        "Al Hamra (Dammam)": "الحمراء (الدمام)",
        "Al Hamra (Khobar)": "الحمراء (الخبر)",
        "Al Haras Al Watani": "الحرس الوطني",
        "Al Hizam Adh Dhahabi": "الحزام الذهبي",
        "Al Hizam Al Akhdar": "الحزام الاخضر",
        "Al Hussam": "الحسام",
        "Al Iskan (Dammam)": "الإسكان (الدمام)",
        "Al Iskan (Khobar)": "الاسكان (الخبر)",
        "Al Itisalat": "الاتصالات",
        "Al Jalawiyah": "الجلوية",
        "Al Jamiah": "الجامعة",
        "Al Jamiyin": "الجامعيين",
        "Al Jawharah (Dammam)": "الجوهرة (الدمام)",
        "Al Jawharah (Khobar)": "الجوهرة (الخبر)",
        "Al Jisr": "الجسر",
        "Al Kawthar": "الكوثر",
        "Al Khalidiyah Al Janubiyah": "الخالدية الجنوبية",
        "Al Khalidiyah Ash Shamaliyah": "الخالدية الشمالية",
        "Al Khalij": "الخليج",
        "Al Khawr": "الخور",
        "Al Khubar Al Janubiyah": "الخبر الجنوبية",
        "Al Khubar Ash Shamaliyah": "الخبر الشمالية",
        "Al Khuzama": "الخزامى",
        "Al Kurnaish": "الكورنيش",
        "Al Kuthriah": "الخضرية",
        "Al Lulu": "اللؤلؤ",
        "Al Maha (Dammam)": "المها (الدمام)",
        "Al Maha (Khobar)": "المها (الخبر)",
        "Al Manar": "المنار",
        "Al Matar": "المطار",
        "Al Mazruiyah": "المزروعية",
        "Al Muhammadiyah": "المحمدية",
        "Al Muntazah": "المنتزه",
        "Al Muraikabat": "المريكبات",
        "Al Murjan": "المرجان",
        "Al Qadisiyah": "القادسية",
        "Al Qashlah": "القشلة",
        "Al Qazaz": "القزاز",
        "Al Qusur": "القصور",
        "Al Ulaya": "العليا",
        "Al Wahah": "الواحة",
        "Al Yarmok": "اليرموك",
        "An Nada": "الندى",
        "An Nasim": "النسيم",
        "An Nawras": "النورس",
        "Ar Rabiyah": "الرابية",
        "Ar Raja": "الرجاء",
        "Ar Rakah Al Janubiyah": "الراكة الجنوبية",
        "Ar Rakah Ash Shamaliyah": "الراكة الشمالية",
        "Ar Rayaan": "الريان",
        "As Sadafah": "الصدفة",
        "As Sahil": "الساحل",
        "As Saif": "السيف",
        "As Sawari": "الصواري",
        "As Sufun": "السفن",
        "As Suq": "السوق",
        "Ash Shati Al Gharbi": "الشاطئ الغربي",
        "Ash Shati Ash Sharqi": "الشاطئ الشرقي",
        "Ash Sheraa": "الشراع",
        "Ash Shulah": "الشعلة",
        "At Tahliyah": "التحلية",
        "At Tubayshi": "الطبيشي",
        "Ath Thuqbah": "الثقبة",
        "Az Zuhur": "الزهور",
        "Dahiyat Al Malik Fahd": "ضاحية الملك فهد",
        "Gharb Adh Dhahran": "غرب الظهران",
        Hajr: "هجر",
        "Ibn Khaldun": "ابن خلدون",
        "Ibn Sina": "ابن سيناء",
        Industrial: "الصناعية",
        "Industrial Area No1": "الصناعية الاولى",
        "Industrial City 2": "المدينة الصناعية الثانية",
        Ishbiliya: "اشبيليا",
        Kfupm: "جامعة الملك فهد للبترول والمعادن",
        "King Abdul Aziz Seaport": "ميناء الملك عبدالعزيز",
        "King Fahd International Airport": "مطار الملك فهد الدولي",
        "King Faysal University": "مدينة الملك فيصل الجامعية",
        "Madinat Al Umal (Dammam)": "مدينة العمال (الدمام)",
        "Madinat Al Ummal (Khobar)": "مدينة العمال(الخبر)",
        "Qasr Al Khalij": "قصر الخليج",
        "Sinaiyah Al Foaziah": "صناعية الفوازية",
        "Sinaiyah Ath Thuqbah": "صناعية الثقبة",
        "Sporting City": "المدينة الرياضية",
        Taybah: "طيبة",
        Tihamah: "تهامة",
        "Abhur Al Janubiyah": "ابحر الجنوبية",
        "Abhur Ash Shamaliyah": "ابحر الشمالية",
        "Abruq Ar Rughamah": "ابرق الرغامة",
        "Ad Dahiah": "الضاحية",
        "Al Adel": "العدل",
        "Al Ajaweed": "الاجاويد",
        "Al Ajwad": "الاجواد",
        "Al Amir Abdoulmajed": "الامير عبدالمجيد",
        "Al Amir Fawaz Al Janouby": "الامير فواز الجنوبى",
        "Al Amir Fawaz Ash Shamaly": "الامير فواز الشمالى",
        "Al Ammariyah": "العمارية",
        "Al Asalah": "الاصالة",
        "Al Baghdadiyah Al Gharbiyah": "البغدادية الغربية",
        "Al Baghdadiyah Ash Sharqiyah": "البغدادية الشرقية",
        "Al Balad": "البلد",
        "Al Barakah": "البركة",
        "Al Bashaer": "البشائر",
        "Al Bawadi": "البوادي",
        "Al Fadel": "الفضل",
        "Al Fadeylah": "الفضيلة",
        "Al Farouk": "الفاروق",
        "Al Ferdous": "الفردوس",
        "Al Frosyah": "الفروسية",
        "Al Hada": "الهدا",
        "Al Hamadaniyah": "الحمدانية",
        "Al Hindawiyah": "الهنداوية",
        "Al Jawharah": "الجوهرة",
        "Al Kandarah": "الكندرة",
        "Al Karamah": "الكرامة",
        "Al Khomrah": "الخمرة",
        "Al Loaloa": "اللؤلؤ",
        "Al Mahjar": "المحجر",
        "Al Masarah": "المسرة",
        "Al Montazah": "المنتزة",
        "Al Moulysaa": "المليساء",
        "Al Mutanazahat": "المتنزهات",
        "Al Qouzeen": "القوزين",
        "Al Qryniah": "القرينية",
        "Al Quraiyat": "القريات",
        "Al Waziriyah": "الوزيريه",
        "Al Yaqoot": "الياقوت",
        "An Naim": "النعيم",
        "An Nakhil": "النخيل",
        "An Nazlah Al Yamaniyah": "النزلة اليمانية",
        "An Nazlah Ash Sharqiyah": "النزلة الشرقية",
        "Ar Rahmah": "الرحمة",
        "Ar Rahmanyah": "الرحمانية",
        "Ar Rayyan": "الريان",
        "Ar Rihab": "الرحاب",
        "Ar Riyadh": "الرياض",
        "Ar Ruwais": "الرويس",
        "As Sabil": "السبيل",
        "As Sahifah": "الصحيفة",
        "As Salamah": "السلامة",
        "As Samir": "السامر",
        "As Sanabel": "السنابل",
        "As Sarawat": "السروات",
        "As Sororyah": "السرورية",
        "As Swaryee": "الصوارى",
        "Ash Shati": "الشاطئ",
        "Ash Shefaa": "الشفا",
        "Ash Shrouk": "الشروق",
        "At Taawon": "التعاون",
        "At Tadamon": "التضامن",
        "Ath Thaalibah": "الثعالبة",
        "Ath Thaghr": "الثغر",
        "Az Zomorod": "الزمرد",
        Bahrah: "بحرة",
        "Bani Malik": "بنى مالك",
        Bryman: "بريمان",
        Ghulail: "غليل",
        "Industrial Area": "المنطقة الصناعية",
        "Jeddah Eslamic Seaport": "ميناء جدة الاسلامى",
        "King Abdul Aziz International Airport": "مطار الملك عبدالعزيز الدولي",
        "King Abdul Aziz Medical City": "مدينة الملك عبدالعزيز الطبية",
        "King Abdul Aziz University": "جامعة الملك عبدالعزيز",
        "King Faisal Naval Base": "قاعدة الملك فيصل البحرية",
        "Madain Al Fahd": "مدائن الفهد",
        Mishrifah: "مشرفة",
        Mraykh: "مريخ",
        Petromin: "بترومين",
        "Um Asalam": "ام السلم",
        "Um Hableen": "ام حبلين",
      },
    },
    sidebar: {
      reservations: "الحجوزات",
      locations: "الأماكن",
      subscriptions: "الإشتراكات",
      lockers: "الخزائن",
      users: "المستخدمين",
      hosts: "المستضيفين",
      settings: "إعدادات",
    },
    navbar: {
      logo: "تحديث العلامة التجارية",
      password: "تغيير الرمز السري",
      signOut: "تسجيل الخروج",
    },
    tabs: {
      Active: "نشط",
      Inactive: "غير نشط",
      Completed: "مكتمل",
      Canceled: "ألغيت",
      Expired: "منتهي",
      notFound: "لا يوجد",
    },
    reactTable: {
      ofText: "من",
      pageText: "صفحة",
      nextText: "التالي",
      previousText: "الخلف",
    },
    columnsHeaders: {
      reservations: {
        number: "رقم",
        name: "الاسم",
        phone: "رقم الجوال",
        location: "المكان",
        locker: "رقم الخزنة",
        cabinet: "رقم الخزنة",
        size: "حجم الخزنة",
        duration: "المدة",
        amount: "السعر",
        price: "سعر",
        date: "الوقت واليوم",
        status: "الحالة",
        actions: "إجراءات",
        poppers: {
          details: "تفاصيل",
          logs: "السجلات",
        },
      },
      reservationDetails: {
        key: "رقم المفتاح",
        type: "نوع المفتاح",
        owner: "المالك",
        phone: "رقم الجوال",
        status: "حالة المفتاح",
      },
      users: {
        name: "الاسم",
        type: "النوع",
        phone: "رقم الجوال",
        email: "البريد الالكتروني",
        status: "الحالة",
        actions: "إجراءات",
        poppers: {
          edit: "تعديل",
          deactivate: "تعطيل",
          activate: "تنشيط",
        },
      },
      subscriptions: {
        visibleName: "الاسم الظاهر",
        sizes: "الاحجام",
        status: "الحالة",
        small: "سعر الحجم الصغير",
        medium: "سعر الحجم الوسط",
        large: "سعر الحجم الكبير",
        duration: "المدة",
        finePerMinute: "رسوم التأخير / دقيقة",
        key: "مشاركة مفتاح الخزنة",
        location: "المكان",
        actions: "إجراءات",
        poppers: {
          edit: "تعديل",
          details: "تفاصيل",
        },
      },
      subscriptionsForm: {
        name: "الاسم",
        address: "العنوان",
        location: "هوية الموقع",
        action: "عمل",
        btnDelete: "مسح",
      },
      locations: {
        name: "الاسم",
        address: "العنوان",
        map: "الخريطة",
        locker: "اسم الخزنة",
        sizes: "الاحجام",
        utilization: "الاستخدام",
        statusLocker: "حالة الخزنة",
        statusLocation: "حالة الموقع",
        actions: "إجراءات",
        host: "اسم الإستضافة",
        status: {
          unlimited: "غير محدود",
          limited: "محدود",
        },
        poppers: {
          edit: "تعديل",
          details: "تفاصيل",
        },
      },
      reservationShareKey: {
        name: "الاسم",
        phoneNumber: "رقم الجوال",
        status: "الحالة",
        actions: "إجراءات",
        poppers: {
          revoke: "إيقاف مشاركة المفتاح",
          resend: "إعادة ارسال رابط المفتاح",
          copy: "نسخ رابط المفتاح",
        },
      },
      hosts: {
        name: "الاسم",
        numberLocation: "عدد المواقع",
        status: "الحالة",
        actions: "إجراءات",
        poppers: {
          edit: "تعديل",
          deactivate: "تعطيل",
          activate: "تنشيط",
        },
      },
      lockers: {
        locker: "خزنة",
        location: "اسم الموقع",
        utilization: "الاستخدام",
        sizes: "الاحجام",
        actions: "إجراءات",
        poppers: {
          details: "تفاصيل",
        },
      },
    },
    validationForm: {
      name: "حرف واحد على الأقل",
      password: "ستة حروف على الأقل",
      email: "بريد الكتروني غلط",
      urlValidation: "رابط غير صالح. مهم ادخال رابط بالتنسيق https://xxxxxx.xxx كمثال https://khzn.net",
      durationValidation: "قيمة غير صحيحة ، أدخلها بالتنسيق HH: MM: SS",
      priceValidation: "قيمة غير صحيحة ، أدخلها بالتنسيق 00.00",
      phoneMin: "966xxxxxxxxx+ بطريقة",
      phoneMinClient: "XXXXXXXXX بطريقة",
      phoneMax: "اكثر شي 13 حرف ورقم",
      phoneValidation: "قيمة غير صحيحة",
      roleHelperText: "رجاء اختيار مهامك",
      hostHelperText: "من فضلك قم بإختيار المستضيف",
      required: "حقول مطلوبة",
      pinMin: "مفروض 4 احرف وأرقام",
      pinMax: "اكثر شي 4 احرف وأرقام",
    },
  },
};
