import axios from 'axios';

export const getReservations = async ({ status = 'Active' }) => {
  const params = {
    sort: '-_id',
    pageSize: 100,
    query: {
      ...(status === 'Active'
        ? { $or: [{ status }, { status: 'Expired' }] }
        : { status }),
    },
  };
  const response = await axios.get('/reservations', { params });
  return response;
};

export const getReservationLogs = async reservationId => {
  const response = await axios.get(`/reservation/logs`, {
    params: { reservationId },
  });
  return response;
};

export const getReservationInvoices = async data => {
  const response = await axios.get(`/invoices`, {
    params: data,
  });
  return response;
};

export const postNewReservation = async newReservation => {
  const response = await axios.post('/reservations', newReservation);
  return response;
};

export const postPreReservation = async data => {
  const response = await axios.post('/location/locker/reserve', data);
  return response;
};

export const postPaymentForReservation = async dataPayment => {
  const response = await axios.post('/payment', dataPayment);
  return response;
};

export const postOpenCabinet = async data => {
  const response = await axios.post(`/reservation/locker/cabinet/open`, data);
  return response;
};

export const postShareKey = async data => {
  const response = await axios.post(`/reservation/sharekey`, data);
  return response;
};

export const postRevokeShareKey = async data => {
  const response = await axios.post(`/reservation/sharekey/revoke`, data);
  return response;
};

export const postResendKey = async data => {
  const response = await axios.post(`/reservation/sharekey/resendlink`, data);
  return response;
};

export const postFreeCabinet = async reservationId => {
  const response = await axios.post(`/reservation/pickup/confrim`, {
    reservationId,
  });
  return response;
};

export const postCompleteReservation = async reservationId => {
  const response = await axios.post(`/reservation/complete`, {
    reservationId,
  });
  return response;
};
