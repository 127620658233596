import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import TransitionsPopover from "../../shared/columns/reservationDetailsPopover/reservationDetailsPopover";
import cn from 'classnames';
import s from "./HeaderOnlyClient.module.scss";

export const HeaderOnlyClient = ({ linkBack, reservation = null, setReservation, location = null }) => {
  const { t, i18n: {language} } = useTranslation();
  const {
    location: { pathname },
  } = useHistory();

  const mainPage = "/client";

  return (
    <>
      {pathname === mainPage ? (
        <div className={s.subNav}>
          <h3 className={s.title}> {`${t("clientLogged").welcome}`}</h3>
        </div>
      ) : (
        <>
          {reservation && location ? (
            <div className={cn(s.subNav, language === "ar" && s.arStyle)}>
              <Link to={pathname !== mainPage && linkBack}>
                <Button
                  className={cn(s.btnBack, language === "ar" && s.arStyle)}
                  startIcon={language === "ar" ? <ArrowForwardIcon /> : <ArrowBackIcon />}
                >
                  {t("header").backBtn}
                </Button>
              </Link>
              <TransitionsPopover reservation={reservation} setReservation={setReservation} location={location} />
            </div>
          ) : (
            <div className={cn(s.subNav, language === "ar" && s.arStyle)}>
              <Link to={pathname !== mainPage && linkBack}>
                <Button
                  className={cn(s.btnBack, language === "ar" && s.arStyle)}
                  startIcon={language === "ar" ? <ArrowForwardIcon /> : <ArrowBackIcon />}
                >
                  {t("header").backBtn}
                </Button>
              </Link>
            </div>
          )}
        </>
      )}
    </>
  );
};
