import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
} from '@material-ui/core/';
import { useVerifyForm } from './useVerify';
import { NavBarByRole, BtnLanguage } from '../../../../../reusableComponents';
import { SubmitButton } from '../../styles';
import estilamLogo from '../../../../../assets/estilamLogo.png';
import s from '../authForm.module.scss';

const VerifyForm = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { state } = useLocation();
  const { verify, sendOTPPin } = useVerifyForm();

  const handleSendOTPPin = () => {
    if (state) {
      sendOTPPin({ phone: state.phone });
    }
  };

  const f = useFormik({
    initialValues: {
      pin: '',
    },
    validationSchema: Yup.object({
      pin: Yup.string()
        .min(4, t('validationForm').pinMin)
        .max(4, t('validationForm').pinMax)
        .required(t('validationForm').required),
    }),
    onSubmit: (values, { resetForm }) => {
      const credentials = {
        ...state,
        ...values,
      };
      verify(credentials);
      resetForm({});
    },
  });

  return (
    <div className={s.container}>
      <NavBarByRole />
      <h3>{t('authForm').verifyForm.title}</h3>
      <p>{t('authForm').verifyForm.getSMS}</p>
      <div className={s.formContainer}>
        <div className={s.btnLanguage}>
          <BtnLanguage />
        </div>
        <div className={s.logo}>
          <img src={estilamLogo} width="120" alt="Logo" />
        </div>
        <form onSubmit={f.handleSubmit} className={s.loginForm}>
          <FormControl size="medium">
            <InputLabel
              htmlFor="pin"
              className={language === 'ar' && s.placeholderAr}
            >
              {t('authForm').verifyForm.pin}
            </InputLabel>
            <Input
              id="pin"
              name="pin"
              type="text"
              value={f.values.pin}
              onBlur={f.handleBlur}
              onChange={f.handleChange}
              autoComplete="off"
            />
            {f.errors?.pin && (
              <FormHelperText error>{f.errors?.pin}</FormHelperText>
            )}
            <SubmitButton
              type="submit"
              disabled={f.values.pin === '' || !!f.errors?.pin}
            >
              {t('authForm').verifyForm.verifyBtn}
            </SubmitButton>
          </FormControl>
        </form>
        <p className={s.send} onClick={handleSendOTPPin}>
          {t('authForm').verifyForm.getSMSAgain}
        </p>
      </div>
    </div>
  );
};

export default VerifyForm;
